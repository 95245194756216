import { connect } from "react-redux";
import { BtnPlayBackView } from "./BtnPlayBack";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnPlayBack = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnPlayBackView);

export { BtnPlayBack };
