import { connect } from "react-redux";
import { withRouter } from "react-router";
import TileView from "./Tile";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const Tile = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TileView));

export default Tile;
