import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

export const Shane = ({ setStory2Survey }) =>
{
    const [story, setStory] = useState("");
    const [isValid, setIsValid] = useState(false);

    const validateStory = (newStory) =>
    {
        if (newStory.length > 10)
        {
            setIsValid(true);
        }
        else
        {
            setIsValid(false);
        }
    };

    const handleInputChange = (event) =>
    {
        const { value } = event.target;

        setStory(value);
        validateStory(value);
    };

    const handleSubmit = () =>
    {
        setStory2Survey(story);
    };

    return (
        <div className="Shane">
            <span className="Shane_Span">
                Tell us a story about how jewelry has been meaningful to you.
            </span>
            <span className="Shane_Span">
                Maybe your story is about an heirloom that was passed down to you,
                a piece of jewelry given to you with special symbolism, or the ring you proposed with.
            </span>
            <span className="Shane_Span">
                We want to hear it!
            </span>

            <form className="Shane_Form">
                <label htmlFor="story" className="Shane_Form__Story">
                    <textarea
                        className="Shane_Text"
                        name="story"
                        value={story}
                        onChange={handleInputChange}
                    />
                </label>
                <div className="Button_Container Shane_Btn">
                    <button
                        type="button"
                        className="Button_Btn"
                        onClick={() => handleSubmit()}
                        disabled={!isValid}
                    >
                        Submit story
                    </button>
                </div>
            </form>
        </div>
    );
};

Shane.defaultProps = {
    setStory2Survey: PropTypes.func.isRequired
};

Shane.propTypes = {
    setStory2Survey: PropTypes.func
};
