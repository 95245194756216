import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { Card } from "react-bootstrap";
import { Box } from "@material-ui/core";
// import { ReactBootstrapSlider } from "react-bootstrap-slider";
import { CLASS_BLOCK, CLASS_NONE } from "../../../assets/constants";

export const DebugThresholds = ({ showDebug, openChooser, threshold, changeThreshold, blurArea, changeBlur }) =>
{
    const displayClass = openChooser ? CLASS_NONE : CLASS_BLOCK;
    const openChooserClass = `CameraActivity_OpenChooser ${displayClass}`;

    if (!showDebug)
    {
        return null;
    }

    return (
        <div>
            <Draggable bounds="body" handle=".cursor">
                <div className={openChooserClass}>
                    <Card>
                        <strong className="cursor CameraActivity_Strong">
                            &nbsp;
                        </strong>
                        <Box component="div">
                            <span>
                                Threshold
                            </span>
                            {/*
                            <ReactBootstrapSlider
                                value={threshold}
                                change={changeThreshold}
                                step={0.01}
                                max={0.99}
                                min={0.80}
                                orientation="horizontal"
                            />
                            <span>
                                Blur Amount
                            </span>
                            <ReactBootstrapSlider
                                value={blurArea}
                                change={changeBlur}
                                step={0.01}
                                max={512}
                                min={64}
                                orientation="horizontal"
                            />
                            */}
                        </Box>
                    </Card>
                </div>
            </Draggable>
        </div>
    );
};

DebugThresholds.defaultProps = {
    showDebug: false,
    openChooser: PropTypes.bool.isRequired,
    threshold: PropTypes.number.isRequired,
    changeThreshold: PropTypes.func.isRequired,
    blurArea: PropTypes.number.isRequired,
    changeBlur: PropTypes.func.isRequired
};

DebugThresholds.propTypes = {
    showDebug: PropTypes.bool,
    openChooser: PropTypes.bool,
    threshold: PropTypes.number,
    changeThreshold: PropTypes.func,
    blurArea: PropTypes.number,
    changeBlur: PropTypes.func
};
