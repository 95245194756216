import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnCancelView = ({ onCancel }) => (
    <div className="BtnCancel">
        <button
            type="button"
            className="BtnCancel_Btn"
            onClick={onCancel}
        >
            <span className="BtnCancel_Text">
                Cancel
            </span>
        </button>
    </div>
);

BtnCancelView.defaultProps = {
    onCancel: PropTypes.func.isRequired
};

BtnCancelView.propTypes = {
    onCancel: PropTypes.func
};

export { BtnCancelView };
