import { connect } from "react-redux";
import {
    selectImages,
    selectVideos,
    selectUploads,
    selectCampaign,
    selectTestFlag,
    selectImageWorker,
    selectVideoWorker
} from "../../state/selectors";
import PhotoShareView from "./PhotoShareOld";

const mapStateToProps = (state) => ({
    images: selectImages(state),
    videos: selectVideos(state),
    uploads: selectUploads(state),
    campaign: selectCampaign(state),
    testFlag: selectTestFlag(state),
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state)
});

const mapDispatchToProps = () => ({
});

const PhotoShare = connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoShareView);

export default PhotoShare;
