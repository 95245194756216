import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnApproveView = ({ onApprove }) => (
    <div className="BtnApprove">
        <button
            type="button"
            className="BtnApprove_Btn"
            onClick={onApprove}
        >
            <span className="BtnApprove_Text">
                Submit
            </span>
        </button>
    </div>
);

BtnApproveView.defaultProps = {
    onApprove: PropTypes.func.isRequired
};

BtnApproveView.propTypes = {
    onApprove: PropTypes.func
};

export { BtnApproveView };
