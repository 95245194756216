import React, { Component } from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import { Button, ProgressBar } from "react-bootstrap";
import { Box, Popover, Typography } from "@material-ui/core";
import { isIOS, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
import ReactGA from "react-ga";
import { arrayBufferObjectToBlob, getImage, getVideo } from "../../db";
import { Instructions } from "./Instructions/instructions";
import { downloadSvg, instagramSvg } from "../../assets/svg";
import { CLASS_BLOCK, CLASS_NONE } from "../../assets/constants";
import "./style.css";

const clickAnchor = (properties) =>
{
    const anchor = document.createElement("a");

    Object.assign(anchor, properties);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export default class PhotoShareView extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            blob: null,
            frameBlobUrl: null,
            showDownloadWarning: false,
            showInstagramInstructions: false,
            downloadAnchor: null,
            instagramAnchor: null,
            isFacebookEnabled: true,
            isEmailEnabled: true,
            isDownloadEnabled: true,
            isTwitterEnabled: true,
            isInstagramEnabled: isMobile,
            isDownloaded: false
        };
    }

    componentDidMount()
    {
        const { imageWorker, videoWorker, match: { params: { ID, type } } } = this.props;

        if (imageWorker && Object.keys(imageWorker).length > 0)
        {
            imageWorker.postMessage({ messageType: "upload_images" });
        }

        if (videoWorker && Object.keys(videoWorker).length > 0)
        {
            videoWorker.postMessage({ messageType: "upload_videos" });
        }

        if (type === "image")
        {
            getImage(parseInt(ID, 10))
                .then(({ image }) => arrayBufferObjectToBlob(image.array, image.mimeType))
                .then((blob) =>
                {
                    this.setState({
                        frameBlobUrl: URL.createObjectURL(blob)
                    });
                });
        }
        else
        {
            getVideo(parseInt(ID, 10))
                .then(({ video }) => video && arrayBufferObjectToBlob(video.array, video.mimeType))
                .then((blob) =>
                {
                    this.setState({
                        frameBlobUrl: URL.createObjectURL(blob),
                        blob: blob
                    });
                });
        }
    }

    doneButton = () =>
    {
        const { history, campaign } = this.props;
        const newHistory = (campaign?.activation_mode === "kiosk") ? "/KioskStartScreen" : "/HomeScreenActivity";

        history.replace(newHistory);
    }

    doneButtonGoToCameraActivity = () =>
    {
        const { campaign, history } = this.props;

        if (campaign?.activation_mode === "kiosk")
        {
            history.replace("/CameraActivity");
        }
    }

    closeWarning = () =>
    {
        this.setState({
            showDownloadWarning: false,
            showInstagramInstructions: false
        });
    }

    updateJpgName = (jpgName) =>
    {
        if (!jpgName)
        {
            return "photo";
        }

        const fileExt = jpgName.split(".").pop();
        const fileName = jpgName.substring(0, jpgName.lastIndexOf(fileExt) - 1);

        return fileName.concat("_processed", ".", fileExt);
    };

    downloadButton = (event) =>
    {
        const { testFlag } = this.props;

        this.setState({ isDownloaded: true });

        this.onClickReactGAEvent("download");
        this.processMobile("downloadAnchor", event.currentTarget);

        if (testFlag)
        {
            this.setState({
                isPhotoDownloaded: true
            }, () =>
            {
                this.doneButtonGoToCameraActivity();
            });
        }
    }

    instagramButton = (event) =>
    {
        this.onClickReactGAEvent("instagram");
        this.processMobile("instagramAnchor", event.currentTarget);
    }

    findFrame = () =>
    {
        const { match: { params: { ID, type } } } = this.props;
        const name = `${type}s`;
        const { [name]: frames } = this.props;

        return frames.find((element) => element.id === parseInt(ID, 10));
    }

    onClickReactGAEvent = (data) =>
    {
        const { campaign } = this.props;

        ReactGA.event({
            category: "photoshare",
            action: data,
            label: campaign.id
        });
    }

    processMobile = (name, data) =>
    {
        const { frameBlobUrl, blob } = this.state;
        const { match: { params: { type } } } = this.props;
        const fileName = (type === "image") ? "MyTagkastPhoto.jpg" : "MyTagkastVideo.mp4";

        if (isIOS)
        {
            this.setState({
                [name]: data,
                showDownloadWarning: true
            });

            const reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = () =>
            {
                const fileToInclude = new File([blob], fileName,
                    {
                        type: "video/mp4",
                        lastModified: Date.now()
                    });

                const shareObject = {
                    title: "",
                    text: "",
                    files: [fileToInclude]
                };

                navigator.share(shareObject);
            };
        }
        else
        {
            clickAnchor({
                href: frameBlobUrl,
                download: fileName
            });
        }
    }

    getContent = () =>
    {
        const { frameBlobUrl } = this.state;
        const { match: { params: { type } } } = this.props;

        const image = (
            <img
                alt="The selfie you took"
                className="PhotoShare_Img"
                src={frameBlobUrl}
            />
        );

        const video = (
            <video
                className="PhotoShare_Img"
                src={frameBlobUrl}
                autoPlay
                playsInline
            />
        );

        return (type === "image") ? image : video;
    }

    render()
    {
        const {
            frameBlobUrl, isPhotoDownloaded, isEmailEnabled, isDownloaded,
            isFacebookEnabled, isTwitterEnabled, isInstagramEnabled, isDownloadEnabled,
            showDownloadWarning, downloadAnchor, showInstagramInstructions, instagramAnchor
        } = this.state;
        const { testFlag, uploads, location, match: { params: { ID, type } } } = this.props;

        const upload = uploads?.[ID];
        const uploadProgress = upload?.uploadProgress;
        const uploadError = upload?.uploadError;
        const frame = this.findFrame();
        const frameUrl = frame?.url;
        const isVideo = location.pathname.includes("video");
        const newHref = `mailto:?subject=Your%20Tagkast%20${type}&body=${encodeURI(frameUrl)}`;

        if (testFlag && (isPhotoDownloaded === false) && (frameBlobUrl !== null) && (frame?.isUploaded))
        {
            this.downloadButton("photoshare render");
        }

        const boxClassName = (frame?.isUploaded || isVideo) ? "" : CLASS_NONE;
        const faceBookClassName = isFacebookEnabled ? CLASS_BLOCK : CLASS_NONE;
        const twitterClassName = isTwitterEnabled ? CLASS_BLOCK : CLASS_NONE;
        const emailClassName = isEmailEnabled ? CLASS_BLOCK : CLASS_NONE;

        const instagramBlock = isInstagramEnabled ? CLASS_BLOCK : CLASS_NONE;
        const instagramClassName = `PhotoShare_Instagram ${instagramBlock}`;

        const downloadBlock = (isDownloadEnabled && !isDownloaded) ? CLASS_BLOCK : CLASS_NONE;
        const downloadClassName = `PhotoShare_Download ${downloadBlock}`;

        const isUploadedClassName = (frame?.isUploaded || isVideo) ? CLASS_NONE : CLASS_BLOCK;
        const progressClassName = (uploadProgress < 100 && uploadError == null) ? CLASS_BLOCK : CLASS_NONE;
        const roundedUploadProgress = Math.round(uploadProgress);
        const uploadProgressString = `${roundedUploadProgress}% uploaded...`;

        const errorBlock = (uploadError == null) ? CLASS_NONE : CLASS_BLOCK;
        const errorClassName = `PhotoShare_Error ${errorBlock}`;

        const progressBarClassName = (isNaN(uploadProgress) || upload?.isUploaded) ? CLASS_NONE : CLASS_BLOCK;

        const mobileTypography = isIOS ?
            "To share, long press the image and click save. Then open and share the image using the Instagram app!" :
            "The image has been downloaded to your device. Open the Instagram app, find the image, and share!";

        const content = this.getContent();
        const title = (type === "image") ? "Your Tagkast Photo" : "Your Tagkast Video";

        return (
            <div className="PhotoShare">
                <Instructions />
                {content}
                <div className="PhotoShare_Status">
                    <div className={isUploadedClassName}>
                        Please Wait...
                        <div className={progressClassName}>
                            {uploadProgressString}
                        </div>
                        <div className={errorClassName}>
                            Error uploading
                        </div>
                        <ProgressBar
                            className={progressBarClassName}
                            now={uploadProgress}
                            label={`${roundedUploadProgress}%`}
                        />
                    </div>
                </div>
                <div className={`PhotoShare_Btns ${boxClassName}`}>
                    <FacebookShareButton
                        className={faceBookClassName}
                        url={frameUrl}
                        quote={title}
                        onClick={() => this.onClickReactGAEvent("facebook")}
                    >
                        <FacebookIcon size={64} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                        className={twitterClassName}
                        url={frameUrl}
                        title={title}
                        onClick={() => this.onClickReactGAEvent("twitter")}
                    >
                        <TwitterIcon size={64} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        className={CLASS_BLOCK}
                        url={frameUrl}
                        title={title}
                        onClick={() => this.onClickReactGAEvent("linkedin")}
                    >
                        <LinkedinIcon size={64} round />
                    </LinkedinShareButton>
                    <EmailShareButton
                        className={emailClassName}
                        url={frameUrl}
                        subject={title}
                        onClick={() =>
                        {
                            this.onClickReactGAEvent("email");
                            window.location.href = newHref;
                        }}
                    >
                        <EmailIcon size={64} round />
                    </EmailShareButton>
                    <Button
                        className={instagramClassName}
                        id="instagramButton"
                        onClick={this.instagramButton}
                    >
                        {instagramSvg}
                    </Button>
                    <button
                        type="button"
                        className={downloadClassName}
                        id="downloadButton"
                        onClick={this.downloadButton}
                    >
                        {downloadSvg}
                    </button>
                    <Popover
                        open={showDownloadWarning}
                        anchorEl={downloadAnchor}
                        onClose={this.closeWarning}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <Box component="div">
                            <Typography style={{ padding: "2" }}>
                                To save the image on your device, long press the image and click save!
                            </Typography>
                        </Box>
                    </Popover>
                    <Popover
                        open={showInstagramInstructions}
                        anchorEl={instagramAnchor}
                        onClose={this.closeWarning}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <Box component="div">
                            <Typography style={{ padding: "2" }}>
                                {mobileTypography}
                            </Typography>
                        </Box>
                    </Popover>
                    <Button
                        className="PhotoShare_Close"
                        onClick={this.doneButton}
                        variant="outline-danger"
                    >
                        X
                    </Button>
                </div>
            </div>
        );
    }
}

PhotoShareView.defaultProps = {
    images: [],
    videos: [],
    uploads: [],
    campaign: PropTypes.object.isRequired,
    testFlag: false,
    // history: PropTypes.shape(historyPropTypes).isRequired,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired
};

PhotoShareView.propTypes = {
    images: PropTypes.instanceOf(Array),
    videos: PropTypes.instanceOf(Array),
    uploads: PropTypes.instanceOf(Array),
    campaign: PropTypes.shape({ root: PropTypes.string }),
    testFlag: PropTypes.bool,
    // history: PropTypes.shape(historyPropTypes),
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string })
};
