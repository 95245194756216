import React from "react";
import PropTypes from "prop-types";
import { BtnBack } from "./BtnBack/index";
import { BtnSwap } from "./BtnSwap/index";
import { BtnStartStop } from "./BtnStartStop/index";
import { BtnCapture } from "./BtnCapture/index";
import "./style.css";

const CameraBtnsView = ({ isVideoCaptureStarted, isCaptureBtnHoldType, handleSwap, handleDone,
    setShowCountDownOn, stopRecording, setContentTypeHandler }) =>
{
    const captureHandler = isVideoCaptureStarted ? stopRecording : setShowCountDownOn;

    const btnHoldType = (
        <BtnCapture
            captureHandler={captureHandler}
            setContentTypeHandler={setContentTypeHandler}
        />
    );

    const btnStartStopType = (
        <BtnStartStop
            captureHandler={captureHandler}
            setContentTypeHandler={setContentTypeHandler}
            setShowCountDownOn={setShowCountDownOn}
            isVideoStarted={isVideoCaptureStarted}
        />
    );

    const captureBtn = isCaptureBtnHoldType ? btnHoldType : btnStartStopType;

    return (
        <div className="CameraBtns">
            {captureBtn}
            {!isVideoCaptureStarted && <BtnBack handleDone={handleDone} />}
            {(false && !isVideoCaptureStarted) && <BtnSwap handleSwap={handleSwap} />}
        </div>
    );
};

CameraBtnsView.defaultProps = {
    isCaptureBtnHoldType: PropTypes.bool.isRequired,
    isVideoCaptureStarted: PropTypes.bool.isRequired,
    handleSwap: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
    setShowCountDownOn: PropTypes.func.isRequired,
    stopRecording: PropTypes.func.isRequired,
    setContentTypeHandler: PropTypes.func.isRequired
};

CameraBtnsView.propTypes = {
    isCaptureBtnHoldType: PropTypes.bool,
    isVideoCaptureStarted: PropTypes.bool,
    handleSwap: PropTypes.func,
    handleDone: PropTypes.func,
    setShowCountDownOn: PropTypes.func,
    stopRecording: PropTypes.func,
    setContentTypeHandler: PropTypes.func
};

export default CameraBtnsView;
