import React from "react";
import PropTypes from "prop-types";
import { BtnTakeAnother } from "./BtnTakeAnother";
import "./style.css";

const SuccessView = ({ type, onTakeAnother }) => (
    <div className="Success">
        <div className="Success_Container">
            <div className="Success_Header">
                <span className="Success_Header_Text">&#10003;</span>
            </div>
            <span className="Success_Disclaimer">Success</span>
            <span className="Success_Body">
                {`You have successfully shared to your ${type},
                once your photo has been approved, you will
                receive your photo.`}
            </span>
            <div className="Success_Btns">
                <BtnTakeAnother onTakeAnother={onTakeAnother} />
            </div>
        </div>
    </div>
);

SuccessView.defaultProps = {
    type: PropTypes.string.isRequired,
    onTakeAnother: PropTypes.func.isRequired
};

SuccessView.propTypes = {
    type: PropTypes.string,
    onTakeAnother: PropTypes.func
};

export { SuccessView };
