import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { SpinnerMsg } from "../../../SpinnerMsg";
import { CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO } from "../../../../assets/constants";
import "./style.css";

const BTN_HOLD_TIME = 35; // * 10 ms
const VIDEO_MIN_DURATION = 100; // * 10 ms
const COUNTER_TIME_STEP = 10; // ms

const BtnCaptureView = ({ captureHandler, setContentTypeHandler }) =>
{
    const [counter, setCounter] = useState(0);
    const [isStill, setIsStill] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    const [isBtnUnHold, setIsBtnUnHold] = useState(false);

    const [percentageClassName, setPercentageClassName] =
        useState("BtnCapture_Circle BtnCapture_CirclePercentage BtnCapture_CirclePercentageHidden");
    const intervalRef = useRef(null);
    let startTime;

    const startCounter = () =>
    {
        if (intervalRef.current)
        {
            return;
        }
        startTime = performance.now();
        intervalRef.current = setInterval(() =>
        {
            const nowTime = performance.now();
            const counterElapsed = (nowTime - startTime) / COUNTER_TIME_STEP;
            // console.log(`startTime=${startTime} nowTime=${nowTime}`);
            startTime = nowTime;
            setCounter((prevCounter) => prevCounter + counterElapsed);
        }, COUNTER_TIME_STEP);
    };

    const stopCounter = () =>
    {
        if (intervalRef.current)
        {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const startCounterCommon = () =>
    {
        startCounter();
    };

    const stopCounterCommon = () =>
    {
        setIsBtnUnHold(true);

        if (counter > VIDEO_MIN_DURATION)
        {
            stopCounter();
            captureHandler();
        }
    };

    const btnHandler = () =>
    {
        setShowSpinner(true);
    };

    const setUpPercentageClassName = () =>
    {
        if (counter > 2)
        {
            const percentageClassNameDefault = "BtnCapture_Circle BtnCapture_CirclePercentage";
            setPercentageClassName(percentageClassNameDefault);
        }
    };

    const setPercentage = () =>
    {
        const el = document.getElementsByClassName("BtnCapture_CirclePercentage")[0];

        el && el.style.setProperty("--percentage", (counter / 2)); // TODO counter
    };

    const setStillOrVideo = () =>
    {
        const contentType = (counter < BTN_HOLD_TIME) ? CONTENT_TYPE_STILL : CONTENT_TYPE_VIDEO;
        setContentTypeHandler(contentType);

        if (contentType !== CONTENT_TYPE_STILL)
        {
            setIsStill(false);
        }
    };

    const processVideoStart = () =>
    {
        if (!isStill && (counter >= BTN_HOLD_TIME))
        {
            if (!isVideoStarted)
            {
                setIsVideoStarted(true);
                captureHandler();
            }
        }
    };

    const processVideoStop = () =>
    {
        if (intervalRef.current)
        {
            stopCounter();

            if (!isBtnUnHold)
            {
                startCounter();
            }
            else
            {
                // eslint-disable-next-line no-lonely-if
                if (isStill)
                {
                    captureHandler();
                }
                else
                {
                    // eslint-disable-next-line no-lonely-if
                    if (counter > VIDEO_MIN_DURATION)
                    {
                        captureHandler();
                    }
                    else
                    {
                        startCounter();
                    }
                }
            }
        }
    };

    useEffect(() => () => stopCounterCommon(), []);

    useEffect(() =>
    {
        setUpPercentageClassName();
        setPercentage();
        setStillOrVideo();
        processVideoStart();
        processVideoStop();
    }, [counter]);

    return (
        <div className="BtnCapture">
            <div className="BtnCapture_Container">
                <SpinnerMsg
                    showSpinner={showSpinner}
                    text="Processing..."
                    showBackground={false}
                />
                <div className="BtnCapture_BtnContainer">
                    {!showSpinner && (
                        <button
                            type="button"
                            className="BtnCapture_Btn"
                            onClick={btnHandler}
                            onMouseDown={startCounterCommon}
                            onMouseUp={stopCounterCommon}
                            onTouchStart={startCounterCommon}
                            onTouchEnd={() =>
                            {
                                stopCounterCommon();
                                btnHandler();
                            }}
                        >
                            <svg className="BtnCapture_Circle BtnCapture_CircleBorder" viewBox="0 0 36 36">
                                <path
                                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    fill="none"
                                    stroke="#ffffff"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeDasharray="var(--percentageBorder), 100"
                                />
                            </svg>
                            <svg className={percentageClassName} viewBox="0 0 36 36">
                                <path
                                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    fill="none"
                                    stroke="#1E90FF"
                                    strokeWidth="1"
                                    strokeLinecap="round"
                                    strokeDasharray="var(--percentage), 100"
                                />
                            </svg>

                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

BtnCaptureView.defaultProps = {
    captureHandler: PropTypes.func.isRequired,
    setContentTypeHandler: PropTypes.func.isRequired
};

BtnCaptureView.propTypes = {
    captureHandler: PropTypes.func,
    setContentTypeHandler: PropTypes.func
};

export { BtnCaptureView };
