import { connect } from "react-redux";
import { BtnRetakeView } from "./BtnRetake";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnRetake = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnRetakeView);

export { BtnRetake };
