import { connect } from "react-redux";
import {
    selectCampaign,
    selectCampaignID,
    selectImageWorker,
    selectVideoWorker,
    selectJpgList,
    selectJpgListLength,
    selectLastSurveyId,
    selectTestFlag
} from "../../state/selectors";
import { addImage, addVideo } from "../../state/actions";
import PhotoApproveView from "./PhotoApprove";

const mapStateToProps = (state) => ({
    isCroppedToSquare: selectCampaign(state).crop_to_square,
    campaignID: selectCampaignID(state),
    campaign: selectCampaign(state),
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state),
    surveyID: selectLastSurveyId(state),
    testFlag: selectTestFlag(state),
    jpgList: selectJpgList(state),
    jpgListLength: selectJpgListLength(state),
    pwaShareButtonStyle: selectCampaign(state)?.pwa_share_button_style || "downloadonly"
});

const mapDispatchToProps = (dispatch) => ({
    addImage: (value) => dispatch(addImage(value)),
    addVideo: (value) => dispatch(addVideo(value))
});

const PhotoApprove = connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoApproveView);

export default PhotoApprove;
