import React from "react";
import PropTypes from "prop-types";
import { Button } from "../LeadGenActivity/Button";
import { HTMLPolicy } from "./HTMLPolicy/index";

export default function TermsPolicyView({ setHidePolicy })
{
    return (
        <div className="TermsAndConditions">
            <HTMLPolicy />
            <Button text="OK" onClickHandler={setHidePolicy} btnClass="HTMLTerms_Btn" />
        </div>
    );
}

TermsPolicyView.defaultProps = {
    setHidePolicy: PropTypes.func.isRequired
};

TermsPolicyView.propTypes = {
    setHidePolicy: PropTypes.func
};
