import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
import axios from "axios";
import * as rax from "retry-axios";
import { getImage, getVideo } from "../../db";
import { Header } from "../ScreenSelect/Header/Header";
import { BtnText } from "../CameraActivity/CameraBtns/BtnText";
import { BtnEmail } from "../CameraActivity/CameraBtns/BtnEmail";
import { BtnBack } from "../CameraActivity/CameraBtns/BtnBack";
import { Terms } from "../Sharing/Terms";
import { Phone } from "../Sharing/Phone";
import { Email } from "../Sharing/Email";
import { Success } from "../Sharing/Success";
import { serverURL } from "../../webapi/actions";
import { displayVideo } from "../PhotoApprove/PhotoApprove";
import { CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO } from "../../assets/constants";
import "./style.css";

const TYPE_PHONE = "phone";
const TYPE_EMAIL = "email";

export default function PhotoShareView({ location, history, isCroppedToSquare, smsMessageText,
    isSMSEnabled, isEmailEnabled })
{
    const [showTerms, setShowTerms] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [shareType, setShareType] = useState("");
    const [tagkastId, setTagkastID] = useState(0);
    const [deviceID, setDeviceID] = useState(0);
    const [galleryID, setGalleryID] = useState(0);
    const [pageUrl, setPageUrl] = useState("");
    const [isStill, setIsStill] = useState(true);

    const canvasRef = React.useRef(null);

    useEffect(() =>
    {
        const getTagkastId = () =>
        {
            const ID = parseInt(location.ID, 10);
            const isImage = location.contentType === CONTENT_TYPE_STILL;
            const getMedia = isImage ? getImage : getVideo;

            getMedia(ID).then((media) =>
            {
                if (media.isUploaded === 0)
                {
                    setTimeout(getTagkastId, 1000);
                }
                else
                {
                    setDeviceID(media.deviceID);
                    setGalleryID(media.galleryID);
                    setTagkastID(media.tagkastId);
                    setPageUrl(media.pageUrl);
                }
            });
        };

        if (!location || !location?.blobData || !location.ID || !location.contentType)
        {
            history.replace("/CameraActivity");
        }

        if (location.blobData && (location.contentType === CONTENT_TYPE_STILL))
        {
            const { blobData } = location;
            const newUrl = window.URL.createObjectURL(blobData);

            const ctx = canvasRef.current.getContext("2d");
            const img = new Image();

            img.onload = () =>
            {
                const { width, height } = img;
                const min = Math.min(width, height);
                const minOrWidth = isCroppedToSquare ? min : width;
                const minOrHeigth = isCroppedToSquare ? min : height;

                canvasRef.current.width = minOrWidth;
                canvasRef.current.height = minOrHeigth;

                const sx = (width > height) ? ((width - height) / 2).toFixed(0) : 0;
                const sySquare = (height > width) ? ((height - width) / 2).toFixed(0) : 0;
                const syRect = 0;
                const sy = isCroppedToSquare ? sySquare : syRect;
                const sWidth = minOrWidth;
                const sHeight = minOrHeigth;
                const dx = 0;
                const dy = 0;
                const dWidth = minOrWidth;
                const dHeight = minOrHeigth;

                ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

                // open result in a new window for testing
                // window.open(newUrl, "Image");
            };

            img.src = newUrl;
        }

        if (location.ID)
        {
            setTimeout(getTagkastId, 1000);
        }

        if (location.blobData && (location.contentType === CONTENT_TYPE_VIDEO))
        {
            displayVideo(location.blobData);
        }

        if (location.contentType)
        {
            setIsStill(location.contentType === CONTENT_TYPE_STILL);
        }
    }, [location, history]);

    const onText = () =>
    {
        setShareType(TYPE_PHONE);

        if (!isAgreed)
        {
            setShowTerms(true);
        }
        else
        {
            setShowPhone(true);
        }
    };

    const onEmail = () =>
    {
        setShareType(TYPE_EMAIL);

        if (!isAgreed)
        {
            setShowTerms(true);
        }
        else
        {
            setShowEmail(true);
        }
    };

    const onAgree = () =>
    {
        setShowTerms(false);
        setIsAgreed(true);

        if (shareType === TYPE_PHONE)
        {
            setShowPhone(true);
        }
        else
        {
            setShowEmail(true);
        }
    };

    const onNotAgree = () =>
    {
        setShowTerms(false);
    };

    const sendSms = (phone) =>
    {
        const urlType = isStill ? "v1/photos" : "v2/video";
        const url = `${serverURL}/${urlType}/sms/`;
        const formData = new FormData();
        let phoneString = phone.toString();
        if (phoneString[0] !== "+")
        {
            if (phoneString[0] !== "1")
            {
                phoneString = `+1${phoneString}`;
            }
            else
            {
                phoneString = `+${phoneString}`;
            }
        }

        formData.append("id", tagkastId.toString());
        formData.append("operator_id", "1082");
        formData.append("device_code", deviceID.toString());
        formData.append("phonenumber", phoneString);
        formData.append("is_post_event", "false");
        formData.append("gallery_id", galleryID.toString());
        formData.append("source", "app");
        formData.append("status", smsMessageText);

        axios({
            method: "post",
            url: url,
            data: formData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            raxConfig: {
                retry: 3,
                retryDelay: 5000,
                backoffType: "static",
                httpMethodsToRetry: ["POST"],
                statusCodesToRetry: [[500, 599]],
                onRetryAttempt: (err) =>
                {
                    const cfg = rax.getConfig(err);

                    // console.log("cfg:", cfg);
                    console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
                }
            }
        })
            .then((response) =>
            {
                if (response.data.status.code <= 300)
                {
                    console.info("Success");
                }
                else
                {
                    console.error("Error on sending with:", response.data.status.code);
                }
            })
            .catch((error) =>
            {
                console.info("[Error]:", error);
                history.replace("/CampaignInitialization");
            });
    };

    const sendEmail = (email) =>
    {
        const urlType = isStill ? "v1/photos" : "v2/video";
        const url = `${serverURL}/${urlType}/email/`;
        const jsonData = {};

        jsonData.id = isStill ? tagkastId.toString() : [tagkastId.toString()];
        jsonData.operator_id = "1082";
        jsonData.device_code = deviceID.toString();
        jsonData.email = email.toString();
        jsonData.is_post_event = false;
        jsonData.gallery_id = galleryID.toString();
        jsonData.source = "app";
        jsonData.status = pageUrl;
        jsonData.photos = [tagkastId.toString()];
        jsonData.subscribe = false;
        jsonData.is_instagram_send = false;

        axios({
            method: "post",
            url: url,
            data: jsonData,
            headers: { "Content-Type": "application/json" },
            raxConfig: {
                retry: 3,
                retryDelay: 5000,
                backoffType: "static",
                httpMethodsToRetry: ["POST"],
                statusCodesToRetry: [[500, 599]],
                onRetryAttempt: (err) =>
                {
                    const cfg = rax.getConfig(err);

                    // console.log("cfg:", cfg);
                    console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
                }
            }
        })
            .then((response) =>
            {
                if (response.data.status.code <= 300)
                {
                    console.info("Success");
                }
                else
                {
                    console.error("Error on sending with:", response.data.status.code);
                }
            })
            .catch((error) =>
            {
                console.info("[Error]:", error);
                history.replace("/CampaignInitialization");
            });
    };

    const onShare = (data) =>
    {
        if (shareType === TYPE_PHONE)
        {
            sendSms(data);
        }
        else
        {
            sendEmail(data);
        }

        setShowPhone(false);
        setShowEmail(false);
        setShowSuccess(true);
    };

    const onCancel = () =>
    {
        setShowPhone(false);
        setShowEmail(false);
    };

    const onTakeAnother = () =>
    {
        history.replace("/KioskStartScreen");
    };

    const mediaText = isStill ? "image" : "video";
    const handleDone = () => history.push("/KioskStartScreen");

    const canvasContainerClass = isCroppedToSquare ? "PhotoShare_Canvas_Square" : "PhotoShare_Canvas_Rectangle";

    return (
        <div className="PhotoShare">
            <Header />
            <span className="PhotoShare_Text">{`Share your ${mediaText}`}</span>
            <BtnBack handleDone={handleDone} />
            <div className={`PhotoShare_CanvasContainer ${canvasContainerClass}`}>
                <video
                    id="videoApproved"
                    className="PhotoApprove_Img2 PhotoApprove_DisplayNone"
                >
                    Sorry, your browser does not support embedded videos.
                </video>
                <canvas ref={canvasRef} className="PhotoShare_Img" id="Your frame" />
            </div>
            <div className="PhotoShare_Btns">
                { isSMSEnabled && <BtnText onText={onText} />}
                { isEmailEnabled && <BtnEmail onEmail={onEmail} />}
            </div>

            {showTerms && (
                <Terms
                    onAgree={onAgree}
                    onNotAgree={onNotAgree}
                />
            )}
            {showPhone && (
                <Phone
                    onShare={onShare}
                    onCancel={onCancel}
                />
            )}
            {showEmail && (
                <Email
                    onShare={onShare}
                    onCancel={onCancel}
                />
            )}
            {showSuccess && (
                <Success
                    type={shareType}
                    onTakeAnother={onTakeAnother}
                />
            )}
        </div>
    );
}

PhotoShareView.defaultProps = {
    isCroppedToSquare: PropTypes.bool.isRequired,
    isSMSEnabled: PropTypes.bool.isRequired,
    isEmailEnabled: PropTypes.bool.isRequired,
    smsMessageText: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    })
    // history: PropTypes.shape(historyPropTypes).isRequired
};

PhotoShareView.propTypes = {
    isCroppedToSquare: PropTypes.bool,
    isSMSEnabled: PropTypes.bool,
    isEmailEnabled: PropTypes.bool,
    smsMessageText: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
    // history: PropTypes.shape(historyPropTypes)
};
