import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const ShaneTextView = ({ gotoExamples }) => (
    <div className="ShaneText">
        <span className="ShaneText_Span">
            Tell us a story about how jewelry has been meaningful to you.
        </span>
        <span className="ShaneText_Span">
            Maybe your story is about an heirloom that was passed down to you,
            a piece of jewelry given to you with special symbolism, or the ring you proposed with.
            We want to hear it!
        </span>
        <span className="ShaneText_Span">
            You&apos;ll have 45 seconds to record your story. Not sure what to share? Listen to
        </span>
        <button
            className="ShaneText_Li"
            type="button"
            onClick={gotoExamples}
        >
            examples here.
        </button>
    </div>
);

ShaneTextView.defaultProps = {
    gotoExamples: PropTypes.func.isRequired
};

ShaneTextView.propTypes = {
    gotoExamples: PropTypes.func
};

export { ShaneTextView };
