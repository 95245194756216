import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
import * as rax from "retry-axios";
import axios from "axios";
import { blobToArrayBufferObject, getSurvey, putImage, getImage, putVideo } from "../../db";
import { computeChecksumMd5 } from "../../assets/utils";
import { Header } from "../ScreenSelect/Header/Header";
import { BtnRetake } from "../CameraActivity/CameraBtns/BtnRetake";
import { BtnApprove } from "../CameraActivity/CameraBtns/BtnApprove";
import { BtnPlayBack } from "../CameraActivity/CameraBtns/BtnPlayBack";
import { Uploading } from "./Uploading";
import { SpinnerMsg } from "../SpinnerMsg";
import sound from "../../assets/images/sound.jpg";
import { CLASS_NONE, CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO } from "../../assets/constants";
import "./style.css";

const PWA_SHARED_BTN =
    {
        DOWNLOAD_ONLY: "downloadonly",
        MESSAGING: "messaging",
        MEDIA_PAGE: "mediapage"
    };

const displayVideo = (blobData) =>
{
    const videoEl = document.getElementById("videoApproved");

    if (!(blobData instanceof Blob)) throw new Error("`videoFile` must be a Blob or File object.");
    if (!(videoEl instanceof HTMLVideoElement)) throw new Error("`videoEl` must be a <video> element.");

    const newObjectUrl = URL.createObjectURL(blobData);
    const oldObjectUrl = videoEl.currentSrc;

    if (oldObjectUrl && oldObjectUrl.startsWith("blob:"))
    {
        videoEl.src = "";
        URL.revokeObjectURL(oldObjectUrl);
    }

    videoEl.src = newObjectUrl;
    videoEl.autoplay = false;
    videoEl.loop = false;
    videoEl.muted = false;
    videoEl.playsInline = false;

    videoEl.setAttribute("class", "PhotoApprove_Img PhotoApprove_DisplayVisible");
    videoEl.load();
};

export default function PhotoApproveView({ jpgList, jpgListLength, testFlag, location, isCroppedToSquare,
    history, campaignID, surveyID, campaign, addImage, addVideo, imageWorker, videoWorker, pwaShareButtonStyle
})
{
    const [photoKey, setPhotoKey] = useState(null);
    const [showBtns, setShowBtns] = useState(true);
    const [headerText, setHeaderText] = useState("image");
    const [contentType, setContentType] = useState("image");
    const [mediaUrl, setMediaUrl] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [showAudioText, setShowAudioText] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [showUploading, setShowUploading] = useState(false);

    const videoRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const thumbnailRef = React.useRef(null);
    const btnClass = `PhotoApprove_Btns ${showBtns ? "" : "PhotoApprove_Btns_Disabled"}`;
    const containerTypeClass = isCroppedToSquare ? "PhotoApprove_Canvas_Square" : "PhotoApprove_Canvas_Rectangle";
    const containerClass = `PhotoApprove_CanvasContainer ${containerTypeClass}`;

    const getJpgName = () =>
    {
        let jpgName = null;

        if (jpgList?.length > 0)
        {
            jpgName = jpgList.shift();
            const position = jpgListLength - jpgList.length;
            const percentage = ((position * 100) / jpgListLength).toFixed(0);
            console.log("Processing", jpgName, `[${position}/${jpgListLength}][${percentage}%]`);
        }

        return jpgName;
    };

    const processMediaPageVideo = (nextHistory) =>
    {
        setTimeout(() => window.location.replace(nextHistory), 7000);
    };

    const processMediaPageImage = (ID) =>
    {
        setTimeout(() =>
        {
            getImage(ID)
                .then(({ pageUrl }) =>
                {
                    setTimeout(() => window.location.replace(pageUrl), 2000);
                })
                .catch((error) => console.info("[Error]:", error));
        }, 2000);
    };

    const goToPhotoShare = (ID) =>
    {
        const isImage = contentType === CONTENT_TYPE_STILL;
        const type = isImage ? "image" : "video";
        let shouldChangeHistory = false;
        let nextHistory;

        switch (pwaShareButtonStyle)
        {
            case PWA_SHARED_BTN.DOWNLOAD_ONLY:
                nextHistory = `/PhotoShare/${type}/${ID}`;
                shouldChangeHistory = true;
                break;

            case PWA_SHARED_BTN.MESSAGING:
                shouldChangeHistory = true;
                nextHistory = "/PhotoShare";
                break;

            case PWA_SHARED_BTN.MEDIA_PAGE:
                nextHistory = mediaUrl;
                setShowSpinner(true);

                if (isImage)
                {
                    processMediaPageImage(ID);
                }
                else
                {
                    processMediaPageVideo(nextHistory);
                }
                break;

            default:
                console.error("Invalid pwa shared btn style [", pwaShareButtonStyle, "]");
                nextHistory = "/KioskStartScreen";
                shouldChangeHistory = true;
        }

        shouldChangeHistory && history.replace(
            {
                pathname: "/TnxPage", // nextHistory
                blobData: location.blobData,
                ID: ID,
                contentType: contentType
            });
    };

    const uploadVideoDirect = (response, blobData, blobThumbnail) =>
    {
        const uploadVideoUrl = response.data.data.output.upload_url;
        const uploadThumbnailUrl = response.data.data.output.upload_thumbnail_url;
        const tagkastId = response.data.data.output.video_id;
        const pageUrl = response.data.data.output.page_url;
        const qrCode = response.data.data.meta.parameters.qr_code;
        const thumbnailHeaders = new Headers();
        const videoHeaders = new Headers();

        thumbnailHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        videoHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        fetch(uploadThumbnailUrl, {
            method: "PUT",
            body: blobThumbnail,
            headers: thumbnailHeaders
        }).then((resp) => (!resp.ok) && console.log("Error", resp.statusText));
        fetch(uploadVideoUrl, {
            method: "PUT",
            body: blobData,
            headers: videoHeaders
        }).then((resp) => (!resp.ok) && console.log("Error", resp.statusText));

        // TODO qrcode

        blobToArrayBufferObject(blobData).then(async (arrayObjVideo) =>
        {
            const newVideo = {
                campaignID: campaignID,
                galleryID: campaign.gallery_id,
                created_at: (new Date()).getTime(),
                isUploaded: 0,
                deviceID: campaign.device_code,
                video: arrayObjVideo,
                qrcode: qrCode,
                tagkastId: tagkastId,
                pageUrl: pageUrl
            };

            setMediaUrl(pageUrl);

            putVideo(newVideo).then(async (videoKey) =>
            {
                addVideo(newVideo);
                videoWorker && videoWorker.postMessage({ messageType: "upload_videos" });

                setPhotoKey(videoKey);
                setShowBtns(false);
            });
        });
    };

    const onRetake = useCallback(() =>
    {
        const nextHistory = "/Background";
        history.replace(nextHistory);
    }, [history]);

    const registerVideo = (thumbnailBlob, qrCode) =>
    {
        const { blobData } = location;
        const url = "https://api.tagkast.com/v2/galleries/register_video";
        const operatorId = "2083"; // TODO get operator_id
        const file = new File([blobData], "myVideo.mp4");

        computeChecksumMd5(file).then((originalChecksum) =>
        {
            const bodyFormData = new FormData();
            bodyFormData.append("gallery_id", campaign.gallery_id);
            bodyFormData.append("is_post_event", "false");
            bodyFormData.append("device_code", campaign.device_code);
            bodyFormData.append("operator_id", operatorId);
            bodyFormData.append("original_checksum", originalChecksum);
            if (qrCode != null)
            {
                bodyFormData.append("qr_code", qrCode);
            }

            setShowUploading(true);

            rax.attach();

            // register video
            return axios({
                method: "post",
                url: url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
                raxConfig: {
                    retry: 100,
                    retryDelay: 5000,
                    backoffType: "static",
                    httpMethodsToRetry: ["POST"],
                    statusCodesToRetry: [[404, 429], [500, 599]],
                    onRetryAttempt: (err) =>
                    {
                        const cfg = rax.getConfig(err);
                        console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
                    }
                }
            })
                .then((response) => uploadVideoDirect(response, blobData, thumbnailBlob))
                .catch((response) => console.error(response));
        });
    };

    const onApprove = () =>
    {
        setShowHeader(false);

        if (location && location.type)
        {
            const { blobData, type, thumbnailCanvasSize } = location;

            if (type === CONTENT_TYPE_STILL)
            {
                blobToArrayBufferObject(blobData).then(async (arrayObj) =>
                {
                    const newImage = {
                        campaignID: campaignID,
                        galleryID: campaign.gallery_id,
                        created_at: (new Date()).getTime(),
                        isUploaded: testFlag || 0,
                        deviceID: campaign.device_code,
                        image: arrayObj,
                        qrcode: null
                    };

                    if (campaign.is_qrcode_enabled)
                    {
                        const survey = await getSurvey(surveyID);
                        campaign.lead_generation.questions.forEach((question) =>
                        {
                            if (question.category === "qrcode")
                            {
                                survey.survey.forEach((answer) =>
                                {
                                    if (answer.id === question.id)
                                    {
                                        newImage.qrcode = answer.answer;
                                    }
                                });
                            }
                        });
                    }

                    putImage(newImage).then((key) =>
                    {
                        addImage(newImage);
                        imageWorker && imageWorker.postMessage({ messageType: "upload_images" });
                        // eslint-disable-next-line no-unused-vars
                        const jpgName = getJpgName(); // TODO

                        setPhotoKey(key);
                        setShowBtns(false);

                        // TODO does not work with new PHOTO SHARE
                        // open result in a new window
                        // const win = window.open(`/#/PhotoShare/image/${photoKey}`, "_blank");
                        // win.focus();
                    });
                });
            }
            else if (type === CONTENT_TYPE_VIDEO)
            {
                const { current: thumbnailCanvas } = thumbnailRef;
                [thumbnailCanvas.width, thumbnailCanvas.height] = thumbnailCanvasSize;

                const ctx = thumbnailCanvas.getContext("2d");

                ctx.drawImage(videoRef.current, 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
                ctx.canvas.toBlob(
                    async (thumbnailBlob) =>
                    {
                        let qrCode = null;
                        if (campaign.is_qrcode_enabled)
                        {
                            const survey = await getSurvey(surveyID);
                            campaign.lead_generation.questions.forEach((question) =>
                            {
                                if (question.category === "qrcode")
                                {
                                    survey.survey.forEach((answer) =>
                                    {
                                        if (answer.id === question.id)
                                        {
                                            qrCode = answer.answer;
                                        }
                                    });
                                }
                            });
                        }
                        registerVideo(thumbnailBlob, qrCode);
                    },
                    "image/jpeg",
                    1
                );
            }
            else
            {
                onRetake();
            }
        }
    };

    const playBackVideo = () =>
    {
        videoRef.current.play();
        setShowAudioText(true);
    };

    const hideAudioText = () =>
    {
        setShowAudioText(false);
    };

    const audioText = (
        <span className="PhotoApprove_AudioText">Your audio is playing...</span>
    );

    useEffect(() =>
    {
        if (location && location.blobData)
        {
            const ctx = canvasRef.current.getContext("2d");
            const { blobData, type } = location;

            setContentType(type);

            if (type === CONTENT_TYPE_STILL)
            {
                const newUrl = window.URL.createObjectURL(blobData);

                const img = new Image();

                img.onload = () =>
                {
                    const { width, height } = img;

                    const min = Math.min(width, height);
                    const minOrWidth = isCroppedToSquare ? min : width;
                    const minOrHeigth = isCroppedToSquare ? min : height;

                    canvasRef.current.width = minOrWidth;
                    canvasRef.current.height = minOrHeigth;

                    const sx = (width > height) ? ((width - height) / 2).toFixed(0) : 0;
                    const sySquare = (height > width) ? ((height - width) / 2).toFixed(0) : 0;
                    const syRect = 0;
                    const sy = isCroppedToSquare ? sySquare : syRect;
                    const sWidth = minOrWidth;
                    const sHeight = minOrHeigth;
                    const dx = 0;
                    const dy = 0;
                    const dWidth = minOrWidth;
                    const dHeight = minOrHeigth;

                    ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

                    // open result in a new window for testing
                    // window.open(newUrl, "Image");
                };

                img.src = newUrl;
            }
            else if (type === CONTENT_TYPE_VIDEO)
            {
                setHeaderText("video");
                displayVideo(blobData);

                canvasRef.current.setAttribute("class", "PhotoApprove_DisplayNone");
            }
            else
            {
                onRetake();
            }
        }
        else
        {
            onRetake();
        }
    }, [location, onRetake, canvasRef]);

    useEffect(() =>
    {
        videoRef.current.addEventListener("ended", hideAudioText, false);

        return () =>
        {
            videoRef.current.removeEventListener("ended", hideAudioText, false);
        };
    }, []);

    return (
        <div className="PhotoApprove">
            {false && showHeader && <Header />}
            {false && showHeader && <span className="PhotoApprove_Text">{`Happy with your ${headerText}?`}</span>}
            <button
                type="button"
                className="PhotoApprove_PlayBackBtn"
                onClick={playBackVideo}
            >
                <img className="PhotoApprove_ImgSound" src={sound} alt="sound" />
            </button>

            <div className={containerClass}>
                <video
                    ref={videoRef}
                    id="videoApproved"
                    className="PhotoApprove_Img PhotoApprove_DisplayNone"
                    autoPlay={false}
                    loop={false}
                    muted={false}
                >
                    Sorry, your browser does not support embedded videos.
                </video>
                <canvas ref={thumbnailRef} className={CLASS_NONE} />
                <canvas ref={canvasRef} className="PhotoApprove_Img" />
                {((photoKey || showUploading) && !showSpinner) && (
                    <Uploading
                        ID={photoKey}
                        type={contentType}
                        goToPhotoShare={goToPhotoShare}
                    />
                )}
                {showSpinner && (
                    <SpinnerMsg
                        showSpinner={showSpinner}
                        text="Redirecting..."
                        showBackground
                    />
                )}
            </div>
            <div className={btnClass}>
                <BtnPlayBack onClick={playBackVideo} />
                <BtnRetake onRetake={onRetake} />
                <BtnApprove onApprove={onApprove} />
            </div>
            {showAudioText && audioText}
        </div>
    );
}

PhotoApproveView.defaultProps = {
    testFlag: false,
    jpgListLength: 0,
    campaignID: PropTypes.number.isRequired,
    surveyID: PropTypes.number.isRequired,
    pwaShareButtonStyle: PropTypes.string.isRequired,
    jpgList: [],
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
    campaign: PropTypes.object.isRequired,
    // history: PropTypes.shape(historyPropTypes).isRequired,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired,
    addImage: PropTypes.func.isRequired,
    addVideo: PropTypes.func.isRequired
};

PhotoApproveView.propTypes = {
    testFlag: PropTypes.bool,
    jpgListLength: PropTypes.number,
    campaignID: PropTypes.number,
    surveyID: PropTypes.number,
    pwaShareButtonStyle: PropTypes.string,
    jpgList: PropTypes.instanceOf(Array),
    location: PropTypes.shape({
        pathname: PropTypes.string
    }),
    campaign: PropTypes.shape({ root: PropTypes.string }),
    // history: PropTypes.shape(historyPropTypes),
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    addImage: PropTypes.func,
    addVideo: PropTypes.func
};

export { displayVideo };
