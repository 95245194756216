import React from "react";
import ScrollArea from "react-scrollbar";

export const HTMLPolicy = () => (
    <ScrollArea
        speed={0.8}
        className="HTMLTerms"
        horizontal={false}
        contentClassName="HTMLTerms_El"
    >
        <div className="HTMLTerms_Header">
            <span>Messaging Privacy Policy</span>
        </div>
        <span className="HTMLTerms_Lvl1">
            This Messaging Program Privacy Policy explains how Shane Co. collects and uses information about
            you in relation to its text message marketing program (the “Messaging Service”). We use Attentive
            to provide the Messaging Service to you. For the purposes of the Messaging Service, Attentive
            acts as our service provider and data processor of your information.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Collection of Information</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            We collect various information on our behalf from and about you, including information you directly provide
            when you use the Messaging Service. For example, we collect the phone number and email address you provided
            when signing up for the Messaging Service. When you send messages via the Messaging Service, we will also
            collect your messaging history and any information included in those messages.
        </span>
        <span className="HTMLTerms_Lvl1">
            We may also collect information about you using cookies or similar technologies. Cookies are pieces of
            information that are stored by your browser on the hard drive or memory of your device. Cookies enable
            personalization of your experience on the Messaging Service (e.g., sending you personalized text messages
            such as shopping cart reminders).
        </span>
        <span className="HTMLTerms_Lvl1">
            If you participate in a contest, sweepstakes, research study, or email survey associated with the
            Messaging Service, we will collect basic contact information and any other information you choose
            to provide in connection with these activities. We will also collect your contact information if you
            contact us with questions about the Messaging Service or for customer service.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Use of Information</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            We use your information to deliver, analyze, maintain and support the Messaging Service. We may also use
            your information to enhance the Messaging Service features and customize and personalize your experiences
            on the Messaging Service.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Sharing of Information</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            We may share, transfer, or disclose your information, if you consent to us doing
            so, as well as in the following circumstances:
        </span>
        <span className="HTMLTerms_Lvl1">
            &#x2022;
            <i> Service Providers. </i>
            We may share your information with third parties to help us provide the Messaging Service to you.
        </span>
        <span className="HTMLTerms_Lvl1">
            &#x2022;
            <i> Legal Requirement and Protection of Attentive and Others. </i>
            We may disclose your information as we believe such disclosure is necessary or appropriate to: (i) comply
            with applicable law and legal processes; (ii) respond to requests from public and government authorities,
            including public and government authorities outside your country of residence; (iii) enforce a contract
            with us; (iv) protect our rights, privacy, safety, or property, and/or that of our affiliates, you or
            others; and (v) allow us to pursue available remedies or limit the damages that we may sustain.
        </span>
        <span className="HTMLTerms_Lvl1">
            From time to time, we may share aggregate or de-identified information about use of the Messaging Service
            and such aggregated or de-identified information may be shared with any third party, including advertisers,
            promotional partners, and sponsors.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Protection of Information</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            We take a variety of physical, technical, administrative, and organizational security measures based on
            the sensitivity of the information we collect to protect your information against accidental or unlawful
            destruction or accidental loss, alteration, unauthorized disclosure or access. Unfortunately, no online
            activity can be guaranteed to be 100% secure. While we strive to protect your information against
            unauthorized use or disclosure, we cannot ensure or warrant the security of any information you provide.
            We do not accept liability for unintentional disclosure.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Retention of Information</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            We retain your information for as long as you participate in the Messaging Service or as needed to
            comply with applicable legal obligations. We will also retain and use your information as necessary
            to resolve disputes, protect us and our customers, and enforce our agreements.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Choices and Controls</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            Consent to receive automated marketing text messages is not a condition of any purchase. You can
            opt-out of receiving further commercial text messages via the Messaging Service by responding to
            any of our text messages with any of the following replies: STOP, END, CANCEL, UNSUBSCRIBE, or QUIT.
            For additional opt-out information, please review our
            <a href="https://attnl.tv/t/LEr"> Terms</a>
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Customer Care</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            If you are experiencing any problems with the Messaging Service, please visit
            <a href=" https://support.attentivemobile.com/help/"> https://support.attentivemobile.com/help/ </a>
            and submit the form with details about your problem or your request for support,
            or email support@attentivemobile.com.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Main Privacy Policy</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            By signing up to receive text messages from us, you also agree to our main
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.shaneco.com/about/privacy-policy?promo_name=nav&promo_id=footer&promo_creative=privacypolicy"> Privacy Policy.</a>
        </span>
    </ScrollArea>
);
