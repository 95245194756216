import { connect } from "react-redux";
import { selectCampaign } from "../../state/selectors";
import PhotoShareView from "./PhotoShare";

const mapStateToProps = (state) => ({
    isCroppedToSquare: selectCampaign(state).crop_to_square,
    smsMessageText: selectCampaign(state).sms_message_text,
    isSMSEnabled: selectCampaign(state).is_twilio_enabled,
    isEmailEnabled: selectCampaign(state).is_email_button_enabled
});

const mapDispatchToProps = () => ({
});

const PhotoShare = connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoShareView);

export default PhotoShare;
