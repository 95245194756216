export const selectCampaignID = (state) => (state.campaignID);

export const selectCampaign = (state) => (state.campaign);

export const selectImageWorker = (state) => (state.imageWorker);

export const selectHideHeader = (state) => (state.hideHeader);

export const selectUploads = (state) => (state.uploads);

export const selectOrientation = (state) => (state.orientation);

export const selectLastSurveyId = (state) => (state.lastSurveyId);

export const selectJpgList = (state) => (state.jpgList);

export const selectIsProcessPhotosStarted = (state) => (state.isProcessPhotosStarted);

export const selectIsProcessPhotosReady = (state) => (state.isProcessPhotosReady);

export const selectJpgListLength = (state) => (state.jpgListLength);

export const selectTestFlag = (state) => (state.testFlag);

export const selectImages = (state) => (state.images);

export const selectVideoWorker = (state) => (state.videoWorker);

export const selectVideos = (state) => (state.videos);

export const selectArtworkFrame = (state) => (state.artworks.frame);

export const selectArtworkGreenScreenBackground = (state) => (state.artworks.greenScreenBackground);

export const selectArtworkAppBackground = (state) => (state.artworks.appBackground);

export const selectArtworkFace = (state) => (state.artworks.face);

export const selectContentType = (state) => (state.contentType);

export const selectOuterCSS = (state) => (state.outerCSS);
