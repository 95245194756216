/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Error, NOERROR } from "../Error";
import "./style.css";

const Checkbox1 = ({ setShowConditions, setShowPolicy }) => (
    <div className="Checkbox_HTML">
        <span className="Checkbox_HTMLSpan">
            By checking this box, you agree to receive recurring automated promotional and personalized
            marketing text messages (e.g. cart reminders) from Shane Co at the cell number used when
            signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel.
            Msg frequency varies. Msg &amp; data rates may apply.
        </span>
        <span> View </span>
        <button
            type="button"
            className="LeadGenActivity_Policy"
            onClick={setShowConditions}
        >
            Terms
        </button>
        <span>&#160;&amp;&#160;</span>
        <button
            type="button"
            className="LeadGenActivity_Policy"
            onClick={setShowPolicy}
        >
            Privacy
        </button>
    </div>
);

const Checkbox2 = ({ setShowTerms }) => (
    <div className="Checkbox_HTML">
        <button
            type="button"
            className="LeadGenActivity_Terms"
            onClick={setShowTerms}
        >
            I accept the terms and conditions
        </button>
    </div>
);

const CheckBox = ({ label, ...props }) =>
{
    const [field, meta] = useField(props);
    const { id, name, placeholder, setShowTerms, setShowConditions, setShowPolicy } = props;

    const checkbox1 = <Checkbox1 setShowConditions={setShowConditions} setShowPolicy={setShowPolicy} />;
    const checkbox2 = <Checkbox2 setShowTerms={setShowTerms} />;

    const checkbox = (name === 15945 || name === 16003 || name === 16090) ? checkbox1 : checkbox2;

    return (
        <div className="Checkbox">
            <label
                htmlFor={id || name}
                className="Checkbox_Label"
            >
                <input
                    className="Checkbox_Input"
                    type="checkbox"
                    readOnly
                    name={name}
                    placeholder={placeholder}
                    {...field}
                />
                {checkbox}
            </label>
            <Error error={(name !== 15948 && name !== 16004 && name !== 16091) || meta.error || NOERROR} />
        </div>
    );
};

CheckBox.defaultProps = {
    label: PropTypes.string.isRequired,
    setShowTerms: null,
    setShowConditions: null,
    setShowPolicy: null
};

CheckBox.propTypes = {
    label: PropTypes.string,
    setShowTerms: PropTypes.func,
    setShowConditions: PropTypes.func,
    setShowPolicy: PropTypes.func
};

export { CheckBox };
