import { connect } from "react-redux";
import { BtnBackView } from "./BtnBack";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnBack = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnBackView);

export { BtnBack };
