import { connect } from "react-redux";
import { BtnApproveView } from "./BtnApprove";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnApprove = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnApproveView);

export { BtnApprove };
