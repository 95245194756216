import React, { Component } from "react";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import {
    getArtwork,
    arrayBufferObjectToBlob
} from "../../db";
import { isImageOnUrl } from "../../assets/utils";
import "./style.css";

export default class KioskStartScreenView extends Component
{
    constructor(props)
    {
        super(props);
        const { lockOrientation, orientation } = props;
        const newComponentOrientation = (props.lockOrientation !== "none") ? lockOrientation : orientation;

        this.state = {
            kioskBackground: null,
            componentOrientation: newComponentOrientation,
            isKioskDataImage: null,
            mediaClassName: null
        };

        this.imageRef = React.createRef();
        this.videoRef = React.createRef();
    }

    async componentDidMount()
    {
        const { imageWorker, videoWorker, campaign, history } = this.props;

        if (campaign === null)
        {
            console.log("CAMPAIGN NOT INITIALIZED, POPPING BACK");
            history.replace("/CampaignInitialization");

            return;
        }

        if (imageWorker && imageWorker.postMessage)
        {
            imageWorker.postMessage({ messageType: "upload_images" });
            imageWorker.postMessage({ messageType: "upload_surveys" });
        }

        if (videoWorker && videoWorker.postMessage)
        {
            videoWorker.postMessage({ messageType: "upload_videos" });
            videoWorker.postMessage({ messageType: "upload_surveys" });
        }

        await this.updateArtWork();

        this.setMediaClassName();
    }

    async componentDidUpdate()
    {
        this.isOrientationChanged() && await this.updateArtWork();
    }

    updateArtWork = async () =>
    {
        const { orientation, lockOrientation } = this.props;
        const artOrientation = (lockOrientation !== "none") ? lockOrientation : orientation;

        getArtwork(`kiosk_background_${artOrientation}`).then((result) =>
        {
            try
            {
                let newKioskBackground = null;
                let newIsKioskDataImage = null;

                if (result != null)
                {
                    const { url } = result;

                    newIsKioskDataImage = isImageOnUrl(url);

                    if (newIsKioskDataImage)
                    {
                        const blob = arrayBufferObjectToBlob(result.data, result.mimeType);
                        newKioskBackground = URL.createObjectURL(blob);
                    }
                    else
                    {
                        newKioskBackground = result.url;
                    }
                }

                this.setState({
                    kioskBackground: newKioskBackground,
                    isKioskDataImage: newIsKioskDataImage
                });
            }
            catch (error)
            {
                console.error(error.toLocaleString());
            }
        });
    }

    isOrientationChanged = () =>
    {
        const { orientation, lockOrientation } = this.props;
        const { componentOrientation } = this.state;

        if (lockOrientation !== "none")
        {
            if (componentOrientation !== lockOrientation)
            {
                this.setState({ componentOrientation: lockOrientation });

                return true;
            }

            return false;
        }

        if (orientation && (!componentOrientation || componentOrientation !== orientation))
        {
            this.setState({ componentOrientation: orientation });

            return true;
        }

        return false;
    }

    clickHandler = (e) =>
    {
        const { history, campaign } = this.props;

        const nextHistory = campaign.is_lead_generation_on ? "/LeadGenActivity" : "/ContentType";
        e.preventDefault();
        history.push(nextHistory);
    }

    setMediaClassName = () =>
    {
        const { lockOrientation } = this.props;
        const { isKioskDataImage } = this.state;
        let newMediaClassName = "KioskStartScreenView_Media";

        if (lockOrientation === "portrait")
        {
            newMediaClassName += " KioskStartScreenView_Media__Portrait";
        }
        else if (lockOrientation === "landscape")
        {
            newMediaClassName += " KioskStartScreenView_Media__Landscape";
        }

        if (isKioskDataImage !== null)
        {
            if (!isKioskDataImage)
            {
                const imgEl = this.imageRef.current;

                if (imgEl)
                {
                    imgEl.className = "CameraActivity_BlockNone";
                }
            }
            else
            {
                const videoEl = this.videoRef.current;

                if (videoEl)
                {
                    videoEl.className = "CameraActivity_BlockNone";
                }
            }
        }

        this.setState({ mediaClassName: newMediaClassName });
    }

    render()
    {
        const { imageRef, videoRef } = this;
        const { kioskBackground, mediaClassName } = this.state;

        if (!kioskBackground)
        {
            return null;
        }

        return (
            <div
                role="main"
                className="KioskStartScreenView_Container"
            >
                <button
                    className="KioskStartScreenView_Button"
                    type="button"
                    onClick={this.clickHandler}
                >
                    <img
                        ref={imageRef}
                        className={mediaClassName}
                        src={kioskBackground}
                        alt="Kiosk Background"
                    />
                    <video
                        ref={videoRef}
                        className={mediaClassName}
                        src={kioskBackground}
                        autoPlay
                        playsInline
                        loop
                        muted
                        crossOrigin="anonymous"
                        preload="auto"
                    />

                </button>
            </div>
        );
    }
}

KioskStartScreenView.defaultProps = {
    campaign: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired,
    lockOrientation: PropTypes.string.isRequired,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

KioskStartScreenView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    orientation: PropTypes.string,
    lockOrientation: PropTypes.string,
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string })
    // history: PropTypes.shape(historyPropTypes)
};
