/* eslint-disable max-len */

import React, { Component } from "react";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import * as rax from "retry-axios";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { Base64 } from "js-base64";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ERROR_NO_SERVER, ERROR_CORS } from "../AssertPopup/errors";
import { SpinnerMsg } from "../SpinnerMsg";
import { updateSetting, putCampaign, updateArtwork, db } from "../../db";
import { AssertPopup } from "../AssertPopup/AssertPopup";
import { isImageOnUrl } from "../../assets/utils";
import "./style.css";

// TODO update the values with real stuff
const venueID = "";

export default class CampaignInitializationView extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            initializeState: props.match.params.campaignCode ? 1 : 0,
            campaignCode: props.match.params.campaignCode || "",
            hasParameterizedCode: !!props.match.params.campaignCode,
            statusCode: 0,
            statusText: "",
            campaignID: 0,
            campaign: null,
            progressValue: 0,
            showAssert: false,
            showAssertErrorType: ERROR_NO_SERVER,
            installationID: 0
        };
    }

    async componentDidMount()
    {
        const { clearState, testFlag, match, hideHeader } = this.props;
        const { campaignCode } = match.params;

        /* Clear state for testing only */
        testFlag && clearState();

        this.setInstallationID();

        campaignCode && hideHeader();
        campaignCode && this.verifyCampaign();
    }

    setInstallationID = () =>
    {
        const installationIDLocalStorage = localStorage.getItem("installationID");
        let newID = 0;

        if (installationIDLocalStorage)
        {
            newID = installationIDLocalStorage;
        }
        else
        {
            const randomNumber = this.getRandomNumber();

            newID = Base64.encode(`${randomNumber}_${Date.now()}_${randomNumber}`);
            localStorage.setItem("installationID", newID);
        }

        this.setState({ installationID: newID });
    };

    getRandomNumber = () => (Math.floor(Math.random() * 1000) + 1);

    getStatusClass = () =>
    {
        const { statusCode } = this.state;
        const status = statusCode === 0 || statusCode === 200;

        return status ? "goodStatus" : "errorStatus";
    };

    setStatusCodeTextAndInit = (statusText, statusCode, initializeState) =>
    {
        this.setState({
            statusText,
            statusCode,
            initializeState
        });
    };

    assertCallback = () =>
    {
        window.location.reload(true);
    };

    verifyCampaign = (event) =>
    {
        const { verifyCampaignCode } = this.props;
        const { campaignCode } = this.state;

        event && event.preventDefault();
        this.setStatusCodeTextAndInit("Please wait...", 0, 1);

        verifyCampaignCode(campaignCode)
            .then((result) =>
            {
                this.processVerifyCampaignResult(result);
            })
            .catch((error) =>
            {
                console.error("[Error]:", error);
                this.setState({ showAssert: true });
            });
    };

    processVerifyCampaignResult = (result) =>
    {
        const { status, data } = result;
        const { code: statusCode } = data.status;

        // alert("Got result as " + result);

        if (status < 200 || 300 <= status)
        {
            // alert("Got status as " + status);
            this.setStatusCodeTextAndInit("Network connectivity error!", status, 0);

            return;
        }

        if (statusCode !== 200)
        {
            // alert("Got data code as " + statusCode);
            this.setStatusCodeTextAndInit("Invalid access code!", statusCode, 0);

            return;
        }

        const { id: campaignID } = data.data.output.campaign;

        this.setState({
            statusCode,
            campaignID,
            statusText: "Please wait while we get ready…"
        });

        db.artwork.clear().then(() => this.verifyOperator());
    };

    verifyOperator = () =>
    {
        const { verifyOperator } = this.props;
        const defaultOperatorID = "5555555555";

        verifyOperator(defaultOperatorID)
            .then((result) => this.processVerifyOperatorResult(result))
            .catch((error) =>
            {
                console.log("[Error]:", error);
                this.setState({ showAssert: true });
            });
    };

    processVerifyOperatorResult = ({ status, data }) =>
    {
        if (status < 200 || 300 <= status)
        {
            // alert("Got status as " + status);
            this.setStatusCodeTextAndInit("Network connectivity error!", status, 0);

            return;
        }

        const { code: statusCode } = data.status;
        if (statusCode !== 200)
        {
            // alert("Got data code as " + statusCode);
            this.setStatusCodeTextAndInit("Invalid operator ID!", statusCode, 0);

            return;
        }

        this.setState({
            statusCode,
            initializeState: 2
        });

        this.loadCampaign();
    };

    loadOuterCSSandCampaign = (campaign) =>
    {
        const { custom_css_url: outerCssUrl } = campaign;
        const { setOuterCSS } = this.props;

        const params = { method: "get", url: outerCssUrl, headers: { "Content-Type": "application/json" } };

        axios(params).then((response) =>
        {
            if (response.status === 200)
            {
                if (!document.getElementById("dynamicCSS_tagkast") && (response.data.length !== 0))
                {
                    setOuterCSS(response.data);

                    const head = document.getElementsByTagName("head")[0];
                    const newStyle = document.createElement("style");

                    newStyle.id = "dynamicCSS_tagkast";
                    newStyle.appendChild(document.createTextNode(response.data));
                    head.appendChild(newStyle);
                }

                this.setCampaign(campaign);
            }
        }).catch((error) =>
        {
            console.error("[Error]:", error);

            this.setState({
                showAssert: true,
                showAssertErrorType: ERROR_CORS
            });
        });
    };

    setCampaign = (campaign) =>
    {
        const { is_green_screen_enabled: enableGreenScreen } = campaign;
        const { setCampaign } = this.props;

        if (enableGreenScreen)
        {
            // MODNetInitialization(); replaced with SelfieSegmentation
        }

        setCampaign(campaign);
        putCampaign(campaign).then(() =>
        {
            updateSetting("galleryID", campaign.gallery_id);
            updateSetting("deviceID", campaign.device_code);
            updateSetting("campaign", campaign);

            this.setState({
                initializeState: 3,
                campaign
            });

            return this.downloadCampaignArtwork();
        });
    };

    loadCampaign = () =>
    {
        const { setCurrentCampaignID, initializeCampaign } = this.props;
        const { campaignID, installationID } = this.state;

        setCurrentCampaignID(campaignID);
        updateSetting("campaignID", campaignID).then(() =>
        {
            initializeCampaign(campaignID, venueID, installationID)
                .then((result) =>
                {
                    const { campaign } = result.data.data.output;

                    campaign.segmentation_mode = "selfie"; // = "full_body";

                    if ((campaign.custom_css_url) && (campaign.custom_css_url.length !== 0))
                    {
                        this.loadOuterCSSandCampaign(campaign);
                    }
                    else
                    {
                        this.setCampaign(campaign);
                    }
                })
                .catch((error) =>
                {
                    console.log("[Error]:", error);
                    this.setState({ showAssert: true });
                });
        });
    };

    memberValueFromString = (memberName, objectToAccess) => memberName.replace(/\[(\w+)\]/g, ".$1")
        .split(".").reduce((p, c) => ((p && p[c]) || null), objectToAccess);

    downloadMemberArtwork = async (memberName, keyName, artType, statusText) =>
    {
        const { campaign } = this.state;

        const artworkObj = this.memberValueFromString(memberName, campaign);

        if (artworkObj?.url != null)
        {
            if (statusText != null)
            {
                this.setState({
                    statusText
                });
            }

            if (!isImageOnUrl(artworkObj.url))
            {
                return {
                    data: {
                        key: keyName,
                        data: null,
                        artType: artType,
                        url: artworkObj.url,
                        member: memberName
                    }
                };
            }

            rax.attach();

            return axios({
                method: "get",
                url: artworkObj.url,
                responseType: "arraybuffer",
                crossDomain: true,
                onDownloadProgress: ({ loaded, total }) => Math.round((loaded * 100) / total),
                // This will call the default transformResponse, and then call us. We're going to take the raw
                // data, and save it along with the URL we requested, and the name of the member that was used to trigger us.
                transformResponse: (data, headers) => ({
                    key: keyName,
                    data: Buffer.from(data, "binary"),
                    mimeType: headers["content-type"],
                    artType: artType,
                    url: artworkObj.url,
                    member: memberName
                }),
                raxConfig: {
                    retry: 3
                },
                headers: {
                    "Cache-Control": "no-cache",
                    "x-tagkast-cacheBust2": Math.random()
                }
            });
        }

        return {
            data: {
                key: keyName,
                data: null,
                artType: artType,
                url: null,
                member: memberName
            }
        };
    };

    downloadCampaignArtwork = () =>
    {
        const { campaign } = this.state;
        const { downloadMemberArtwork } = this;
        const { backgrounds } = campaign.green_screen_settings;
        const { backgrounds: videoBackgrounds } = campaign.video_green_screen_settings;
        const { branding } = campaign.photo_settings[0];
        const { facetextures } = campaign;

        const tasks = [
            downloadMemberArtwork("app_background_landscape", "app_background_landscape", "app_background", "Downloading app backgrounds..."),
            downloadMemberArtwork("app_background_portrait", "app_background_portrait", "app_background", ""),
            downloadMemberArtwork("kiosk.background[0].landscape", "kiosk_background_landscape", "kiosk_background", "Downloading kiosk backgrounds..."),
            downloadMemberArtwork("kiosk.background[0].portrait", "kiosk_background_portrait", "kiosk_background", ""),
            downloadMemberArtwork("facetextures", "facetextures", "facetextures", "Downloading face texture...")
        ];

        for (let i = 0; i < backgrounds.length; i++)
        {
            tasks.push(downloadMemberArtwork(`green_screen_settings.backgrounds[${i}].landscape`, `greenscreen_background_landscape_${i}`, "greenscreen", "Downloading green screens..."));
            tasks.push(downloadMemberArtwork(`green_screen_settings.backgrounds[${i}].portrait`, `greenscreen_background_portrait_${i}`, "greenscreen", "Downloading green screens..."));
        }

        for (let i = 0; i < videoBackgrounds.length; i++)
        {
            tasks.push(downloadMemberArtwork(`video_green_screen_settings.backgrounds[${i}].landscape`, `video_greenscreen_background_landscape_${i}`, "videobackgrounds", "Downloading video green screens..."));
            tasks.push(downloadMemberArtwork(`video_green_screen_settings.backgrounds[${i}].portrait`, `video_greenscreen_background_portrait_${i}`, "videobackgrounds", "Downloading video green screens..."));
        }

        for (let i = 0; i < branding.frames.length; i++)
        {
            tasks.push(downloadMemberArtwork(`photo_settings[0].branding.frames[${i}].landscape`, `frame_landscape_${i}`, "frame", "Downloading frames..."));
            tasks.push(downloadMemberArtwork(`photo_settings[0].branding.frames[${i}].portrait`, `frame_portrait_${i}`, "frame", "Downloading frames..."));
        }

        for (let i = 0; i < facetextures?.length; i++)
        {
            const member = `facetextures[${i}].square`;
            const text = "Downloading face textures...";
            tasks.push(downloadMemberArtwork(member, `face_${i}`, "face", text));
        }

        // This chunk goes through the list of tasks above, and calls them one at a time instead of simultaneously,
        // waiting for each one to resolve. The array of results is then returned in the promise.
        return tasks.reduce((promiseChain, currentTask) => promiseChain.then((chainResults) => currentTask.then((currentResult) => [...chainResults, currentResult]
        )
        ), Promise.resolve([])).then((arrayOfResults) =>
        {
            const promiseArray = [];
            arrayOfResults.forEach((_resultObj) =>
            {
                promiseArray.push(updateArtwork(_resultObj.data));
            });

            Promise.all(promiseArray).then((values) =>
            {
                const { showHeader, history } = this.props;

                showHeader();

                const newHistory = (campaign.activation_mode === "kiosk") ? "/KioskStartScreen" : "/HomeScreenActivity";

                history.replace(newHistory);
            });
            // Do something with all results
        }).catch((error) =>
        {
            const { showHeader } = this.props;

            showHeader();
            console.log(`Caught error initializing campaign ${error.toString()}`);
            updateSetting("campaignID", null);
            this.setState({
                statusText: "Error initializing campaign!"
            });
        });
    };

    handleCampaignCodeChange = ({ target: { value } }) => this.setState({ campaignCode: value.trim() });

    render()
    {
        const {
            state: { campaignCode, initializeState, progressValue, hasParameterizedCode,
                statusText, showAssert, showAssertErrorType },
            props: { match },
            verifyCampaign, handleCampaignCodeChange
        } = this;
        const { debug } = match.params;

        const initStateNotDebug = ((hasParameterizedCode || initializeState) && !debug);
        const h3ClassName = !initializeState ? "pb-2 font-weight-normal" : "pb-2 font-weight-normal hidden";

        return (
            <div className="text-center CampaignInitialization">
                {showAssert && (
                    <AssertPopup
                        callback={this.assertCallback}
                        errorType={showAssertErrorType}
                    />
                )}
                <SpinnerMsg
                    showSpinner={!!initStateNotDebug}
                    text="Loading..."
                    showBackground
                />
                <Form
                    className="form-signin"
                    onSubmit={verifyCampaign}
                    style={{ display: initStateNotDebug ? "none" : "block" }}
                >
                    <div style={{ display: hasParameterizedCode ? "none" : "block" }}>
                        <h3 className={h3ClassName}> Enter a campaign code </h3>
                        <div>
                            <Form.Control
                                className="h3 "
                                type="text"
                                placeholder="Campaign code"
                                disabled={!!initializeState}
                                value={campaignCode}
                                onChange={handleCampaignCodeChange}
                            />
                            <Button
                                size="btn-lg btn-primary btn-block"
                                type="button"
                                disabled={!!initializeState}
                                onClick={verifyCampaign}
                            >
                                Check
                            </Button>
                        </div>
                    </div>
                    <div className="pt-md-3">
                        <div className={this.getStatusClass()}>
                            {statusText}
                        </div>
                        <ProgressBar
                            className={!initializeState ? "hidden" : ""}
                            now={progressValue}
                            label={`${progressValue}%`}
                        />
                    </div>
                    <div className="pt-md-3 d-flex flex-column align-items-center">
                        <div className="d-flex flex-column align-items-start">
                            <Form.Check disabled checked={initializeState >= 2} type="checkbox" label="Getting event details" />
                            <Form.Check disabled checked={initializeState >= 3} type="checkbox" label="Loading photo artwork" />
                            <Form.Check disabled checked={initializeState >= 4} type="checkbox" label="Loading app artwork" />
                            <Form.Check disabled checked={initializeState >= 5} type="checkbox" label="Setting photographer" />
                            <Form.Check disabled checked={initializeState >= 6} type="checkbox" label="Saving diagnostic data" />
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

CampaignInitializationView.defaultProps = {
    // history: PropTypes.shape(historyPropTypes).isRequired,
    testFlag: false,
    campaign: PropTypes.object.isRequired,
    verifyCampaignCode: PropTypes.func.isRequired,
    verifyOperator: PropTypes.func.isRequired,
    initializeCampaign: PropTypes.func.isRequired,
    setCampaign: PropTypes.func.isRequired,
    clearState: PropTypes.func.isRequired,
    showHeader: PropTypes.func.isRequired,
    hideHeader: PropTypes.func.isRequired,
    setCurrentCampaignID: PropTypes.func.isRequired,
    setOuterCSS: PropTypes.func.isRequired
};

CampaignInitializationView.propTypes = {
    // history: PropTypes.shape(historyPropTypes),
    testFlag: PropTypes.bool,
    campaign: PropTypes.shape({ root: PropTypes.string }),
    verifyCampaignCode: PropTypes.func,
    verifyOperator: PropTypes.func,
    initializeCampaign: PropTypes.func,
    setCampaign: PropTypes.func,
    clearState: PropTypes.func,
    showHeader: PropTypes.func,
    hideHeader: PropTypes.func,
    setCurrentCampaignID: PropTypes.func,
    setOuterCSS: PropTypes.func
};
