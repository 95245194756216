import { connect } from "react-redux";
import { selectCampaign } from "../../state/selectors";
import TermsAndConditionsView from "./TermsAndConditions";

const mapStateToProps = (state) => ({
    legalNotice: selectCampaign(state)?.app_setting?.legal_notice || null
});

const mapDispatchToProps = () => ({
});

const TermsAndConditions = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsAndConditionsView);

export default TermsAndConditions;
