import { connect } from "react-redux";
import { BtnSwapView } from "./BtnSwap";
import { selectCampaign } from "../../../../state/selectors";

const mapStateToProps = (state) =>
{
    const isCroppedToSquare = selectCampaign(state).crop_to_square;
    const btnSwapType = isCroppedToSquare ? "BtnSwap_Square" : "BtnSwap_Rect";
    const btnSwapClass = `BtnSwap ${btnSwapType}`;

    return ({
        btnSwapClass: btnSwapClass
    });
};

const mapDispatchToProps = () => ({
});

const BtnSwap = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnSwapView);

export { BtnSwap };
