import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Button = ({ text, onClickHandler, btnClass = "Button_Container" }) => (
    <div className={btnClass}>
        <button
            type="submit"
            className="Button_Btn"
            onClick={onClickHandler}
        >
            {text}
        </button>
    </div>
);

Button.defaultProps = {
    text: PropTypes.string.isRequired,
    btnClass: "Button_Container",
    onClickHandler: PropTypes.func.isRequired
};

Button.propTypes = {
    text: PropTypes.string,
    btnClass: PropTypes.string,
    onClickHandler: PropTypes.func
};

export { Button };
