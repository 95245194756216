import { connect } from "react-redux";
import AppView from "./App";
import {
    setImageWorker,
    setTestFlag,
    setUploadProgress,
    setUploadError,
    setImageURL,
    setVideoWorker,
    setVideoURL,
    cleanVideoData
} from "../../state/actions";
import { selectOrientation } from "../../state/selectors";

const mapStateToProps = (state) => ({
    appOrientation: selectOrientation(state)
});

const mapDispatchToProps = (dispatch) => ({
    setImageWorker: (imageWorker) => dispatch(setImageWorker(imageWorker)),
    setVideoWorker: (videoWorker) => dispatch(setVideoWorker(videoWorker)),
    setTestFlag: (testFlag) => dispatch(setTestFlag(testFlag)),
    setUploadProgress: (imageID, current, total) => dispatch(setUploadProgress(imageID, current, total)),
    setUploadError: (imageID, uploadError) => dispatch(setUploadError(imageID, uploadError)),
    setImageURL: (imageID, tagkastId, url, imageUrl) => dispatch(setImageURL(imageID, tagkastId, url, imageUrl)),
    setVideoURL: (videoID, tagkastId, url, videoUrl) => dispatch(setVideoURL(videoID, tagkastId, url, videoUrl)),
    cleanVideoData: (videoID) => dispatch(cleanVideoData(videoID))
});

const App = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppView);

export default App;
