import { connect } from "react-redux";
import { selectCampaign, selectContentType } from "../../../state/selectors";
import { CONTENT_TYPE_STILL } from "../../../assets/constants";
import { CounterView } from "./Counter";

const mapStateToProps = (state) => ({
    isContentPhoto: selectContentType(state) === CONTENT_TYPE_STILL,
    isCaptureBtnHoldType: false, // TODO get from the server
    countdownTime: selectCampaign(state).prerecord_countdown_time || 5000 // TODO get from the server
});

const mapDispatchToProps = () => ({
});

const Counter = connect(
    mapStateToProps,
    mapDispatchToProps
)(CounterView);

export { Counter };
