import { connect } from "react-redux";
import { BtnAgreeView } from "./BtnAgree";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnAgree = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnAgreeView);

export { BtnAgree };
