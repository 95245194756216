import React from "react";
import PropTypes from "prop-types";
import { ERROR_NO_SERVER, ERROR_CORS } from "./errors";
import "./style.css";

const MSG1_NO_SERVER = "Server is not responding";
const MSG2_NO_SERVER = "Please retry later";

const MSG1_CORS = "CORS error";
const MSG2_CORS = "Add access-control header";

const AssertPopup = ({ callback, errorType = ERROR_NO_SERVER }) => (
    <div className="AssertPopup">
        <div className="AssertPopup_Background">
            <span className="AssertPopup_Span">
                {errorType === ERROR_CORS ? MSG1_CORS : MSG1_NO_SERVER}
            </span>
            <span className="AssertPopup_Span">
                {errorType === ERROR_CORS ? MSG2_CORS : MSG2_NO_SERVER}
            </span>
            <button
                type="button"
                className="AssertPopup_Btn"
                onClick={callback}
            >
                OK
            </button>
        </div>
    </div>
);

AssertPopup.defaultProps = {
    callback: PropTypes.func.isRequired
};

AssertPopup.propTypes = {
    callback: PropTypes.func
};

export { AssertPopup };
