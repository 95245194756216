import { connect } from "react-redux";
import { BtnTextView } from "./BtnText";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnText = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnTextView);

export { BtnText };
