/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from "react";
import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import { putSurvey } from "../../db";
import { TextInput } from "./Blocks/TextInput";
import { SelectInput } from "./Blocks/SelectInput";
import { CheckBox } from "./Blocks/CheckBox";
import { QRCode } from "./Blocks/QRCode";
import { Button } from "./Button";
import { Header } from "./Header";
import { Shane } from "../Shane/Shane";
import TermsAndConditions from "../TermsAndConditions";
import TermsConditions from "../TermsConditions";
import TermsPolicy from "../TermsPolicy";
import "./style.css";

const SHOW_INPUT_LABEL = false;

export default class LeadGenActivityView extends Component
{
    lastSurveyId = -1;

    constructor(props)
    {
        super(props);
        this.qrcode = this.generateQuickGuid();
        this.values = null;
        this.storyId = null;
        this.emailID = 0;
        this.state = {
            validationSchema: null,
            showTerms: false,
            showConditions: false,
            showPolicy: false,
            showStory: false
        };
    }

    componentDidMount()
    {
        const { campaign } = this.props;

        if (!campaign)
        {
            return;
        }

        const { questions } = campaign.lead_generation;
        const validationSchema = {};

        questions.map(({ category, question_tag: tag, is_answer_required: isAnswerRequired, id }) =>
        {
            let yupObj = null;
            const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
            const ageRegExp = /^(?:1[01][0-9]|120|1[7-9]|[2-9][0-9])$/;

            if (tag === "story")
            {
                this.storyId = id;
            }

            switch (category)
            {
                case "custom_text":
                case "name":
                    if (tag === "age")
                    {
                        yupObj = Yup.string().matches(ageRegExp, "Please enter a valid age");

                        if (isAnswerRequired)
                        {
                            yupObj = yupObj.required("Please supply your age");
                        }
                        validationSchema[id] = yupObj;
                    }
                    else if (tag !== "team")
                    {
                        yupObj = Yup.string().max(255, "Must be 255 characters or less");
                        if (isAnswerRequired)
                        {
                            yupObj = yupObj.required("Required").min(1, "This field is required!");
                        }
                        validationSchema[id] = yupObj;
                    }
                    break;

                case "email":
                    this.emailID = id;

                    yupObj = Yup.string().max(
                        255, "Must be 255 characters or less").email("Not a valid email address!"
                    );
                    if (isAnswerRequired)
                    {
                        yupObj = yupObj.required("Required").min(1, "This field is required!");
                    }

                    validationSchema[id] = yupObj;
                    break;

                case "telephone":
                    // yupObj = Yup.string().phone("US", "Please enter a valid phone number");
                    yupObj = Yup.string().matches(phoneRegExp, "Please enter a valid phone number");

                    if (isAnswerRequired)
                    {
                        yupObj = yupObj.required("Required");
                    }

                    validationSchema[id] = yupObj;
                    break;

                case "custom_boolean":
                    if (isAnswerRequired)
                    {
                        yupObj = Yup.boolean().oneOf([true], "Required");
                        yupObj = yupObj.required("Required");
                    }
                    else
                    {
                        yupObj = Yup.boolean().oneOf([false, true], "Required");
                    }

                    validationSchema[id] = yupObj;
                    break;

                case "qrcode":
                    // TODO
                    break;

                default:
                    validationSchema[id] = Yup.string();
                    break;
            }

            return id;
        });

        this.setState({ validationSchema: Yup.object(validationSchema) });
    }

    onPreSubmit = (values) =>
    {
        this.setState({ showStory: true });
        this.values = values;
    }

    onSubmit = (story) =>
    {
        const { campaign, setLastSurveyID, imageWorker, history } = this.props;

        const answers = Object.entries(this.values).map((v) => ({ id: parseInt(v[0], 10), answer: v[1] }));
        const email = answers.find(({ id }) => id === this.emailID);
        answers.push({ id: this.storyId, answer: story });

        const newSurvey = {
            campaignID: campaign.id,
            galleryID: campaign.gallery_id,
            created_at: (new Date()).getTime(),
            isUploaded: 0,
            deviceID: campaign.device_code,
            survey: answers
        };

        putSurvey(newSurvey).then((res) =>
        {
            setLastSurveyID(res);

            imageWorker.postMessage({ messageType: "upload_surveys" });
            // videoWorker.postMessage({ messageType: "upload_surveys" }); // TODO
            (campaign.activation_mode === "lead") ?
                history.goBack()
                : history.replace({ pathname: "/TnxPage", state: { survey: newSurvey } });
            // alert("Thanks!");
        });

        localStorage.setItem("email", email.answer.toString());
    }

    renderSelect = ({ id: selectId, question, answers }) => (
        <SelectInput
            label={question}
            name={selectId}
            type="text"
            placeholder={question}
            key={`TagkastQuestion_${selectId}`}
        >
            {
                answers.map(({ id, answer }) => <option key={`TagkastAnswer_${id}`} value={answer}>{ answer }</option>)
            }
        </SelectInput>
    )

    renderTextInput = ({ id, question }) => (
        <TextInput
            label={question}
            name={id}
            type="text"
            placeholder={question}
            key={`TagkastQuestion_${id}`}
            showInputLabel={SHOW_INPUT_LABEL}
        />
    )

    renderBooleanInput = ({ id, question }) => (
        <CheckBox
            label={question}
            name={id}
            type="checkbox"
            placeholder={question}
            key={`TagkastQuestion_${id}`}
            setShowTerms={this.setShowTerms}
            setShowConditions={this.setShowConditions}
            setShowPolicy={this.setShowPolicy}
        />
    )

    generateQuickGuid = () =>
    {
        const code = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        console.debug("[DEBUG] ===== Generated qrCode: [", code, "] ====="); // TODO delete

        return code;
    }

    renderQRCodeInput = ({ id, question }) => (
        <QRCode
            label={question}
            name={id}
            initialValue={this.qrcode || ""}
            key={`TagkastQuestion_${id}`}
        />
    )

    setShowTerms = () => this.setState({ showTerms: true });

    setHideTerms = () => this.setState({ showTerms: false });

    setShowConditions = () => this.setState({ showConditions: true });

    setHideConditions = () => this.setState({ showConditions: false });

    setShowPolicy = () => this.setState({ showPolicy: true });

    setHidePolicy = () => this.setState({ showPolicy: false });

    render()
    {
        const { campaign } = this.props;

        if (!campaign)
        {
            return null;
        }

        const { questions,
            call_to_action_text: surveyBtnText,
            incentive_text: surveyHeaderText
        } = campaign.lead_generation;
        const { validationSchema, showTerms, showConditions, showPolicy, showStory } = this.state;
        const initialV = {};

        questions.map(({ category, id }) =>
        {
            switch (category)
            {
                case "qrcode":
                    initialV[id] = this.qrcode;
                    break;
                case "custom_boolean":
                    initialV[id] = false;
                    break;

                default:
                    initialV[id] = "";
            }

            return {};
        });

        const renderedQuestions = questions.map((question) =>
        {
            const { category } = question;
            let result = null;

            if (category === "custom_dropdown")
            {
                let defaultIndex = 0;

                for (let i = 0; i < question.answers.length; i++)
                {
                    const answer = question.answers[0];

                    if (answer.is_default === true)
                    {
                        defaultIndex = i;
                    }
                }

                result = this.renderSelect(question);
                initialV[question.id] = question.answers[defaultIndex].answer;
            }
            else if (category === "name"
                || category === "email"
                || category === "telephone"
                || category === "custom_text")
            {
                result = this.renderTextInput(question);
            }
            else if (category === "custom_boolean")
            {
                result = this.renderBooleanInput(question);
            }
            else if (category === "qrcode")
            {
                result = this.renderQRCodeInput(question);
            }

            return result;
        });

        const containerClassDefault = "LeadGenActivity_Container";
        const containerClassNoLabel = "LeadGenActivity_Container LeadGenActivity_Container_NoLabel";
        const containerClass = SHOW_INPUT_LABEL ? containerClassDefault : containerClassNoLabel;

        return (
            <div className="LeadGenActivity">
                <Formik
                    initialValues={initialV}
                    validationSchema={validationSchema}
                    onSubmit={(values) => this.onPreSubmit(values)}
                >
                    <Form className="LeadGenActivity_Form">
                        <Header text={surveyHeaderText} />
                        <div className={containerClass}>{renderedQuestions}</div>
                        <Button text={surveyBtnText} onClickHandler={null} />
                    </Form>
                </Formik>
                {showTerms && <TermsAndConditions setHideTerms={this.setHideTerms} />}
                {showConditions && <TermsConditions setHideConditions={this.setHideConditions} />}
                {showPolicy && <TermsPolicy setHidePolicy={this.setHidePolicy} />}
                {showStory && <Shane setStory2Survey={this.onSubmit} />}
            </div>
        );
    }
}

LeadGenActivityView.defaultProps = {
    campaign: null,
    imageWorker: null,
    videoWorker: null,
    setLastSurveyID: PropTypes.func.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

LeadGenActivityView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    setLastSurveyID: PropTypes.func
    // history: PropTypes.shape(historyPropTypes)
};
