import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearCurrentCampaignID, clearCurrentCampaign } from "../../state/actions";
import { selectHideHeader, selectCampaign, selectOuterCSS } from "../../state/selectors";
import HeaderBarView from "./HeaderBar";

const mapDispatchToProps = (dispatch) => ({
    clearCurrentCampaignID: () => dispatch(clearCurrentCampaignID()),
    clearCurrentCampaign: () => dispatch(clearCurrentCampaign())
});

const mapStateToProps = (state) => ({
    hideHeader: selectHideHeader(state),
    campaign: selectCampaign(state),
    outerCSS: selectOuterCSS(state)
});

const HeaderBar = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBarView));

export default HeaderBar;
