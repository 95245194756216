import { connect } from "react-redux";
import { SuccessView } from "./Success";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const Success = connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccessView);

export { Success };
