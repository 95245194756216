import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnRetakeView = ({ onRetake }) => (
    <div className="BtnRetake">
        <button
            type="button"
            className="BtnRetake_Btn"
            onClick={onRetake}
        >
            <div className="BtnRetake_Circle" />
            <span className="BtnRetake_Text">
                Rerecord
            </span>
        </button>
    </div>
);

BtnRetakeView.defaultProps = {
    onRetake: PropTypes.func.isRequired
};

BtnRetakeView.propTypes = {
    onRetake: PropTypes.func
};

export { BtnRetakeView };
