import { connect } from "react-redux";
import { selectCampaign } from "../../../state/selectors";
import { TermsView } from "./Terms";

const mapStateToProps = (state) => ({
    legalNotice: selectCampaign(state).app_setting.legal_notice
});

const mapDispatchToProps = () => ({
});

const Terms = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsView);

export { Terms };
