import { connect } from "react-redux";
import { selectCampaign } from "../../../state/selectors";
import { setArtworkFace } from "../../../state/actions";
import FaceView from "./Face";

const mapStateToProps = (state) =>
{
    const campaign = selectCampaign(state);
    const listLengthFace = campaign?.facetextures?.length;

    return ({
        listLengthFace: listLengthFace,
        enableFaceMesh: selectCampaign(state).is_facetexture_enabled
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkFace: (url, index) => dispatch(setArtworkFace(url, index))
});
const Face = connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceView);

export default Face;
