import React, { useEffect, useRef, useState } from "react";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../db";
import { FrameChooser } from "../../CameraActivity/FrameChooser/FrameChooser";
import { Header } from "../Header/Header";
import { SVG_BTN } from "../ContentType/svg";
import { isImageOnUrl } from "../../../assets/utils";
import "../style.css";

const NEXT_HISTORY = "/Face";

export default function FrameView({ orientation, setArtworkFrame, listLengthFrame, history })
{
    const [listFrame, setListFrame] = useState([]);
    const [indexFrame, setIndexFrame] = useState(-1);
    const [isFrameSelected, setIsFrameSelected] = useState(false);
    const refImg = useRef(null);

    useEffect(() =>
    {
        if (indexFrame >= 0)
        {
            getArtwork(`frame_${orientation}_${indexFrame}`).then((result) =>
            {
                const isFrameDataImage = isImageOnUrl(result.url);
                let newUrl = result.url;

                if (isFrameDataImage && (result?.data != null))
                {
                    newUrl = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                }

                setArtworkFrame(newUrl, indexFrame);
            });
        }
    }, [indexFrame, orientation, setArtworkFrame]);

    if (listLengthFrame)
    {
        if (listFrame.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("frame").each((art) =>
            {
                if (art.member.endsWith(orientation))
                {
                    artworkList.push({
                        original: art.url,
                        thumbnail: art.url
                    });
                }
            }).then(() =>
            {
                artworkList?.length && setListFrame(artworkList);

                if (artworkList?.length === 1)
                {
                    setIndexFrame(0);
                    history.push(NEXT_HISTORY);
                }
            });
        }

        if (listLengthFrame > 1)
        {
            return (
                <div role="main" className="ScreenSelect_Container">
                    <Header />
                    <span className="ScreenSelect_Header">Select your Frame!</span>

                    <div className="ScreenSelect_Description_Block">
                        <span className="ScreenSelect_Description_Text">
                            Your experience will replace the Frame in your photo.
                            Choose the Frame you would like.
                            You can update the Frame once the camera starts by pressing the &quot;FRAME&quot; button.
                        </span>
                        <span className="ScreenSelect_HelperText">
                            Click on the image to chose Frame
                        </span>
                        <FrameChooser
                            text=""
                            index={indexFrame >= 0 ? indexFrame : 0}
                            list={listFrame}
                            chooserModal={null}
                            onClick={() =>
                            {
                                const indexFrameNew = refImg.current.getCurrentIndex();

                                if (indexFrameNew !== indexFrame)
                                {
                                    setIndexFrame(indexFrameNew);
                                    setIsFrameSelected(true);
                                }

                                const imageList = document.getElementsByClassName("image-gallery-image");

                                for (let i = 0; i < imageList.length; i++)
                                {
                                    const classes = imageList[i].classList;

                                    (i === indexFrameNew) ? classes.add("active") : classes.remove("active");
                                }
                            }}
                            refVar={refImg}
                            hideButtons
                        />
                        <button
                            type="button"
                            className="ScreenSelect_Btn"
                            onClick={(e) =>
                            {
                                e.preventDefault();
                                history.push(NEXT_HISTORY);
                            }}
                            disabled={!isFrameSelected}
                        >
                            <span className="ScreenSelect_Btn_Text">Next</span>
                            <div className="ScreenSelect_Btn_Circle">{SVG_BTN}</div>
                        </button>
                    </div>
                </div>
            );
        }
    }

    return <div />;
}

FrameView.defaultProps = {
    orientation: PropTypes.string.isRequired,
    listLengthFrame: PropTypes.number.isRequired,
    setArtworkFrame: PropTypes.func.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

FrameView.propTypes = {
    orientation: PropTypes.string,
    listLengthFrame: PropTypes.number,
    setArtworkFrame: PropTypes.func
    // history: PropTypes.shape(historyPropTypes)
};
