import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { BtnAgree } from "./BtnAgree";
import { BtnNotAgree } from "./BtnNotAgree";
import "./style.css";

const TermsView = ({ onAgree, onNotAgree, legalNotice }) =>
{
    const text = parse(legalNotice);

    return (
        <div className="Terms">
            <div className="Terms_Container">
                <div className="Terms_Header">
                    <span className="Terms_Header_Text">?</span>
                </div>
                <span className="Terms_Disclaimer">Disclaimer</span>
                <span className="Terms_Body">{text}</span>
                <div className="Terms_Btns">
                    <BtnAgree onAgree={onAgree} />
                    <BtnNotAgree onNotAgree={onNotAgree} />
                </div>
            </div>
        </div>
    );
};

TermsView.defaultProps = {
    legalNotice: PropTypes.string.isRequired,
    onNotAgree: PropTypes.func.isRequired
};

TermsView.propTypes = {
    legalNotice: PropTypes.string,
    onNotAgree: PropTypes.func
};

export { TermsView };
