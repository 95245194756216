import { connect } from "react-redux";
import TermsConditionsView from "./TermsConditions";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const TermsConditions = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsConditionsView);

export default TermsConditions;
