import React from "react";
import ScrollArea from "react-scrollbar";
import "./style.css";

export const HTMLTerms = () => (
    <ScrollArea
        speed={0.8}
        className="HTMLTerms"
        horizontal={false}
        contentClassName="HTMLTerms_El"
    >
        <div className="HTMLTerms_Header">
            <span>SHANE CO.</span>
            <span>MADE TO SHINE REAL STORIES SUBMISSION</span>
            <span>TERMS AND CONDITIONS</span>
        </div>
        <span className="HTMLTerms_Lvl0">
            Western Stone & Metal Corp., a Colorado corporation doing business as Shane Co.
            <b> (“Shane Co.”), </b>
            and Shane Co. employees, directors, owners, agents, parents, subsidiaries, affiliates, and
            advertising, fulfillment and promotional agencies (collectively the
            <b> “Promotional Parties”) </b>
            thanks you for your interest in submitting your original story about how a piece of jewelry has
            been meaningful to you in one of the following formats: (i) written form or (ii) an audio recording (
            <b>“Story”</b>
            ).
        </span>
        <span className="HTMLTerms_UppCase">
            <b>
                BY SUBMITTING YOUR STORY TO SHANE CO., YOU CONFIRM THAT YOU HAVE READ AND AGREE TO THESE MADE TO SHINE
                REAL STORIES PHOTO SUBMISSION TERMS AND CONDITIONS (THE “TERMS AND CONDITIONS”).
            </b>
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>1. Submission. </b>
            To submit your Story, you must upload your Story to Shane Co.’s website at
            https://www.shaneco.com/made-to-shine.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>2. PERSONAL INFORMATION. </b>
            Personal information collected from submission participants is subject to Shane Co.’s Privacy Policy
            available at http://www.shaneco.com/About/-PrivacyPolicy.htmx. By submitting your Story, you expressly
            agree to the terms of the Privacy Policy. The Privacy Policy discusses how each Shane Co. may use your
            personal information, including without limitation for marketing and promotional correspondence
            from Shane Co.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>3. TERMS GOVERNING GENERATED CONTENT.</b>
        </span>
        <span className="HTMLTerms_UppCase">
            <b>
                DO NOT UPLOAD YOUR STORY IF YOU DO NOT AGREE TO SHANE CO.’S USE OF YOUR CONTENT OR IF YOUR CONTENT
                VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY.
            </b>
        </span>
        <span className="HTMLTerms_UppCase">
            <b>
                SHANE CO. RETAINS THE RIGHT, AT ITS DISCRETION, TO MODIFY THESE MADE TO SHINE REAL STORIES SUBMISSION
                TERMS AND CONDITIONS. YOUR CONTINUED SUBMISSION OF STORIES TO SHANE CO. CONSTITUTES YOUR ACCEPTANCE
                TO ALL MODIFIED MADE TO SHINE REAL STORIES SUBMISSION TERMS AND CONDITIONS.
            </b>
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>a. Grant of License. </b>
            By upload you hereby grant to Shane Co. a perpetual, irrevocable, worldwide, non-exclusive, fully
            transferrable (with the right to sublicense), fully paid-up right and license (i) to reproduce, prepare
            derivative work, distribute, perform publicly, and display publicly the Story submitted (the
            <b> “Content”</b>
            ) for any lawful purpose, (ii) to all rights in the Content, including the right to sublicense the Content
            and to use the Content in any advertising or for other lawful purpose, and (iii)  to use entrant’s name,
            biographical information, testimonial, photo and/or likeness, voice, personal exposition, and/or any other
            information contained in the entrant’s Content or otherwise provided by the entrant in connection with a
            submission (and/or any edited portion thereof) for advertising, publicity, promotional, and other purposes
            in any and all media, now or hereafter devised, throughout the world in perpetuity, without additional
            compensation, notification, or permission, except where prohibited by law. You understand that Shane Co.
            and its designees or sublicensees may use the Content for advertising and other purposes and, upon such
            use, entrant is not entitled to any further compensation. In any event the Content is used, Shane Co. may
            modify the Content to comply with the Federal Trade Commission’s Guides Concerning the Use of Endorsements
            and Testimonials in Advertising, as applicable.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>b. Representations and Warranties. </b>
            You represent and warrant to Shane Co. that (i) your Content does not violate the rights of any third
            party, including any rights in copyright, trademark, patent, trade secret, publicity, or likeness, (ii)
            your Content does not disparage, denigrate, defame, libel, slander, or cast false lights on Shane Co. or
            any third person, (iii) you are at least 18 years of age, (iv) you have not impersonated any person or
            falsely stated or misrepresented your affiliation with a person or entity, (v) your images and information
            contained in your Content do not embody, reflect or reveal any confidential information and that we are
            not obligated to treat them as confidential, (vi) your Content is appropriate for a public forum (e.g.,
            it is defamatory, hateful, harassing, derogatory, obscene, vulgar, sexually explicit, demeaning,
            threatening, abusive, pornographic, racially or religiously biased or otherwise offensive or
            objectionable), (vii) your Content does not contain comments that violate Shane Co.’s policies against
            discrimination, harassment, or retaliation, (viii) your Content does not support illegal activities
            under local, state, federal or country specific law; (ix) your Content does not contain confidential
            or business-sensitive information about Shane Co., its customers, or its vendor partners, including
            any proprietary or company-confidential information, and (x) your Content does not include any information
            that references other websites, addresses, email addresses, contact information or phone numbers.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>c. Indemnification. </b>
            You will defend, indemnify, and hold harmless Shane Co. against any and all claims, liabilities,
            settlements, expenses, and related attorneys’ fees and costs incurred or suffered by Shane Co. arising
            out of or resulting from (i) any claim of a third party arising from the infringement or violation of
            a third party’s rights in the entrant’s Content or (ii) any claim of a third party that the entrant’s
            Content is defamatory, libelous, slanderous, or cast a false light.
        </span>

        <span className="HTMLTerms_Lvl1">
            <b>4. GENERAL CONDITIONS.</b>
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>a. Publicity Release. </b>
            By submitting a Story, you grant Shane Co. and each of its designees and sublicensees the right to use
            the your name, biographical information, testimonial, photo and/or likeness, voice, personal exposition,
            and/or any other information contained in your Content or otherwise provided by in connection with a
            submission of Content (and/or any edited portion thereof) for advertising, publicity, promotional, and
            other purposes in any and all media, now or hereafter devised, throughout the world in perpetuity, without
            additional compensation, notification, or permission, except where prohibited by law.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>b. Force Majeure. </b>
            The Promotional Parties’ failure to comply with these Terms and Conditions due to an act of God, weather
            condition, natural disaster, war, terrorism, communications system or network error or failure,
            cyber-attack or hacking, actions of governmental control, or other events outside the control of the
            Promotional Parties shall not be considered a breach of these Terms and Conditions.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>c. Consent to Commercial Emails. </b>
            You agree to receive commercial emails from Shane Co., which you may opt-out
            of by following the instructions in the commercial emails.
        </span>

        <span className="HTMLTerms_Lvl1">
            <b>5. LIMITATION OF LIABILITY.</b>
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>a. Release. </b>
            By submission of your Story, you agree for yourself and for your heirs, executors, and administrators
            (i) to release, defend, indemnify, and hold harmless Shane Co. from any and all costs, injuries, losses,
            or damages of any kind, including, without limitation, death and bodily injury, due in whole or in party,
            directly or indirectly,  from your submission of Content, or from otherwise participating in the Made To
            Shine Real Stories Submission program; (ii) to comply with all Shane Co.’s policies, terms, and conditions
            relating to the use of Shane Co.’s websites; and (iii) to be bound by these Terms and Conditions and to
            waive any right to claim any ambiguity or error therein. This release and hold harmless obligation shall
            survive any expiration or termination of the  Made To Shine Real Stories Submission program.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>b. Limitation of Liability. </b>
            SHANE CO.’S LIABILITY ARISING IN CONNECTION WITH THE MADE TO SHINE REAL STORIES SUBMISSION PROGRAM OR
            THE TERMS AND CONDITIONS WHETHER IN CONTRACT, IN TORT, UNDER ANY WARRANTY, IN NEGLIGENCE, OR
            OTHERWISE SHALL NOT EXCEED THE AMOUNT OF $100.00, AND SHALL BE FURTHER LIMITED TO ACTUAL DAMAGES
            EQUAL TO OR LESS THAN SUCH AMOUNTS, RESPECTIVELY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE
            PROMOTIONAL PARTIES SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, PUNITIVE, OR
            EXEMPLARY DAMAGES, OR FOR DAMAGES FOR LOST PROFITS, OR FOR ATTORNEYS’ FEES OR OTHER SUCH RELATED
            COSTS OF BRINGING A CLAIM. ENTRANT IS NOT ENTITLED TO RESCIND THIS AGREEMENT OR SEEK INJUNCTIVE
            OR OTHER EQUITABLE RELIEF.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>c. California Release. </b>
            Each entrant agrees, to the extent such laws give the entrant rights, that all rights under Section
            1542 of the California Civil Code and similar law of any state or territory of the United States are
            hereby expressly waived. Said section reads as follows:
        </span>
        <span className="HTMLTerms_Doc">
            42. CERTAIN CLAIMS NOT AFFECTED BY GENERAL RELEASES. A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH
            THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH
            IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>6. ADJUDICATION OF CLAIMS.</b>
        </span>
        <span className="HTMLTerms_UppCase">
            <b>
                DO NOT SUBMIT STORIES TO US IF YOU DO NOT AGREE TO HAVE ANY CLAIM OR CONTROVERSY FINALLY DECIDED
                IN ACCORDANCE WITH THESE TERMS AND CONDITIONS.
            </b>
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>a. Binding Arbitration. </b>
            Any claim, dispute, or controversy arising under or related to these Terms and Conditions or the Made
            To Shine Real Stories Submission program shall be resolved first by means of direct discussions with
            either Shane Co. If not resolved pursuant to such discussions, any claim, dispute, or controversy shall
            then be resolved by final and binding arbitration administered by Judicial Arbiter Group, Inc. (“
            <b>JAG</b>
            ”), in accordance with the AAA Commercial Arbitration Rules or other rules agreed to by the parties.
            All proceedings brought pursuant to this agreement will be conducted in the County of Denver, State
            of Colorado. Any claim, dispute, or controversy arising out of or related to these Terms and Conditions
            or the Made To Shine Real Stories Submission program must be submitted to JAG within three months from
            the day upon which a Story was submitted; otherwise, such claim is permanently barred.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>b. Litigation.</b>
        </span>
        <span className="HTMLTerms_Lvl3">
            <b>i. WAIVER OF JURY TRIAL. </b>
            IF THE BINDING ARBITRATION PROVISION ABOVE IS DETERMINED TO BE NULL AND VOID OR IF ANY CLAIM OR
            CONTROVERSY MUST BE LITIGATED FOR ANY REASON, ENTRANT HEREBY WAIVES ANY RIGHT IT MAY HAVE TO A
            JURY TRIAL IN ANY ACTION, SUIT, OR PROCEEDING ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
            AND CONDITIONS OR MADE TO SHINE REAL STORY SUBMISSION PROGRAM.

        </span>
        <span className="HTMLTerms_Lvl3">
            <b>ii. Venue. </b>
            If you or any Promotional Party is entitled to bring any claim, dispute, or controversy in court, any
            such action shall only be brought in a state or federal court located in Denver, Colorado. You hereby
            submit to the jurisdiction and venue of such courts and waives any objection to which the entrant
            otherwise might be entitled regarding such jurisdiction or venue.
        </span>
        <span className="HTMLTerms_Lvl2">
            <b>c. CLASS ACTION WAIVER. </b>
            ANY ARBITRATION, ACTION, SUIT, OR PROCEEDING ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND
            CONDITIONS OR THE MADE TO SHINE REAL STORY SUBMISSION PROGRAM SHALL BE IN EACH ENTRANT’S INDIVIDUAL
            CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            NO ENTRANT SHALL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, OR IN ANY
            PROCEEDING IN WHICH AN ENTRANT OR SHANE CO. ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. THE
            ARBITER OR JUDGE MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY
            SITUATED WITHOUT THE WRITTEN CONSENT OF EACH ENTRANT, SHANE CO., AND ALL PARTIES TO ANY SUCH PROCEEDING.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>7. TERMINATION. </b>
            Shane Co. may terminate your right to submit your Story at any time without notice. However, such
            termination shall only apply to your right to submit your Story and shall not in any other way
            terminate or limit any other provision of these Terms and Conditions.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>8. GOVERNING LAW. </b>
            These Terms and Conditions and the Made To Shine Real Stories Submission program are governed by U.S.
            laws and are subject to all applicable federal, state, and local laws and regulations. All issues and
            questions concerning the construction, validity, interpretation, and enforceability of these Terms and
            Conditions, or the rights and obligations of entrant and Shane Co. in connection with the Made to Shine
            Real Story Submission program, shall be governed by, and construed in accordance with, the laws of the
            State of Colorado, without giving effect to the conflict of laws rules thereof.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>9. SEVERABILITY. </b>
            If any provision(s) of these Terms and Conditions are held to be invalid or unenforceable, all
            remaining provisions hereof will remain in full force and effect. Shane Co. may, in its discretion,
            replace the invalidated or unenforceable provisions with valid and enforceable provisions that,
            in Shane Co.’s discretion, fulfill the intent of these Terms and Conditions and the Made
            to Shine Real Story Submission program.
        </span>
    </ScrollArea>
);
