import React, { useEffect } from "react";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import TypeBox from "./TypeBox/TypeBox";
import { Header } from "../Header/Header";
import { CONTENT_TYPE_GIF, CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO } from "../../../assets/constants";
import "../style.css";

export default function ContentTypeView({ isLeadGenOn, isPhotoEnabled, isGifEnabled, isVideoEnabled,
    history, setContentType })
{
    const processNextPath = (newType) =>
    {
        const nextHistory = isLeadGenOn ? "/LeadGenActivity" : "Background";

        setContentType(newType);
        history.push(nextHistory);
    };

    useEffect(() =>
    {
        const qntOfContentTypes = Number(isPhotoEnabled) + Number(isGifEnabled) + Number(isVideoEnabled);

        if (qntOfContentTypes === 1)
        {
            let newType;

            if (isPhotoEnabled)
            {
                newType = CONTENT_TYPE_STILL;
            }
            else if (isVideoEnabled)
            {
                newType = CONTENT_TYPE_VIDEO;
            }
            else
            {
                newType = CONTENT_TYPE_GIF;
            }

            processNextPath(newType);
        }

        if ((qntOfContentTypes > 1) && isVideoEnabled)
        {
            processNextPath(CONTENT_TYPE_VIDEO);
        }
    }, []);

    return (
        <div role="main" className="ScreenSelect_Container">

            <div className="ScreenSelect_ContentType_Header">
                <Header />
            </div>
            <span className="ScreenSelect_Header">Pick Your Experience</span>
            <div className="ScreenSelect_ContentType_Body">
                <TypeBox
                    name="Still"
                    isEnabled={isPhotoEnabled}
                    onClickCallBack={processNextPath}
                />
                <TypeBox
                    name="GIF"
                    isEnabled={isGifEnabled}
                    onClickCallBack={processNextPath}
                />
                <TypeBox
                    name="Video"
                    isEnabled={isVideoEnabled}
                    onClickCallBack={processNextPath}
                />
            </div>
        </div>
    );
}

ContentTypeView.defaultProps = {
    isLeadGenOn: PropTypes.bool.isRequired,
    isPhotoEnabled: PropTypes.bool.isRequired,
    isGifEnabled: PropTypes.bool.isRequired,
    isVideoEnabled: PropTypes.bool.isRequired,
    // history: PropTypes.shape(historyPropTypes).isRequired,
    setContentType: PropTypes.func.isRequired
};

ContentTypeView.propTypes = {
    isLeadGenOn: PropTypes.bool,
    isPhotoEnabled: PropTypes.bool,
    isGifEnabled: PropTypes.bool,
    isVideoEnabled: PropTypes.bool,
    // history: PropTypes.shape(historyPropTypes),
    setContentType: PropTypes.func
};
