/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Label } from "../Label";
import "./style.css";

const QRCode = ({ label, initialValue, ...props }) =>
{
    const [field] = useField(props);
    const { id, name } = props;

    const QRCodeLabel = label && <Label data={label} id={id || name} />;

    return (
        <div className="QRCode">
            { QRCodeLabel }
            <input className="text-input" type="hidden" readOnly {...field} {...props} />
        </div>
    );
};

QRCode.defaultProps = {
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired
};

QRCode.propTypes = {
    label: PropTypes.string,
    initialValue: PropTypes.string
};

export { QRCode };
