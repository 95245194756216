/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Label } from "../Label";
import { Error, NOERROR } from "../Error";
import "./style.css";

const SelectInput = ({ label, ...props }) =>
{
    const [field, meta] = useField(props);
    const { id, name } = props;

    const textInputLabel = label && <Label data={label} id={id || name} labelClass="SelectInput_Label" />;
    const textInputError = <Error error={meta.error || NOERROR} />;

    return (
        <div className="SelectInput">
            <div className="SelectInput_Container">
                <select className="SelectInput_Select" {...field} {...props} />
                { textInputLabel }
            </div>

            { textInputError }
        </div>
    );
};

SelectInput.defaultProps = {
    label: PropTypes.string.isRequired
};

SelectInput.propTypes = {
    label: PropTypes.string
};

export { SelectInput };
