import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Header = ({ text }) => (
    <div className="Header">
        <span className="Header_Span1">
            {text}
        </span>
        <span className="Header_Span2">
            First tell us a little about you.
        </span>
    </div>
);

Header.defaultProps = {
    text: PropTypes.string.isRequired
};

Header.propTypes = {
    text: PropTypes.string
};

export { Header };
