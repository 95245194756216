import { connect } from "react-redux";
import { BtnTakeAnotherView } from "./BtnTakeAnother";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnTakeAnother = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnTakeAnotherView);

export { BtnTakeAnother };
