import { connect } from "react-redux";
import {
    selectCampaignID,
    selectCampaign,
    selectOrientation,
    selectImageWorker,
    selectVideoWorker
} from "../../state/selectors";
import { setImages, setVideos } from "../../state/actions";
import HomeScreenActivityView from "./HomeScreenActivity";

const mapStateToProps = (state) => ({
    campaignID: selectCampaignID(state),
    campaign: selectCampaign(state),
    orientation: selectOrientation(state),
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state)
});

const mapDispatchToProps = (dispatch) => ({
    setImages: (imagesFromDB) => dispatch(setImages(imagesFromDB)),
    setVideos: (videosFromDB) => dispatch(setVideos(videosFromDB))
});

const HomeScreenActivity = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeScreenActivityView);

export default HomeScreenActivity;
