import { connect } from "react-redux";
import GreenScreenBackgroundView from "./GreenScreenBackground";
import {
    selectArtworkGreenScreenBackground,
    selectCampaign,
    selectContentType,
    selectOrientation
} from "../../../../state/selectors";
import { setArtworkGreenScreenBackground } from "../../../../state/actions";
import { CONTENT_TYPE_STILL } from "../../../../assets/constants";

const mapStateToProps = (state) => 
{    
    const contentType = selectContentType(state);
    const isContentStill = contentType === CONTENT_TYPE_STILL;
    const name = isContentStill ? "" : "video_";

    return ({
        name: name,
        orientation: selectOrientation(state),
        enableGreenScreen: selectCampaign(state).is_green_screen_enabled,
        artWorkGreenScreenBackgroundIndex: selectArtworkGreenScreenBackground(state)?.index || 0
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkGreenScreenBackground: (url, index) => dispatch(setArtworkGreenScreenBackground(url, index))
});

const GreenScreenBackground = connect(
    mapStateToProps,
    mapDispatchToProps
)(GreenScreenBackgroundView);

export default GreenScreenBackground;
