import Dexie from "dexie";

const DB_NAME = "tagkast";
// const dbVersion = 5;
// Dexie.delete(DB_NAME);

const db = new Dexie(DB_NAME);
db.version(1).stores({
    settings: "&key",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded",
    videos: "++id, campaignID, isUploaded"
});
db.version(2).stores({
    settings: "&key",
    artwork: "&key",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded",
    videos: "++id, campaignID, isUploaded"
});
db.version(3).stores({
    settings: "&key",
    artwork: "&key, artType",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded",
    videos: "++id, campaignID, isUploaded"
});
db.version(4).stores({
    settings: "&key",
    artwork: "&key, artType",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded, galleryID, tagkastID",
    videos: "++id, campaignID, isUploaded, galleryID, tagkastID"
});
db.version(5).stores({
    settings: "&key",
    artwork: "&key, artType",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded, galleryID, tagkastID",
    videos: "++id, campaignID, isUploaded, galleryID, tagkastID"
}).upgrade((tx) => tx.table("images").toCollection().modify(async (image) =>
{
    // friend.firstName = friend.name.split(" ")[0];
    // friend.lastName = friend.name.split(" ")[1];
    console.log("Trying to upgrade a table from 4 to 5");
    console.log(`Image is ${image.id}`);
    if (image.image instanceof Blob)
    {
        // eslint-disable-next-line no-param-reassign, no-use-before-define
        image.image = await blobToArrayBufferObject(image.image);
    }
}));

// TODO do we need upgrade 4 to 5 for Videos ?

db.version(6).stores({
    settings: "&key",
    artwork: "&key, artType",
    campaigns: "&id",
    images: "++id, campaignID, isUploaded, galleryID, tagkastID",
    videos: "++id, campaignID, isUploaded, galleryID, tagkastID",
    surveys: "++id, campaignID, isUploaded, galleryID, tagkastID"
});

try
{
    db.open();
}
catch (e)
{
    console.log(`Caught exception:${e.toLocaleString()}`);
    db.open();
}

/* eslint-disable no-return-await */

const getSettings = async () => await db.settings.toArray();

const getSetting = async (key) => await db.settings.get(key);

const deleteSetting = async (key) => await db.settings.delete(key);

const updateSetting = async (key, value) => await db.settings.put({ key: key, value: value });

/* We want to cache our images somewhere, and so we are storing them in indexedDB. Safari won"t let us
store blobs in indexedDB, so we have to convert them to array objects. Array Objects don"t have a mime
type, so we have a new ArrayBuffer object thingie that just stores the mime type, so that we can
convert these back correctly.
 */
const arrayBufferObjectToBlob = (bufferObject, mimeType) => new Blob([bufferObject], { type: mimeType });

const blobToArrayBufferObject = (_blob) => new Promise((resolve, reject) =>
{
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) =>
    {
        const arrayBufObj = {
            array: reader.result,
            mimeType: _blob.type
        };
        resolve(arrayBufObj);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(_blob);
});

const blobToBase64 = (_blob) => new Promise((resolve, reject) =>
{
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) =>
    {
        const arrayBufObj = {
            array: reader.result,
            mimeType: _blob.type
        };
        resolve(arrayBufObj);
    });
    reader.addEventListener("error", reject);
    reader.readAsDataURL(_blob);
});

const getAllArtwork = async () => await db.artwork.toArray();

const getArtwork = async (key) => await db.artwork.get(key);

const getArtworkByType = async (artType, fnc) => await db.artwork.where("artType").equalsIgnoreCase(artType).each(fnc);

const deleteArtwork = async (key) => await db.artwork.delete(key);

const updateArtwork = async (artwork) => await db.artwork.put(artwork);

const getCampaigns = async () => await db.campaigns.toArray();

const getCampaign = async (campaignID) => await db.campaigns.get(campaignID);

const deleteCampaign = async (campaignID) => await db.campaigns.delete(campaignID);

const updateCampaign = async (campaignID, campaign) => await db.campaigns.update(campaignID, campaign);

const putCampaign = async (campaign) => await db.campaigns.put(campaign);

const getImages = async () => await db.images.toArray();

const getCampaignImages = async (campaignID) => await db.images.where({ campaignID: campaignID }).toArray();

const getPendingImages = async () => await db.images.where({ isUploaded: 0 }).toArray();

const getPendingSurveys = async () => await db.surveys.where({ isUploaded: 0 }).toArray();

const updateSurvey = async (id, changes) => await db.surveys.update(id, changes);

const putSurvey = async (survey) => await db.surveys.put(survey);

const getSurvey = async (surveyId) => await db.surveys.get(surveyId);

const deleteImage = async (imageID) => await db.images.delete(imageID);

const updateImage = async (id, changes) => await db.images.update(id, changes);

const putImage = async (image) => await db.images.put(image);

const getImage = async (imageID) => await db.images.get(imageID);

const getVideos = async () => await db.videos.toArray();

const getCampaignVideos = async (campaignID) => await db.videos.where({ campaignID: campaignID }).toArray();

const getPendingVideos = async () => await db.videos.where({ isUploaded: 0 }).toArray();

const deleteVideo = async (videoID) => await db.videos.delete(videoID);

const updateVideo = async (id, changes) => await db.videos.update(id, changes);

const putVideo = async (video) => await db.videos.put(video);

const getVideo = async (videoID) => await db.videos.get(videoID);

export {
    arrayBufferObjectToBlob,
    blobToArrayBufferObject,
    blobToBase64,
    getAllArtwork,
    getArtworkByType,
    getArtwork,
    deleteArtwork,
    updateArtwork,
    getSettings,
    getSetting,
    deleteSetting,
    updateSetting,
    getCampaigns,
    getCampaign,
    deleteCampaign,
    updateCampaign,
    putCampaign,
    getImages,
    getCampaignImages,
    deleteImage,
    updateImage,
    putImage,
    getImage,
    getPendingImages,
    getSurvey,
    putSurvey,
    updateSurvey,
    getPendingSurveys,
    getVideos,
    getCampaignVideos,
    getPendingVideos,
    deleteVideo,
    updateVideo,
    putVideo,
    getVideo,
    db
};
