import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnShareView = ({ valueIsValid, data, type, onShare }) => (
    <div className="BtnShare">
        <button
            type="button"
            className="BtnShare_Btn"
            onClick={() => onShare(data)}
            disabled={!valueIsValid}
        >
            <span className="BtnShare_Text">
                {`Share to ${type}`}
            </span>
        </button>
    </div>
);

BtnShareView.defaultProps = {
    valueIsValid: PropTypes.bool.isRequired,
    data: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onShare: PropTypes.func.isRequired
};

BtnShareView.propTypes = {
    valueIsValid: PropTypes.bool,
    data: PropTypes.string,
    type: PropTypes.string,
    onShare: PropTypes.func
};

export { BtnShareView };
