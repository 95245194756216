import React from "react";
import PropTypes from "prop-types";
import { Button } from "../LeadGenActivity/Button";
import { HTMLConditions } from "./HTMLConditions/index";

export default function TermsConditionsView({ setHideConditions })
{
    return (
        <div className="TermsAndConditions">
            <HTMLConditions />
            <Button text="OK" onClickHandler={setHideConditions} btnClass="HTMLTerms_Btn" />
        </div>
    );
}

TermsConditionsView.defaultProps = {
    setHideConditions: PropTypes.func.isRequired
};

TermsConditionsView.propTypes = {
    setHideConditions: PropTypes.func
};
