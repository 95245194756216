import * as THREE from "three";

const calculateCentroid = (pos) => pos.reduce(
    (a, b) => [a[0] + b[0], a[1] + b[1], a[2] + b[2]]).map((e) => e / pos.length);

const calculateRelative = (pos) =>
{
    const centroid = calculateCentroid(pos);

    return pos.map((e) => new THREE.Vector3(e[0] - centroid[0], e[1] - centroid[1], e[2] - centroid[2]));
};

const calculateAvSize = (ref) => ref.reduce((a, b) => (a + b.length()), 0) / ref.length;

const calculateRotationMatrix = (pos) =>
{
    const upVectorRef = pos[0];
    upVectorRef.normalize();
    const rightVectorRef = pos[1];
    rightVectorRef.normalize();
    const forwardVectorRef = (new THREE.Vector3()).crossVectors(upVectorRef, rightVectorRef);
    forwardVectorRef.normalize();
    rightVectorRef.crossVectors(upVectorRef, forwardVectorRef);
    rightVectorRef.normalize();
    const refRotationMatrix = new THREE.Matrix4();
    refRotationMatrix.set(
        rightVectorRef.x, rightVectorRef.y, rightVectorRef.z, 0,
        upVectorRef.x, upVectorRef.y, upVectorRef.z, 0,
        forwardVectorRef.x, forwardVectorRef.y, forwardVectorRef.z, 0,
        0, 0, 0, 1
    );

    return refRotationMatrix;
};

const convertArrayToUint8Array = (threshold, array) =>
{
    const uint8Array = new Uint8Array(144 * 256).fill(0);

    for (let i = 0; i < 144 * 256; i++)
    {
        const x = Math.floor(i / 256);
        const y = i % 256;
        uint8Array[i] = (array[x][y] > threshold) ? 1 : 0;
    }

    return uint8Array;
};

const convertArrayToUint8Array256 = (array, threshold) =>
{
    const uint8Array = new Uint8Array(256 * 256).fill(0);

    for (let i = 0; i < 256 * 256; i++)
    {
        uint8Array[i] = (array[i * 4 + 3] > (threshold * 255)) ? 1 : 0;
    }

    return uint8Array;
};

const getScaleToCanvas = (canvasSize, resolution) => Math.max(
    canvasSize[0] / resolution[0], canvasSize[1] / resolution[1]);

const calculateOffsets = (scaledSize, size) => [
    (scaledSize[0] - size[0]) / scaledSize[0], (scaledSize[1] - size[1]) / scaledSize[1]];

const calculateScaledCameraSize = (res, scaleToCanvas) => [res[0] * scaleToCanvas, res[1] * scaleToCanvas];

const calculateBackUV = (e) => [
    e[0] / 2, e[1] / 2, 1 - e[0] / 2, e[1] / 2, e[0] / 2, 1 - e[1] / 2, 1 - e[0] / 2, 1 - e[1] / 2];

const calculateFrontUV = (e) => [
    1 - e[0] / 2, e[1] / 2, e[0] / 2, e[1] / 2, 1 - e[0] / 2, 1 - e[1] / 2, e[0] / 2, 1 - e[1] / 2];

const calculateFaceMesh = (scaledSize, size) => [
    -(1.0 + (scaledSize[0] - size[0]) / size[0]), 1.0 + (scaledSize[1] - size[1]) / size[1]];

const calculateCanvasSize = (texture, canvasContainerRef, isCroppedToSquare) =>
{
    const { current } = canvasContainerRef;
    const frameSize = [texture.width, texture.height];
    const canvasSize = [current.clientWidth, current.clientHeight];
    const frameScaleToCanvas = canvasSize[0] / frameSize[0];

    // make preview square
    if (isCroppedToSquare)
    {
        const min = Math.min(frameSize[0], frameSize[1]);

        return [min * frameScaleToCanvas, min * frameScaleToCanvas];
    }

    return [frameSize[0] * frameScaleToCanvas, frameSize[1] * frameScaleToCanvas];
};

const calculateBackgroundOffset = (backgroundRef, canvasRef, newCanvasSize = null) =>
{
    const { current: background } = backgroundRef;
    const { current: canvas } = canvasRef;

    const backgroundRes = [background.width || background.videoWidth, background.height || background.videoHeight];
    const canvasSize = newCanvasSize || [canvas.width, canvas.height];

    const bgScaleToCanvas = getScaleToCanvas(canvasSize, backgroundRes);
    const scaledBgSize = calculateScaledCameraSize(backgroundRes, bgScaleToCanvas);
    const offsets = calculateOffsets(scaledBgSize, canvasSize);

    return calculateBackUV(offsets);
};

const calculateFaceMeshScaleFromVideo = (videoRef, canvasRef, newCanvasSize = null) =>
{
    const { current: video } = videoRef;
    const { current: canvas } = canvasRef;

    if (!video && !canvas)
    {
        return;
    }

    const cameraRes = [video.videoWidth, video.videoHeight];
    const canvasSize = newCanvasSize || [canvas.width, canvas.height];
    const cameraScaleToCanvas = getScaleToCanvas(canvasSize, cameraRes);
    const scaledCameraSize = calculateScaledCameraSize(cameraRes, cameraScaleToCanvas);

    return calculateFaceMesh(scaledCameraSize, canvasSize);
};

const calculateFaceMeshScaleFromCanvas = (inputRef, canvasRef, newCanvasSize = null) =>
{
    const { current: src } = inputRef;
    const { current: canvas } = canvasRef;

    if (!src && !canvas)
    {
        return;
    }

    const cameraRes = [src.width, src.height];
    const canvasSize = newCanvasSize || [canvas.width, canvas.height];
    const cameraScaleToCanvas = getScaleToCanvas(canvasSize, cameraRes);
    const scaledCameraSize = calculateScaledCameraSize(cameraRes, cameraScaleToCanvas);

    return calculateFaceMesh(scaledCameraSize, canvasSize);
};

const calculateFaceMeshScaleFromElement = (canvasElement, canvasRef, newCanvasSize = null) =>
{
    const { current: canvas } = canvasRef;

    if (!canvasElement && !canvas)
    {
        return;
    }

    const cameraRes = [canvasElement.width, canvasElement.height];
    const canvasSize = newCanvasSize || [canvas.width, canvas.height];

    const cameraScaleToCanvas = getScaleToCanvas(canvasSize, cameraRes);
    const scaledCameraSize = calculateScaledCameraSize(cameraRes, cameraScaleToCanvas);

    return calculateFaceMesh(scaledCameraSize, canvasSize);
};

const calculateOffscreenCanvasSize = (testFlag, videoRef, jpgCanvasRef, isRealtime = true) =>
{
    const { current: video } = videoRef;
    const { current: jpg } = jpgCanvasRef;

    const cameraRes = testFlag ? [jpg.width, jpg.height] : [video.videoWidth, video.videoHeight];
    const newFrameSize = isRealtime ? [640, 360] : [1280, 720];

    if (cameraRes[0] < cameraRes[1])
    {
        newFrameSize[0] = isRealtime ? 360 : 720;
        newFrameSize[1] = isRealtime ? 640 : 1280;
    }

    return newFrameSize;
};

const calculateRealCanvasSize = (videoRef) =>
{
    const { current: video } = videoRef;

    return [video.videoWidth, video.videoHeight];
};

const calculateCameraOffset = (
    testFlag, facingMode, frontFacingPreviewFlipped, videoRef, canvasRef, jpgCanvasRef,
    newCanvasSize = null, forceEnvironmentMode = false) =>
{
    const { current: video } = videoRef;
    const { current: canvas } = canvasRef;
    const { current: jpg } = jpgCanvasRef;

    const cameraRes = testFlag ? [jpg.width, jpg.height] : [video.videoWidth, video.videoHeight];
    const canvasSize = newCanvasSize || [canvas.width, canvas.height];

    const cameraScaleToCanvas = getScaleToCanvas(canvasSize, cameraRes);
    const scaledCameraSize = calculateScaledCameraSize(cameraRes, cameraScaleToCanvas);
    const offsets = calculateOffsets(scaledCameraSize, canvasSize);
    const frontUV = calculateFrontUV(offsets);
    const backUV = calculateBackUV(offsets);

    return (facingMode === "user" && !forceEnvironmentMode && frontFacingPreviewFlipped) ? frontUV : backUV;
};

const calculateCanvasScale = (tex3) =>
{
    const cameraCanvasScaleX = 1 / (tex3[0] - tex3[2]);
    const cameraCanvasScaleY = 1 / (tex3[5] - tex3[1]);

    return [cameraCanvasScaleX, cameraCanvasScaleY];
};

const calculateCanvasScaleRefreshOnOrientChange = (oldCameraCanvasScaleX, oldCameraCanvasScaleY,
    testFlag, facingMode, frontFacingPreviewFlipped, videoRef, canvasRef, jpgCanvasRef) =>
{
    const tex3 = calculateCameraOffset(
        testFlag, facingMode, frontFacingPreviewFlipped, videoRef, canvasRef, jpgCanvasRef);

    const newCameraCanvasScaleX = 1 / (tex3[0] - tex3[2]);
    const newCameraCanvasScaleY = 1 / (tex3[5] - tex3[1]);

    if (
        (Math.abs(oldCameraCanvasScaleX - newCameraCanvasScaleX) > 0.1) &&
        (Math.abs(oldCameraCanvasScaleY - newCameraCanvasScaleY) > 0.1))
    {
        window.location.reload(); // TODO reload needed for updating incoming props from CameraPreview
    }
};

export {
    calculateCentroid,
    calculateRelative,
    calculateAvSize,
    calculateRotationMatrix,
    convertArrayToUint8Array,
    convertArrayToUint8Array256,
    calculateCanvasSize,
    calculateBackgroundOffset,
    calculateFaceMeshScaleFromVideo,
    calculateFaceMeshScaleFromCanvas,
    calculateFaceMeshScaleFromElement,
    calculateOffscreenCanvasSize,
    calculateRealCanvasSize,
    calculateCameraOffset,
    calculateCanvasScale,
    calculateCanvasScaleRefreshOnOrientChange
};
