/*
This is an attempt o document the data flow for the camera preview class, which can be rather complicated.

There are several layers to the compositing in the PWA, and I'm going to discuss them here before I go into
details on how the data flow actually works.

Frame Layer: There is a frame layer, this layer is the topmost layer, and is drawn on top of all the other layers.
The frame is what it sounds it sounds like, it is a frame that runs around the edges of the screen. That means that
the frame is what is used to determine the actual aspect ratio used for the final photos, since we can't have camera
sticking out past the sides of the frame

Camera Layer: This is the direct video feed coming from the camera, or in the case of a user uploaded picture, it
is a jpg. The camera layer is whatever we determine is the camera, so this can either be a still picture (for testing
or user upload) or it can be a video stream.

Background Layer: This is the layer that contains the background image or video if we want to do background removal. It will
contain the background we want to draw (as opposed to the removed background from the camera image)

Alpha Layer: This is the layer that contains the alpha mask that will be used to determine which areas of the foreground
and which areas of the background layers are drawn.

Data flow overview:
There are several different pieces of data, and they all update asynchronously. The camera is updating at roughly 30fps,
and the camera will render directly into the WebGL texture that is our camera texture. That means this image is constantly
being updated, and since we can't control the camera frame rate directly, it happens independently of any processing we
are doing in our own service. When we have determined we want to do segmentation, then we have to run the segmentation
process on a camera image. This means that a camera frame is delivered to us and is displayed on screen, and only
after it is delivered are we able to run segmentation. Once the segmentation is complete, we have a new alpha mask that
is used to composite the background. The segmentation runs quite a bit slower than the camera update rate. This means
the alpha mask will always lag behind the camera image somewhat, and if we try and prevent the camera update until
segmentation is complete, then our camera frame rate is limited by our segmentation frame rate. Our segmentation runs
at roughly 15 frames per second, so if we locked the camera to this, we would have both a slow/jerky update of the camera
image, and on top of that, we would introduce latency, which results in a poor experience.

Complications and Caveats:
The camera is opened and displayed through a "video" element, but all of our rendering is occuring on a "canvas" element.
Since we can't draw on the video element, to simplify our compositing, we would like to use the camera image as a texture
and just render it on the canvas, which is what we do. Unfortunately, for browser security reasons, we can't display
video from the camera onto a hidden video element and still get the camera data (on some platforms, notably iOS). This
means we have to keep the video element visible, which we do by drawing it behind the canvas.

There's quite a bit of complicated image axis flipping occuring as well. A camera can be environment facing, or it can
be user facing. If its user facing, users tend to want it as a mirror, which results in backwards text and logos on
people's clothing. This has been issue for some of our customers, so we instead have a preview image we display to
the user, with the horizontal flipping being determined by a combination of which camera is being used and campaign
settings (if they want the flip). When the picture (or video) is actually captured, then mirroring it for user
purposes is no longer important, but people generally want the text and logos in the environment to be readable. If
the preview image is flipped horizontally, then we normally do NOT flip the rendered to image texture, so that text
becomes readable again. Unfortunately, this is not the end of our flipping. Some customers have a green screen background
that they want the user to line up with, and if this is the case, then we DON'T want to flip the rendered image, otherwise
the user will be on the wrong side relative to the background (imagine a background of a team you are posing with, for
example). So we also have a campaign setting to determine whether the rendered image is to be flipped. This means
for straightforward compositing, we have 4 potential combinations for preview & render independently flipped or not flipped.

To complicate things, we sometimes render 2D or 3D props on the face or body of the user. If we draw a hat, and then we
flip the image horizontally, we also have to make sure to scale the X axis of the 3D objects to -1, so that it will
be horizontally flipped. If we have a hat on the user, then this flips the hat to the other side correctly and as
we expect. If there is a texture on the hat, then the texture will also get horizontally flipped, which will result
in backwards logos. This means that we also need to update the texture U value to be 1 - U instead of just U if we
have clamping, or we can repeat the texture and negate the U value, which will also result in a horizontally flipped
texture.

ThreeJS scene setup: There is a single texture that is drawn in 3D, and then any facemesh props are drawn as 3d objects
on top of that. The 4 layers above are rendered into a single texture with a custom shader, which is then placed at a far
away Z value, but rendered without perspective so that it stays full-screen. We then render the 3D objects at some calculated
Z depth that is closer than the flat 2D image we rendered in the back, and this way 3D objects are rendered on top
of the 2D layers.

This results in our final data flow, where images come in from the camera and are updated in close to realtime. Every
frame we receive from the camera we pass to BodyPix, which does the segmentation, and when the segmentation is complete
we render the final output image with whatever thecurrent camera image is (since it happens asynchronously) composited
with the now somewhat behind alpha layer + background + frame.
 */
import React from "react";
import * as THREE from "three";
import { Mesh } from "three";
import PropTypes from "prop-types";
import { StandardShader } from "../Shaders/StandardShader";
import { ProcessingText } from "./ProcessingText/ProcessingText";
import { SelfieSegmentationModel } from "../Models/SelfieSegmentation/SelfieSegmentation";
import { FaceMesh } from "../FaceMesh";
import { Header } from "../ScreenSelect/Header/Header";
import { CLASS_NONE, CONTENT_TYPE_STILL } from "../../assets/constants";
import {
    calculateCanvasSize,
    calculateBackgroundOffset,
    calculateOffscreenCanvasSize,
    calculateCameraOffset,
    calculateRealCanvasSize,
    calculateCanvasScale,
    calculateCanvasScaleRefreshOnOrientChange
} from "./math";
import { isImageOnUrl } from "../../assets/utils";
import "./style.css";
import myTestImage from "../../assets/images/image_webcam.jpeg";
import styles from "./style.module.css";

export default class CameraPreviewView extends React.Component
{
    CAPTURE_OPTIONS_VIDEO = {
        audio: true,
        video: {
            // eslint-disable-next-line react/destructuring-assignment
            facingMode: this.props.facingMode,
            width: { ideal: 1920 },
            height: { ideal: 1080 }
        }
    };

    CAPTURE_OPTIONS_AUDIO =
    {
        mimeType: "video/webm;codecs=vp8,opus",
        audio: {
            deviceId: null
        }
    };

    constructor(props)
    {
        super(props);

        this.state = {
            isCanvasEmpty: true,
            processVideo: false,
            showShaneProgressBar: false
        };
        this.videoRef = React.createRef();
        this.capturedVideoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.captureCanvasRef = React.createRef();
        this.canvasContainerRef = React.createRef();
        this.drawCanvasRef = React.createRef();
        this.frameCanvasRef = React.createRef();
        this.jpgCanvasRef = React.createRef();
        this.blurCanvasRef = React.createRef();
        this.frameRef = React.createRef();
        this.backgroundRef = React.createRef();
        this.videoBackgroundRef = React.createRef();
        this.cameraCanvasScaleX = 1;
        this.cameraCanvasScaleY = 1;
        this.videoStartTime = 0;

        this.disablePreview = false;
        this.faceInstance = null;

        this.mediaStream = null;
        this.audioStream = null;
        // eslint-disable-next-line no-param-reassign
        props.previewRef.current = this;

        if (navigator.mediaDevices?.enumerateDevices())
        {
            navigator.mediaDevices.enumerateDevices().then((mediaDevices) =>
            {
                this.mediaDevices = [];

                mediaDevices.forEach(({ kind, label, deviceId, groupId }) =>
                {
                    if (kind === "videoinput")
                    {
                        console.log(`Got video device ${kind} ${label}`);
                    }
                    if (kind === "audioinput" && deviceId === "default")
                    {
                        console.log(`Got audio device ${kind} ${label}`);

                        this.CAPTURE_OPTIONS_AUDIO.audio.deviceId = groupId;
                    }
                });
            });
        }

        this.blobs = [];
        this.stream = null;
        this.processingText = null;
        this.previewProcessingEnabled = true;
    }

    componentDidMount()
    {
        this.setGreenScreenBackground();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { testFlag, artWorkFrameUrl } = this.props;

        testFlag && this.updateFrame();

        this.updateBackground();

        if (prevProps.artWorkFrameUrl !== artWorkFrameUrl)
        {
            this.updateFrame();
            setTimeout(this.canvasOnResize, 100);
        }

        // const { current: canvasRef } = this.canvasRef;
        // const canvasSize = [canvasRef.width, canvasRef.height];
        // console.log(`Size is now ${canvasSize[0]}:${canvasSize[1]}`);
    }

    componentWillUnmount()
    {
        const { mediaStream } = this;

        if (mediaStream != null)
        {
            mediaStream.getTracks().forEach((track) => track.stop());
        }

        this.setState(() => ({ processVideo: false }));

        this.cleanState();
    }

    cleanState = () =>
    {
        const { canvasOnResize, props } = this;
        window.removeEventListener("resize", canvasOnResize);

        this.faceInstance = null;
        this.mediaStream = null;
        this.audioStream = null;
        props.previewRef.current = null;
        this.mediaDevices = [];
        this.blobs = [];
        this.stream = null;
    };

    getSurveyAnswer = (survey, questionId) =>
    {
        let result = "";
        survey.survey.forEach((answer) =>
        {
            if (answer.id === questionId)
            {
                result = answer.answer;
            }
        });

        return result;
    }

    updateBackground = () =>
    {
        const { enableGreenScreen } = this.props;
        const { backgroundRef, videoBackgroundRef, material, isGreenScreenBackgroundImage } = this;
        const { current: greenScreenBackGroundRef } = this.greenScreenBackGroundRef;

        if ((!backgroundRef && !videoBackgroundRef) || !material || !enableGreenScreen)
        {
            return;
        }

        if (!greenScreenBackGroundRef)
        {
            console.error("ERROR - backgroundRef is NULL!");

            return;
        }

        const threeTexture = isGreenScreenBackgroundImage ?
            new THREE.Texture(greenScreenBackGroundRef) :
            new THREE.VideoTexture(greenScreenBackGroundRef);

        material.uniforms.backgroundTexture.value = threeTexture;
        material.uniforms.backgroundTexture.value.needsUpdate = true;
    }

    updateFrame = async () =>
    {
        const { frameRef, material } = this;
        const { current: frmRef } = frameRef;

        if (!frameRef || !material)
        {
            return;
        }

        if (!frmRef)
        {
            console.error("ERROR - frameRef is null!");

            return;
        }

        material.uniforms.frameTexture.value = new THREE.Texture(frmRef);
        material.uniforms.frameTexture.value.needsUpdate = true;
    }

    drawFrameToCanvas = () =>
    {
        const { current: frameCanvasRef } = this.frameCanvasRef;
        const { current: frameRef } = this.frameRef;
        const { material } = this;
        const { finishFacingModeSwapping } = this.props;

        const ctx = frameCanvasRef.getContext("2d");

        ctx.save();
        ctx.clearRect(0, 0, frameCanvasRef.width, frameCanvasRef.height);
        ctx.drawImage(frameRef, 0, 0, frameCanvasRef.width, frameCanvasRef.height);
        ctx.restore();

        if (frameCanvasRef && material)
        {
            material.uniforms.frameTexture.value = new THREE.CanvasTexture(frameCanvasRef);
            material.uniforms.frameTexture.value.needsUpdate = true;
        }
        // If you want to draw onto the frame or modify it in some other way, here"s the place to do it.

        finishFacingModeSwapping();
    }

    definePictureOrientation = (width, height) =>
    {
        const { setAppOrientation, appOrientation } = this.props;

        if ((appOrientation !== "landscape") && (height < width))
        {
            setAppOrientation("landscape");
        }
        else if ((appOrientation !== "portrait") && (height > width))
        {
            setAppOrientation("portrait");
        }
    }

    setGreenScreenBackground = () =>
    {
        const { artWorkGreenScreenBackgroundUrl, contentType } = this.props;
        const isContentStill = contentType === CONTENT_TYPE_STILL;
        this.isGreenScreenBackgroundImage = isContentStill || isImageOnUrl(artWorkGreenScreenBackgroundUrl);

        /* change image src in case when we have IMAGE in video green screen url */
        if (!isContentStill)
        {
            const image = document.querySelector("img[alt='Background']");
            image.src = artWorkGreenScreenBackgroundUrl;
        }

        this.greenScreenBackGroundRef = this.isGreenScreenBackgroundImage ?
            this.backgroundRef : this.videoBackgroundRef;
    }

    initializeCanvas = () =>
    {
        const { current: videoRef } = this.videoRef;

        if (!videoRef)
        {
            console.error("ERROR - videoRef is NULL!");

            return;
        }

        // this.onplaying
        this.videoTexture = new THREE.VideoTexture(videoRef);

        this.stopAnimateTextures = false;
    }

    initializeBackground = () =>
    {
        const { artWorkGreenScreenBackgroundUrl } = this.props;
        const { current: greenScreenBackGroundRef } = this.greenScreenBackGroundRef;

        if (!greenScreenBackGroundRef)
        {
            console.error("ERROR - backgroundRef is NULL!");

            return;
        }

        const threeTexture = this.isGreenScreenBackgroundImage ?
            new THREE.Texture(greenScreenBackGroundRef) : new THREE.VideoTexture(greenScreenBackGroundRef);

        this.backgroundTexture = artWorkGreenScreenBackgroundUrl ?
            threeTexture : new THREE.DataTexture(new Uint8Array([0, 0, 0]), 1, 1, THREE.RGBFormat);

        const alphaArray = [255];
        this.alphaTexture = new THREE.DataTexture(new Uint8Array(alphaArray), 1, 1, THREE.AlphaFormat);

        // this.blurTextur = new THREE.CanvasTexture();

        this.backgroundTexture.needsUpdate = true;
    }

    initializeFrameCanvas = () =>
    {
        const { current: videoRef } = this.videoRef;
        const { current: frameRef } = this.frameRef;
        const { current: frameCanvasRef } = this.frameCanvasRef;

        if (!frameCanvasRef)
        {
            console.error("ERROR - frameCanvasRef is NULL!");

            return;
        }

        if (frameRef != null)
        {
            this.frameCanvasRef.current.width = frameRef.width;
            this.frameCanvasRef.current.height = frameRef.height;
        }
        else
        {
            this.frameCanvasRef.current.width = videoRef.videoWidth;
            this.frameCanvasRef.current.height = videoRef.videoHeight;
        }

        this.videoRef.current.display = "none";
        this.drawFrameToCanvas();

        this.frameTexture = new THREE.CanvasTexture(frameCanvasRef);
        // this.frameTexture.needsUpdate = true;
    }

    initializeBlurCanvas = () =>
    {
        const { current: blurCanvasRef } = this.blurCanvasRef;

        if (!blurCanvasRef)
        {
            console.error("ERROR - blurCanvasRef is NULL!");

            return;
        }

        this.blurCanvasRef.current.width = 640;
        this.blurCanvasRef.current.height = 360;

        this.blurCanvasTexture = new THREE.CanvasTexture(blurCanvasRef);

        // const { current: videoRef } = this.videoRef;
        // this.videoRef.current.display = "none";
        // this.drawFrameToCanvas();

        // this.dataTexture = this.blurCanvasTexture;
        // this.dataTexture.needsUpdate = true;
    }

    initializeDrawCanvas = () =>
    {
        const { current: frameRef } = this.frameRef;
        const { testFlag, isCroppedToSquare } = this.props;

        const newCanvasSize = calculateCanvasSize(frameRef, this.canvasContainerRef, isCroppedToSquare);
        [this.canvasRef.current.width, this.canvasRef.current.height] = newCanvasSize;
        [this.captureCanvasRef.current.width, this.captureCanvasRef.current.height] = newCanvasSize;

        const drawCanvasSize = calculateOffscreenCanvasSize(testFlag, this.videoRef, this.jpgCanvasRef);

        [this.drawCanvasRef.current.width, this.drawCanvasRef.current.height] = drawCanvasSize;
    }

    initializeScene = () =>
    {
        const { testFlag, facingMode, frontFacingPreviewFlipped } = this.props;
        const { greenScreenBackGroundRef } = this;

        this.geometry = new THREE.BufferGeometry();
        const indices = [3, 2, 1, 0, 1, 2];
        // This is -989 because our max depth is 1000, the camera is 0,0,10, and so if we are at -989,
        // then we are 999 units from the camera and don't clip
        const vertices =
            [(-1) / 1, (-1) / 1, -989, (1) / 1, (-1) / 1, -989, (-1) / 1, (1) / 1, -989, (1) / 1, (1) / 1, -989];
        // const vertices = [(-1) / 1, (-1) / 1, 0, (1) / 1, (-1) / 1, 0, (-1) / 1, (1) / 1, 0, (1) / 1, (1) / 1, 0];
        // const vertices = this.calculateVertices(frameRef);
        const tex1 = [0, 0, 1, 0, 0, 1, 1, 1]; // Everything is clamped to the frame, so this is always 1:1
        const tex2 = calculateBackgroundOffset(greenScreenBackGroundRef, this.canvasRef); // [0, 0, 1, 0, 0, 1, 1, 1];
        const tex3 = calculateCameraOffset(
            testFlag, facingMode, frontFacingPreviewFlipped, this.videoRef, this.canvasRef, this.jpgCanvasRef); // [0, 0, 1, 0, 0, 1, 1, 1];

        [this.cameraCanvasScaleX, this.cameraCanvasScaleY] = calculateCanvasScale(tex3);

        this.geometry.setIndex(indices);
        this.geometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3));
        this.geometry.setAttribute("texcoord1", new THREE.Float32BufferAttribute(tex1, 2));
        this.geometry.setAttribute("texcoord2", new THREE.Float32BufferAttribute(tex2, 2));
        this.geometry.setAttribute("texcoord3", new THREE.Float32BufferAttribute(tex3, 2));

        this.mesh = new Mesh(this.geometry, this.material);
        this.scene.add(this.mesh);

        if (!this.sceneIsInitialized)
        {
            this.scene.add(new THREE.HemisphereLight(0x443333, 0x222233, 4));
            const light = new THREE.AmbientLight(0x404040); // soft white light
            this.scene.add(light);
        }

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            canvas: this.canvasRef.current
        });
        this.renderer.setSize(this.canvasRef.current.width, this.canvasRef.current.height);
        this.capturerenderer = new THREE.WebGLRenderer({
            antialias: true,
            canvas: this.captureCanvasRef.current
        });
        this.capturerenderer.setSize(this.canvasRef.current.width, this.canvasRef.current.height);

        this.scene.add(this.camera);

        this.sceneIsInitialized = true;
    }

    initializeSceneCamera = () =>
    {
        if (this.camera && this.scene)
        {
            return;
        }

        this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.0, 1000);
        this.camera.position.z = 10;
        this.scene = new THREE.Scene();
    }

    initialize3D = () =>
    {
        const { width, height } = this.videoRef.current;

        this.perspectiveCamera = new THREE.PerspectiveCamera(55, width / height, 0.01, 1000);
        this.perspectiveScene = new THREE.Scene();

        // this.perspectiveScene.add(this.perspectiveCamera);
    }

    initializeJpgTexture = () =>
    {
        const { testFlag } = this.props;
        const { current: jpgCanvasRef } = this.jpgCanvasRef;

        if (testFlag)
        {
            this.JpgTexture = new THREE.Texture(jpgCanvasRef);
            this.definePictureOrientation(jpgCanvasRef.width, jpgCanvasRef.height);

            this.videoTexture.needsUpdate = true;
            this.JpgTexture.needsUpdate = true;
        }
    }

    initializeShaderUniforms = () =>
    {
        const { testFlag } = this.props;

        const uniforms = THREE.UniformsUtils.clone(StandardShader.uniforms);
        const dataTexture = this.alphaTexture;
        uniforms.frameTexture.value = this.frameTexture;

        uniforms.cameraTexture.value = testFlag ? this.JpgTexture : this.videoTexture;

        uniforms.dataTexture.value = dataTexture;
        uniforms.backgroundTexture.value = this.backgroundTexture;
        // uniforms.kernel.value = new Float32Array([0.0625, 0.125, 0.0625, 0.125, 0.25, 0.125, 0.0625, 0.125, 0.0625]);
        uniforms.kernel.value = new Float32Array([
            0.003, 0.013, 0.022, 0.013, 0.003,
            0.013, 0.059, 0.097, 0.059, 0.013,
            0.022, 0.097, 0.159, 0.097, 0.022,
            0.013, 0.059, 0.097, 0.059, 0.013,
            0.003, 0.013, 0.022, 0.013, 0.003]);

        this.material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: StandardShader.vertexShader,
            fragmentShader: StandardShader.fragmentShader
        });
    }

    initializeCamera = async () =>
    {
        const { facingMode } = this.props;

        try
        {
            this.CAPTURE_OPTIONS_VIDEO.video.facingMode = facingMode;
            this.mediaStream = await navigator.mediaDevices.getUserMedia(this.CAPTURE_OPTIONS_VIDEO);
        }
        catch (err)
        {
            console.log(err);
            this.mediaStream = null;
        }

        if (!this.mediaStream || !this.videoRef.current)
        {
            return;
        }

        this.videoRef.current.srcObject = this.mediaStream;
        this.videoRef.current.onloadeddata = async () =>
        {
            const { current: videoRef } = this.videoRef;

            this.videoRef.current.width = videoRef.videoWidth;
            this.videoRef.current.height = videoRef.videoHeight;
            this.videoRef.current.onplaying = async () =>
            {
                this.initializeSceneCamera();
                this.initialize3D();
                this.initializeFrameCanvas();
                this.initializeBlurCanvas();
                this.initializeCanvas();
                this.initializeJpgTexture();
                this.initializeBackground();
                this.initializeDrawCanvas();
                this.initializeShaderUniforms();
                this.initializeScene();

                this.canvasOnResize();

                await this.loadBackgroundRemoveAndEvents();
                this.animateTextures();
            };
        };

        await this.initializeMicrophone();
    }

    initializeMicrophone = async () =>
    {
        this.audioStream = await navigator.mediaDevices.getUserMedia(this.CAPTURE_OPTIONS_AUDIO);
    }

    loadBackgroundRemoveAndEvents = async () =>
    {
        this.setState({
            processVideo: true
        }, this.loadBackgroundRemoveCallback);

        window.removeEventListener("resize", this.canvasOnResize);
        window.addEventListener("resize", this.canvasOnResize);
    }

    canvasOnResize = () =>
    {
        const { testFlag, facingMode, frontFacingPreviewFlipped, isCroppedToSquare } = this.props;
        const { current: frameRef } = this.frameRef;
        const { current: canvasRef } = this.canvasRef;
        const { current: captureCanvasRef } = this.captureCanvasRef;
        const { renderer, capturerenderer, geometry, greenScreenBackGroundRef } = this;

        if (!canvasRef || !renderer)
        {
            return;
        }

        console.log("Canvas is being resized!");

        // const vertices = this.calculateVertices(this.frameRef.current);
        // this.geometry.setAttribute("position",  new THREE.Float32BufferAttribute( vertices, 3));

        const newCanvasSize = calculateCanvasSize(frameRef, this.canvasContainerRef, isCroppedToSquare);
        [canvasRef.width, canvasRef.height] = newCanvasSize;
        [captureCanvasRef.width, captureCanvasRef.height] = newCanvasSize;

        // canvasRef.scrollWidth = newCanvasSize[0];
        // canvasRef.scrollHeight = newCanvasSize[1];
        renderer.setSize(canvasRef.width, canvasRef.height);
        capturerenderer.setSize(canvasRef.width, canvasRef.height);

        const cameraOffset = calculateCameraOffset(
            testFlag, facingMode, frontFacingPreviewFlipped, this.videoRef, this.canvasRef, this.jpgCanvasRef,
            newCanvasSize);
        geometry.setAttribute("texcoord3", new THREE.Float32BufferAttribute(cameraOffset, 2));

        const bgOffset = calculateBackgroundOffset(greenScreenBackGroundRef, this.canvasRef, newCanvasSize);

        geometry.setAttribute("texcoord2", new THREE.Float32BufferAttribute(bgOffset, 2));

        calculateCanvasScaleRefreshOnOrientChange(this.cameraCanvasScaleX, this.cameraCanvasScaleY,
            testFlag, facingMode, frontFacingPreviewFlipped, this.videoRef, this.canvasRef, this.jpgCanvasRef);
    }

    animateTextures = () =>
    {
        const { processVideo } = this.state;
        const { isFacingModeSwapping, enableGreenScreen } = this.props;
        const { current: videoRef } = this.videoRef;

        if (!videoRef || !processVideo || this.stopAnimateTextures)
        {
            return;
        }

        !enableGreenScreen && this.onSegment();
        this.beforeSegment();

        !this.stopAnimateTextures && !isFacingModeSwapping && requestAnimationFrame(this.animateTextures);
    }

    beforeSegment = () =>
    {
        const { current: drawCanvasRef } = this.drawCanvasRef;
        const { current: jpgCanvasRef } = this.jpgCanvasRef;
        const { current: videoRef } = this.videoRef;
        const { testFlag } = this.props;
        const { disablePreview } = this;

        // console.log("Got segment data");

        const context = drawCanvasRef?.getContext("2d");
        const canvasImageSource = testFlag ? jpgCanvasRef : videoRef;

        if (!disablePreview)
        {
            if (!testFlag)
            {
                context.drawImage(canvasImageSource, 0, 0, drawCanvasRef.width, drawCanvasRef.height);
            }
            else
            if (jpgCanvasRef)
            {
                context.drawImage(canvasImageSource, 0, 0, drawCanvasRef.width, drawCanvasRef.height);
            }
        }
    }

    saveImageBitmapToFile = (imageBitmap, name) =>
    {
        const createElCanvas = document.createElement("canvas");

        createElCanvas.width = imageBitmap.width;
        createElCanvas.height = imageBitmap.height;
        const canvasCtx = createElCanvas.getContext("2d");
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, createElCanvas.width, createElCanvas.height);

        canvasCtx.drawImage(imageBitmap, 0, 0, createElCanvas.width, createElCanvas.height);
        canvasCtx.restore();

        const canvasUrl = createElCanvas.toDataURL("image/jpeg");
        const createEl = document.createElement("a");

        createEl.href = canvasUrl;
        createEl.download = name;
        createEl.click();
        createEl.remove();

        console.log("Saving", name);
    };

    onSegment = (segmentationMask) =>
    {
        const { enableGreenScreen, testFlag } = this.props;

        if (!this.previewProcessingEnabled)
        {
            return null;
        }

        if (!this.material)
        {
            return;
        }

        if (testFlag && !this.saved)
        {
            this.saveImageBitmapToFile(segmentationMask, "image_webcam_segmentationMask");
            this.saved = true;
        }

        const { uniforms: material } = this.material;
        const { renderer, capturerenderer } = this;

        if (enableGreenScreen && renderer)
        {
            // material.dataTexture.value = new THREE.CanvasTexture(segmentationMask);
            // material.dataTexture.value = new THREE.CanvasTexture(blurCanvasRef);

            /*
            const { current: blurCanvasRef } = this.blurCanvasRef;
            const { current: drawCanvasRef } = this.drawCanvasRef;

            blurCanvasRef.width = drawCanvasRef.width;
            blurCanvasRef.height = drawCanvasRef.height;
            const ctx = blurCanvasRef.getContext("2d");

            ctx.save();
            ctx.clearRect(0, 0, blurCanvasRef.width, blurCanvasRef.height);
            ctx.globalCompositeOperation = "copy";
            ctx.filter = "blur(4px)";
            ctx.drawImage(segmentationMask, 0, 0, blurCanvasRef.width, blurCanvasRef.height);
            ctx.restore();

            material.dataTexture.value = this.blurCanvasTexture;
            this.blurCanvasTexture.needsUpdate = true;
            */
            material.dataTexture.value = new THREE.CanvasTexture(segmentationMask);

            material.width.value = segmentationMask.width;
            material.height.value = segmentationMask.height;
            //
            material.dataTexture.needsUpdate = true;
            material.needsUpdate = true;
            // material.width.value = this.canvasRef.current.width;
            // material.height.value = this.canvasRef.current.height;
            renderer.setRenderTarget(null);
            capturerenderer.setRenderTarget(null);
        }
        else
        {
            material.dataTexture.value = this.alphaTexture;
            material.dataTexture.needsUpdate = true;
        }
    }

    onSegmentSelfieSegmentation = (segmentationMask) =>
    {
        if (!this.material)
        {
            return;
        }

        const { renderer, capturerenderer } = this;

        /*
        const ctx = blurCanvasRef.getContext("2d");
        const { current: blurCanvasRef } = this.blurCanvasRef;
        const { canvasRef  } = this;

        ctx.save();
        ctx.clearRect(0, 0, blurCanvasRef.width, blurCanvasRef.height);
        ctx.drawImage(segmentationMask, 0, 0, blurCanvasRef.width, blurCanvasRef.height);
        ctx.restore();

        const { uniforms: material } = this.material;
        material.dataTexture.value = this.blurCanvasTexture;
        material.dataTexture.needsUpdate = true;
        */
        // material.dataTexture.value = new THREE.CanvasTexture(segmentationMask);
        // material.width.value = canvasRef.current.width;
        // material.height.value = canvasRef.current.height;
        renderer.setRenderTarget(null);
        capturerenderer.setRenderTarget(null);

        this.afterRenderToCanvas();
    }

    loadBackgroundRemoveCallback = () =>
    {
        const { enableFaceMesh, enableGreenScreen } = this.props;

        this.animate();

        if (enableFaceMesh || !enableGreenScreen)
        {
            this.animateTextures();
        }
    }

    animate = () =>
    {
        const { processVideo } = this.state;
        const { scene, camera, animate } = this;

        // console.log("Processing frame");

        if (processVideo)
        {
            try
            {
                this.renderer.render(scene, camera);
            }
            catch (e)
            {
                console.log("Caught animation exception. Ignoring");
            }

            // this.renderer.autoClear = false;
            // this.renderer.render(this.perspectiveScene, this.perspectiveCamera);
            // this.renderer.autoClear = true;

            requestAnimationFrame(animate);
        }
    }

    handleCanPlay = () =>
    {
        const { play } = this.videoRef.current;

        play();
    }

    restartVideo = () =>
    {
        const { isCanvasEmpty } = this.state;
        const { current: canvasRef } = this.canvasRef;
        const { current: captureCanvasRef } = this.captureCanvasRef;
        const { originalWidth, originalHeight, renderer, animate } = this;

        if (isCanvasEmpty)
        {
            return;
        }

        canvasRef.width = originalWidth;
        canvasRef.height = originalHeight;
        captureCanvasRef.width = originalWidth;
        captureCanvasRef.height = originalHeight;
        console.log(`At capture restore it is ${canvasRef.width}:${canvasRef.height}`);
        renderer.setSize(originalWidth, originalHeight);

        this.setState({
            processVideo: true,
            isCanvasEmpty: true
        });
        requestAnimationFrame(animate);
    }

    renderFrontFacingFlipped = (width, height, flag, forceEnvironmentMode = false) =>
    {
        const { facingMode, testFlag } = this.props;
        const { videoRef, canvasRef, jpgCanvasRef, greenScreenBackGroundRef } = this;

        const cameraOffset = calculateCameraOffset(testFlag, facingMode, flag, videoRef, canvasRef, jpgCanvasRef,
            [width, height], forceEnvironmentMode);
        this.geometry.setAttribute("texcoord3", new THREE.Float32BufferAttribute(cameraOffset, 2));

        const bgOffset = calculateBackgroundOffset(greenScreenBackGroundRef, canvasRef, [width, height]);

        this.geometry.setAttribute("texcoord2", new THREE.Float32BufferAttribute(bgOffset, 2));
    };

    renderToCanvas = () =>
    {
        console.info("Rendering to canvas");

        const { current: drawCanvasRef } = this.drawCanvasRef;
        const { current: captureCanvasRef } = this.captureCanvasRef;
        const { enableGreenScreen } = this.props;

        if (captureCanvasRef == null)
        {
            return;
        }

        // this.stopAnimateTextures = true; // WITH THAT CODE THERE IS NO FACE IN THE END

        if (!drawCanvasRef)
        {
            console.error("ERROR - drawCanvasRef is NULL!");

            return;
        }

        this.material.uniforms.cameraTexture.value = new THREE.CanvasTexture(drawCanvasRef);
        this.material.uniforms.cameraTexture.needsUpdate = true;

        // We're going to update the draw canvas so that we can take
        // a picture at higher resolution than the realtime preview
        [drawCanvasRef.width, drawCanvasRef.height] = calculateRealCanvasSize(this.videoRef);

        this.beforeSegment();
        this.disablePreview = true;

        // remove background only if enableGreenScreen is ON.
        if (enableGreenScreen)
        {
            this.onSegmentSelfieSegmentation();
        }
        else
        {
            this.afterRenderToCanvas();
        }
    }

    afterRenderToCanvas = () =>
    {
        const { current: canvasRef } = this.canvasRef;
        const { current: frameRef } = this.frameRef;
        const { onCapture, facingMode, frontFacingPreviewFlipped, frontFacingCameraFlipped,
            isCroppedToSquare } = this.props;
        const { capturerenderer } = this;

        const { current: captureCanvasRef } = this.captureCanvasRef;
        let { originalWidth, originalHeight } = this;

        console.info("Processing final picture after SelfieSegmentation");

        setTimeout(() =>
        {
            const frameMin = Math.min(frameRef.width, frameRef.height);

            originalWidth = canvasRef.width;
            originalHeight = canvasRef.height;

            console.log(`Before capture it is ${canvasRef.width}:${canvasRef.height}`);
            captureCanvasRef.width = isCroppedToSquare ? frameMin : frameRef.width;
            captureCanvasRef.height = isCroppedToSquare ? frameMin : frameRef.height;
            console.log(`At capture it is ${canvasRef.width}:${canvasRef.height}`);
            capturerenderer.setSize(captureCanvasRef.width, captureCanvasRef.height);
            // capturerenderer.setViewport(0, 0, captureCanvasRef.width, captureCanvasRef.height);

            this.renderFrontFacingFlipped(captureCanvasRef.width, captureCanvasRef.height, frontFacingCameraFlipped);

            // We're using the front-facing camera, so we're going to flip the image (and therefore the face texture)
            if (this.faceInstance != null) 
            {
                this.faceInstance.scale.x *= (frontFacingCameraFlipped && facingMode === "user") ? 1.0 : -1.0;
                this.faceInstance.needsUpdate = true;
            }

            this.captureRenderer();

            if (onCapture != null)
            {
                onCapture(this.captureCanvasRef);
            }
            if (this.faceInstance != null)
            {
                this.faceInstance.scale.x = 1.0;
            }

            this.setState({
                processVideo: false,
                isCanvasEmpty: false
            });

            captureCanvasRef.width = originalWidth;
            captureCanvasRef.height = originalHeight;
            capturerenderer.setSize(canvasRef.width, canvasRef.height);

            this.renderFrontFacingFlipped(originalWidth, originalHeight, frontFacingPreviewFlipped, true);
            this.captureRenderer();
        }, 100);

        this.animateTextures();
    };

    captureRenderer = () =>
    {
        const { capturerenderer, scene, camera } = this;

        capturerenderer.render(scene, camera);

        // const { perspectiveScene, perspectiveCamera } = this;
        // capturerenderer.autoClear = false;
        // capturerenderer.render(perspectiveScene, perspectiveCamera);
        // capturerenderer.autoClear = true;
    };

    clearCanvas = () =>
    {
        const { current: canvasRef } = this.canvasRef;
        const { current: videoRef } = this.videoRef;
        const { onClear, mediaStream } = this;

        const context = canvasRef?.getContext("2d");
        if (!context)
        {
            return;
        }
        context.clearRect(0, 0, canvasRef.width, canvasRef.height);
        if (onClear != null)
        {
            onClear();
        }

        this.setState({
            isCanvasEmpty: true
        });
        if (mediaStream && videoRef && !videoRef.srcObject)
        {
            this.videoRef.current.srcObject = mediaStream;
        }
        else
        {
            videoRef.play();
        }
    };

    startRecording = async () =>
    {
        const { setVideoCaptureStarted, videoEndTime } = this.props;

        setVideoCaptureStarted(true);

        this.setState({ showShaneProgressBar: true });

        this.stream = this.canvasRef.current.captureStream();

        // eslint-disable-next-line no-restricted-syntax
        for (const track of this.audioStream.getTracks())
        {
            this.stream.addTrack(track);
            console.log("Stream added audio", this.stream);
        }

        this.mediaRecorder = new MediaRecorder(this.stream);
        this.videoStartTime = Math.round(Date.now() / 1000);
        this.mediaRecorder.ondataavailable = (event) =>
        {
            if (event.data)
            {
                this.blobs.push(event.data);
                const timeSinceStart = (Date.now() / 1000) - this.videoStartTime;
                const coef = videoEndTime / 40;
                const percentage = (timeSinceStart / coef + 1).toFixed(1);
                const el = document.getElementsByClassName("ShaneCircles_Border")[0];

                if (el)
                {
                    el.style.setProperty("--percentageShaneBorder", `${percentage}%`);
                }

                if (this.videoStartTime !== 0 && timeSinceStart > videoEndTime)
                {
                    this.videoStartTime = 0;
                    this.stopRecording();
                }
            }
        };
        this.mediaRecorder.onstop = this.handleVideoOnStop;
        this.mediaRecorder.onerror = this.handleVideoError;
        this.mediaRecorder.start(1000); // Let's receive 1 second blobs
        console.log("MediaRecorder started");
    }

    stopRecording = () =>
    {
        const { setVideoCaptureStarted } = this.props;

        setVideoCaptureStarted(false);
        this.mediaRecorder.stop();
        this.stream.getTracks().forEach((track) => track.stop());
        console.log("MediaRecorder stopped");

        this.processingText = <ProcessingText />;
    }

    handleVideoError = (event) =>
    {
        console.log(`MediaRecorder threw an error of ${JSON.stringify(event)}`);
    }

    handleVideoOnStop = (event) =>
    {
        const { onCaptureVideo } = this.props;
        const { current: canvasRef } = this.canvasRef;

        if (event != null) 
        {
            console.log(JSON.stringify(event));
        }
        if (!this.blobs.length)
        {
            return;
        }

        const videoBlob = new Blob(this.blobs, { type: this.mediaRecorder.mimeType });
        const thumbnailCanvasSize = [canvasRef.width, canvasRef.height];
        onCaptureVideo && onCaptureVideo(videoBlob, thumbnailCanvasSize);
    }

    setFaceInstance = (newFaceInstance) =>
    {
        this.faceInstance = newFaceInstance;
    }

    initFaceInstance = (faceInstance) =>
    {
        const { testFlag } = this.props;

        !testFlag && this.scene.add(faceInstance);
    }

    render()
    {
        const {
            canvasRef,
            captureCanvasRef,
            canvasContainerRef,
            videoRef,
            capturedVideoRef,
            drawCanvasRef,
            frameCanvasRef,
            frameRef,
            blurCanvasRef,
            backgroundRef,
            jpgCanvasRef,
            cameraCanvasScaleX,
            cameraCanvasScaleY,
            previewProcessingEnabled,
            setFaceInstance,
            initFaceInstance,
            videoBackgroundRef
        } = this;
        const {
            artWorkFrameUrl, artWorkGreenScreenBackgroundUrl, jpgName, testFlag, isCroppedToSquare,
            isVideoCaptureStarted, facingMode, enableFaceMesh, enableGreenScreen
        } = this.props;
        const { showShaneProgressBar } = this.state;
        const classNameDisplayContainer = testFlag ? CLASS_NONE : "";
        let currentImg = null;

        if (jpgName)
        {
            // eslint-disable-next-line global-require, import/no-dynamic-require
            // const image = require(`./../../assets/images/${jpgName}`);
            currentImg = (
                <img
                    key={jpgName}
                    alt="myTestImage"
                    className={CLASS_NONE}
                    ref={jpgCanvasRef}
                    // src={image}
                    src={myTestImage}
                />
            );
        }

        const isVideoRecordered = (this.blobs?.length > 1) && !isVideoCaptureStarted;
        const classVideoDisplayCaptured = CLASS_NONE;
        const classVideoDisplay = isVideoRecordered ? CLASS_NONE : "";

        const previewCanvasClass = isCroppedToSquare ?
            styles.CameraPreview_Canvas_Square : styles.CameraPreview_Canvas_Rectangle;
        const classCanvas = `CameraPreview_Canvas ${previewCanvasClass} ${classVideoDisplay}`;

        const videoUrl = ""; // TODO
        const blurStyle = {
            position: "relative",
            "-webkit-filter": "blur(3px)",
            filter: "blur(3px)"
        };

        return (
            <div className="CameraPreview_Container">
                <div
                    ref={canvasContainerRef}
                    className={`CameraPreview_CanvasContainer ${classNameDisplayContainer}`}
                >
                    <Header />
                    { this.processingText }

                    <video
                        className={`CameraPreview_VideoCaptured ${classVideoDisplayCaptured}`}
                        ref={capturedVideoRef}
                        src={videoUrl} // TODO
                        autoPlay
                        playsInline
                        muted
                    />
                    <video
                        className={`CameraPreview_VideoPreview ${classVideoDisplay} `}
                        ref={videoRef}
                        style={{ marginLeft: canvasRef.current?.width / 2 || 0 }}
                        autoPlay
                        playsInline
                        muted
                    />
                    <canvas ref={captureCanvasRef} className={CLASS_NONE} id="captureCanvasRef" />
                    <canvas ref={canvasRef} className={classCanvas} id="canvasRef" />
                    <canvas ref={drawCanvasRef} className={CLASS_NONE} id="drawCanvasRef" />
                    <canvas ref={frameCanvasRef} className={CLASS_NONE} id="frameCanvasRef" />
                    <canvas ref={blurCanvasRef} className={CLASS_NONE} style={{ blurStyle }} />
                    <canvas ref={jpgCanvasRef} className={CLASS_NONE} id="jpgCanvasRef" />
                    { currentImg }
                    <img
                        alt="Frame"
                        className={CLASS_NONE}
                        ref={frameRef}
                        src={artWorkFrameUrl}
                    />
                    <img
                        alt="Background"
                        className={CLASS_NONE}
                        ref={backgroundRef}
                        src={artWorkGreenScreenBackgroundUrl}
                        crossOrigin="anonymous"
                    />
                    <video
                        className="CameraPreview_GreenScreenVideo"
                        ref={videoBackgroundRef}
                        src={artWorkGreenScreenBackgroundUrl}
                        autoPlay
                        playsInline
                        loop
                        muted
                        crossOrigin="anonymous"
                        preload="auto"
                        onLoadedData={() =>
                        {
                            this.canvasOnResize();
                            this.updateBackground();
                        }}
                    />
                    { (previewProcessingEnabled && enableGreenScreen && drawCanvasRef?.current) && (
                        <SelfieSegmentationModel
                            drawCanvasRef={drawCanvasRef}
                            callback={this.onSegment}
                        />
                    )}
                    { (this.sceneIsInitialized && enableFaceMesh) && (
                        <FaceMesh
                            drawCanvasRef={drawCanvasRef}
                            facingMode={facingMode}
                            cameraCanvasScaleX={cameraCanvasScaleX}
                            cameraCanvasScaleY={cameraCanvasScaleY}
                            initFaceInstance={initFaceInstance}
                            setFaceInstance={setFaceInstance}
                        />
                    )}
                </div>

                {(showShaneProgressBar) && (
                    <>
                        <div className="ShaneCircles_BorderShadow" />
                        <div className="ShaneCircles_Border" />
                    </>
                )}
            </div>
        );
    }
}

/*
The canvasRef is what is actually draw on screen. This contains the final compositing.
The capture canvas ref is a copy of our normal canvas, but we have it underneath our regular canvas. This allows us to process
the capture, without changing the contents of the regular canvas.
The frameCanvasRef is a non-visible canvas where we draw the frame, which is then re-blitted during the compositing. It sits within
its own canvas so that we can render text onto it for the baseball card stuff (frame customization).
The drawCanvasRef is also a non-visible canvas. It is used to render the camera at a lower resolution, so that when we do our image segmentation,
it runs faster. It is not drawn outside of that.
The capturedVideo is captured video from canvasRef.
 */

CameraPreviewView.defaultProps = {
    frontFacingPreviewFlipped: PropTypes.bool.isRequired,
    frontFacingCameraFlipped: PropTypes.bool.isRequired,
    testFlag: PropTypes.bool.isRequired,
    enableGreenScreen: PropTypes.bool.isRequired,
    isCroppedToSquare: PropTypes.bool.isRequired,
    facingMode: PropTypes.string.isRequired,
    appOrientation: PropTypes.string.isRequired,
    jpgName: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired
    ]).isRequired,
    videoEndTime: PropTypes.number.isRequired,
    onCapture: PropTypes.func.isRequired,
    setAppOrientation: PropTypes.func.isRequired,
    // processPhotoReady: PropTypes.func.isRequired,
    enableFaceMesh: PropTypes.bool.isRequired,
    isFacingModeSwapping: PropTypes.bool.isRequired,
    finishFacingModeSwapping: PropTypes.func.isRequired,
    setVideoCaptureStarted: PropTypes.func.isRequired,
    onCaptureVideo: PropTypes.func.isRequired,
    // threshold: PropTypes.number.isRequired,
    artWorkFrameUrl: PropTypes.string.isRequired,
    artWorkGreenScreenBackgroundUrl: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired
};

CameraPreviewView.propTypes = {
    frontFacingPreviewFlipped: PropTypes.bool,
    frontFacingCameraFlipped: PropTypes.bool,
    testFlag: PropTypes.bool,
    enableGreenScreen: PropTypes.bool,
    isCroppedToSquare: PropTypes.bool,
    facingMode: PropTypes.string,
    appOrientation: PropTypes.string,
    jpgName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null])
    ]),
    videoEndTime: PropTypes.number,
    onCapture: PropTypes.func,
    setAppOrientation: PropTypes.func,
    // processPhotoReady: PropTypes.func,
    enableFaceMesh: PropTypes.bool,
    isFacingModeSwapping: PropTypes.bool,
    finishFacingModeSwapping: PropTypes.func,
    setVideoCaptureStarted: PropTypes.func,
    onCaptureVideo: PropTypes.func,
    // threshold: PropTypes.number,
    artWorkFrameUrl: PropTypes.string,
    artWorkGreenScreenBackgroundUrl: PropTypes.string,
    contentType: PropTypes.string
};
