import Spinner from "react-bootstrap/Spinner";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";

export const SpinnerMsg = ({ showSpinner, text, showBackground = true }) =>
{
    if (!showSpinner)
    {
        return null;
    }
    
    return (
        <div className="SpinnerMsg_Container">
            {showBackground && <div className="SpinnerMsg_Background" />}
            <Spinner
                className="SpinnerMsg_Spinner"
                animation="border"
                role="status"
            />
            <span className="SpinnerMsg_Text">
                {text}
            </span>
        </div>
    );
};

SpinnerMsg.defaultProps = {
    showSpinner: PropTypes.bool.isRequired,
    showBackground: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
};

SpinnerMsg.propTypes = {
    showSpinner: PropTypes.bool,
    showBackground: PropTypes.bool,
    text: PropTypes.string
};
