import axios from "axios";
import * as rax from "retry-axios";

export const serverURL = "https://api.tagkast.com";
export const hostURL = ((process.env.NODE_ENV === "development") ? "" : serverURL);

export const checkCampaignCode = (campaignCode) => () =>
{
    const url = `${hostURL}/v1/access_codes/input?code=${campaignCode}`;
    // const url = "https://httpstat.us/503"; // for testing

    rax.attach();

    return axios({
        method: "get",
        url: url,
        raxConfig: {
            retry: 3,
            retryDelay: 5000,
            backoffType: "static",
            httpMethodsToRetry: ["GET"],
            statusCodesToRetry: [[500, 599]],
            onRetryAttempt: (err) =>
            {
                const cfg = rax.getConfig(err);

                // console.log("cfg:", cfg);
                console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
            }
        }
    });
};

export const verifyOperator = (operatorCode) => () =>
{
    const url = `${hostURL}/v1/operators/verify`;
    // const url = "https://httpstat.us/502"; // for testing

    rax.attach();

    return axios({
        method: "post",
        url: url,
        data: {
            code: operatorCode
        },
        raxConfig: {
            retry: 3,
            retryDelay: 5000,
            backoffType: "static",
            httpMethodsToRetry: ["POST"],
            statusCodesToRetry: [[500, 599]],
            onRetryAttempt: (err) =>
            {
                const cfg = rax.getConfig(err);

                // console.log("cfg:", cfg);
                console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
            }
        }
    });
};

export const initializeCampaign = (campaignID, venueID, installationID) => () =>
{
    const url = `${hostURL}/v3/campaign/initialize`;
    // const url = "https://httpstat.us/503"; // for testing

    rax.attach();

    return axios({
        method: "post",
        url: url,
        data: {
            campaign_id: campaignID,
            venue_id: venueID,
            installation_id: installationID
        },
        raxConfig: {
            retry: 3,
            retryDelay: 5000,
            backoffType: "static",
            httpMethodsToRetry: ["POST"],
            statusCodesToRetry: [[500, 599]],
            onRetryAttempt: (err) =>
            {
                const cfg = rax.getConfig(err);

                // console.log("cfg:", cfg);
                console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
            }
        }
    });
};
