import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
import { arrayBufferObjectToBlob, getArtwork } from "../../../db";
import { isImageOnUrl } from "../../../assets/utils";

const NEXT_HISTORY = "/Frame";
export default function AppBackgroundView({ orientation, setArtworkAppBackground, artWorkAppBackgroundIndex, history })
{
    getArtwork(`app_background_${orientation}`).then((result) =>
    {
        if (result != null)
        {
            const isAppBackgroundDataImage = isImageOnUrl(result.url);
            let newUrl = result.url;

            if (isAppBackgroundDataImage && (result?.data != null))
            {
                newUrl = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
            }

            setArtworkAppBackground(newUrl, artWorkAppBackgroundIndex);

            history.push(NEXT_HISTORY);
        }
    });

    return null;
}

AppBackgroundView.defaultProps = {
    orientation: PropTypes.string.isRequired,
    setArtworkAppBackground: PropTypes.func.isRequired,
    artWorkAppBackgroundIndex: PropTypes.number.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

AppBackgroundView.propTypes = {
    orientation: PropTypes.string,
    setArtworkAppBackground: PropTypes.func,
    artWorkAppBackgroundIndex: PropTypes.number
    // history: PropTypes.shape(historyPropTypes)
};
