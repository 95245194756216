import React, { Component } from "react";
import PropTypes from "prop-types";
import Tile from "../Tile";

export default class TilesView extends Component
{
    getTilesImages = () =>
    {
        const { uploads, images } = this.props;

        if (!images || !images.length)
        {
            return null;
        }

        return images.map((element) => (
            <Tile
                key={element.id}
                data={element}
                type="image"
                upload={uploads[element.id]}
            />
        ));
    }

    getTilesVideos = () =>
    {
        const { videos } = this.props;

        if (!videos || !videos.length)
        {
            return null;
        }

        return videos.map((element) => (
            <Tile
                key={element.id}
                data={element}
                type="video"
                upload={null}
            />
        ));
    }

    render()
    {
        const images = this.getTilesImages();
        const videos = this.getTilesVideos();

        return (
            <div className="row">
                { images }
                { videos }
            </div>
        );
    }
}

TilesView.defaultProps = {
    images: [],
    videos: [],
    uploads: []
};

TilesView.propTypes = {
    images: PropTypes.instanceOf(Array),
    videos: PropTypes.instanceOf(Array),
    uploads: PropTypes.instanceOf(Array)
};
