import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const TypeBox = ({ name, isEnabled, onClickCallBack }) => (
    <button
        type="button"
        className={`${isEnabled ? "TypeBox" : "TypeBox_Disabled"}`}
        onClick={(e) =>
        {
            e.preventDefault();
            onClickCallBack(name.toLowerCase());
        }}
        key={name}
    >
        <div className="TypeBox_TextBlock">
            <span className="TypeBox_Name">{name}</span>
        </div>
    </button>
);

TypeBox.defaultProps = {
    isEnabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onClickCallBack: PropTypes.func.isRequired
};

TypeBox.propTypes = {
    isEnabled: PropTypes.bool,
    name: PropTypes.string,
    onClickCallBack: PropTypes.func
};

export default TypeBox;
