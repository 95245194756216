import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../db";
import { FrameChooser } from "../../CameraActivity/FrameChooser/FrameChooser";
import { Header } from "../Header/Header";
import { SVG_BTN } from "../ContentType/svg";
import { isImageOnUrl } from "../../../assets/utils";
import "../style.css";

const NEXT_HISTORY = "/CameraActivity";

export default function FaceView({ enableFaceMesh, setArtworkFace, listLengthFace, history })
{
    const [listFace, setListFace] = useState([]);
    const [indexFace, setIndexFace] = useState(-1);
    const [isFaceSelected, setIsFaceSelected] = useState(false);
    const refImg = useRef(null);

    useEffect(() =>
    {
        if (indexFace >= 0)
        {
            getArtwork(`face_${indexFace}`).then((result) =>
            {
                const isFaceDataImage = isImageOnUrl(result.url);
                let newUrl = result.url;

                if (isFaceDataImage && (result?.data != null))
                {
                    newUrl = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                }

                setArtworkFace(newUrl, indexFace);
            });
        }
    }, [indexFace, setArtworkFace]);

    if (!enableFaceMesh)
    {
        return (
            <Redirect to={NEXT_HISTORY} />
        );
    }

    if (listLengthFace)
    {
        if (listFace.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("face").each((art) =>
            {
                artworkList.push({
                    original: art.url,
                    thumbnail: art.url
                });
            }).then(() =>
            {
                artworkList?.length && setListFace(artworkList);

                if (artworkList?.length === 1)
                {
                    setIndexFace(0);
                    history.push(NEXT_HISTORY);
                }
            });
        }

        if (listLengthFace > 1)
        {
            return (
                <div role="main" className="ScreenSelect_Container">
                    <Header />
                    <span className="ScreenSelect_Header">Select your Face!</span>
                    <div className="ScreenSelect_Description_Block">
                        <span className="ScreenSelect_Description_Text">
                            Your experience will replace the Face in your photo.
                            Choose the Face you would like.
                            You can update the Face once the camera starts by pressing the &quot;Face&quot; button.
                        </span>
                        <span className="ScreenSelect_HelperText">
                            Click on the image to chose Face
                        </span>
                        <FrameChooser
                            text=""
                            index={indexFace >= 0 ? indexFace : 0}
                            list={listFace}
                            chooserModal={null}
                            onClick={() =>
                            {
                                const indexFaceNew = refImg.current.getCurrentIndex();

                                if (indexFaceNew !== indexFace)
                                {
                                    setIndexFace(indexFaceNew);
                                    setIsFaceSelected(true);
                                }

                                const imageList = document.getElementsByClassName("image-gallery-image");

                                for (let i = 0; i < imageList.length; i++)
                                {
                                    const classes = imageList[i].classList;

                                    (i === indexFaceNew) ? classes.add("active") : classes.remove("active");
                                }
                            }}
                            refVar={refImg}
                            hideButtons
                        />
                        <button
                            type="button"
                            className="ScreenSelect_Btn"
                            onClick={(e) =>
                            {
                                e.preventDefault();
                                history.push(NEXT_HISTORY);
                            }}
                            disabled={!isFaceSelected}
                        >
                            <span className="ScreenSelect_Btn_Text">Next</span>
                            <div className="ScreenSelect_Btn_Circle">{SVG_BTN}</div>
                        </button>
                    </div>
                </div>
            );
        }
    }

    return <div />;
}

FaceView.defaultProps = {
    enableFaceMesh: PropTypes.bool.isRequired,
    listLengthFace: PropTypes.number.isRequired,
    setArtworkFace: PropTypes.func.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

FaceView.propTypes = {
    enableFaceMesh: PropTypes.bool,
    listLengthFace: PropTypes.number,
    setArtworkFace: PropTypes.func
    // history: PropTypes.shape(historyPropTypes)
};
