import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnAgreeView = ({ onAgree }) => (
    <div className="BtnAgree">
        <button
            type="button"
            className="BtnAgree_Btn"
            onClick={onAgree}
        >
            <span className="BtnAgree_Text">
                I Agree
            </span>
        </button>
    </div>
);

BtnAgreeView.defaultProps = {
    onAgree: PropTypes.func.isRequired
};

BtnAgreeView.propTypes = {
    onAgree: PropTypes.func
};

export { BtnAgreeView };
