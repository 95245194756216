import { connect } from "react-redux";
import { selectUploads } from "../../../state/selectors";
import { UploadingView } from "./Uploading";

const mapStateToProps = (state) => ({
    uploads: selectUploads(state)
});

const mapDispatchToProps = () => ({
});

const Uploading = connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadingView);

export { Uploading };
