import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Label = ({ data, id, labelClass = "Label" }) => (
    <label
        className={labelClass}
        htmlFor={id}
    >
        { data }
    </label>
);

Label.defaultProps = {
    data: PropTypes.string.isRequired,
    labelClass: "Label",
    id: PropTypes.number.isRequired
};

Label.propTypes = {
    data: PropTypes.string,
    labelClass: PropTypes.string,
    id: PropTypes.number
};

export { Label };
