import { connect } from "react-redux";
import { BtnCaptureView } from "./BtnCapture";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnCapture = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnCaptureView);

export { BtnCapture };
