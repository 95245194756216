import React from "react";
import PropTypes from "prop-types";
import circles from "../../../assets/images/circles.jpg";
import "./style.css";

const ShaneCirclesView = ({ isVideoCaptureStarted }) =>
{
    const text = isVideoCaptureStarted ? "Recording" : "Get ready to record...";

    return (
        <div className="ShaneCircles">
            <img className="ShaneCircles_Img" src={circles} alt="circles" />
            <span className="ShaneCircles_Span">{text}</span>
        </div>
    );
};

ShaneCirclesView.defaultProps = {
    isVideoCaptureStarted: PropTypes.bool.isRequired
};

ShaneCirclesView.propTypes = {
    isVideoCaptureStarted: PropTypes.bool
};

export { ShaneCirclesView };
