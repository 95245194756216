import React from "react";
import PropTypes from "prop-types";
import swap from "../../../../assets/images/swap.png";
import "./style.css";

const BtnSwapView = ({ handleSwap, btnSwapClass }) => (
    <div className={btnSwapClass}>
        <button
            type="button"
            className="BtnSwap_Btn"
            onClick={handleSwap}
        >
            <img
                className="BtnSwap_Img"
                alt="swap"
                src={swap}
            />
        </button>
    </div>
);

BtnSwapView.defaultProps = {
    btnSwapClass: PropTypes.string.isRequired,
    handleSwap: PropTypes.func.isRequired
};

BtnSwapView.propTypes = {
    btnSwapClass: PropTypes.string,
    handleSwap: PropTypes.func
};

export { BtnSwapView };
