import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CLASS_BLOCK } from "../../../assets/constants";
import "./style.css";

const COUNT_LIST_NODELAY = [];

export const useInterval = (callback, delay) =>
{
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() =>
    {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() =>
    {
        const tick = () =>
        {
            savedCallback.current();
        };

        if (delay !== null)
        {
            const id = setInterval(tick, delay);
            
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const CounterView = ({ isCaptureBtnHoldType, handleTimerStart, callback, isContentPhoto, countdownTime }) =>
{
    const delayMs = 1000;
    const [containerClass] = useState(`Counter ${CLASS_BLOCK}`);
    const [flashClasses] = useState("flashScreen");
    const [count, setCount] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [elementToAnimate, setElementToAnimate] = useState(null);
    const [showCover, setShowCover] = useState(false);
    const [countList, setCountList] = useState([]);

    useEffect(() =>
    {
        if (isRunning && handleTimerStart)
        {
            handleTimerStart();
        }
    }, [isRunning, handleTimerStart]);

    useEffect(() =>
    {
        if (!isCaptureBtnHoldType && (countList.length < 1))
        {
            return;
        }

        const newId = `element${count}`;
        const el = countList[count];

        if (el)
        {
            const flashLongClass = el.length > 1 ? "Counter_TextLong" : "";
            const flashClass = `Counter_Flash ${flashClasses} ${flashLongClass}`;
            const classCountdownTextSize = el.length > 1 ? "Counter_FlashLongSize" : "";
            const classCountdownText = `Counter_Countdown ${classCountdownTextSize} animate__animated animate__zoomIn`;

            const elToAnimate = (
                <div className={flashClass}>
                    <div className={classCountdownText} key={countList[count]} id={newId}>
                        {countList[count]}
                    </div>
                </div>
            );

            setElementToAnimate(elToAnimate);
        }
        else
        {
            setIsRunning(false);
            setShowCover(true);
            callback && callback();
        }
    }, [count, flashClasses, callback]);

    useInterval(() =>
    {
        setCount(count + 1);
    }, isRunning ? delayMs : null);

    useEffect(() =>
    {
        const newCountList = [" "];
        const maxCountdownTimeSec = (countdownTime / 1000).toFixed(0);

        for (let i = maxCountdownTimeSec; i > 0; i--)
        {
            newCountList.push(i.toString());
        }
        newCountList.push(" ");

        setCountList(isCaptureBtnHoldType ? COUNT_LIST_NODELAY : newCountList);
    }, []);

    return (
        <div className={containerClass}>
            {elementToAnimate}
            {(isContentPhoto && showCover) && (
                <div className="Counter_Cover">
                    <p className="Counter_CoverText">Please wait...</p>
                </div>
            )}
        </div>
    );
};

CounterView.defaultProps = {
    isCaptureBtnHoldType: PropTypes.bool.isRequired,
    isContentPhoto: PropTypes.bool.isRequired,
    countdownTime: PropTypes.number.isRequired,
    handleTimerStart: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired
};

CounterView.propTypes = {
    isCaptureBtnHoldType: PropTypes.bool,
    isContentPhoto: PropTypes.bool,
    countdownTime: PropTypes.number,
    handleTimerStart: PropTypes.func,
    callback: PropTypes.func
};
