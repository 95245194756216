import React from "react";
// import PropTypes from "prop-types";
import "./style.css";

const NOERROR = "NOERROR";

const Error = ({ error }) =>
{
    const errorClass = error === NOERROR ? "Error_Transparent" : "Error";

    return (
        <div className={errorClass}>
            { error }
        </div>
    );
};

Error.defaultProps = {
    // error: PropTypes.string.isRequired
};

Error.propTypes = {
    // error: PropTypes.string
};

export { Error, NOERROR };
