import { connect } from "react-redux";
import { ShaneTextView } from "./ShaneText";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ShaneText = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShaneTextView);

export { ShaneText };
