import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnEmailView = ({ onEmail }) => (
    <div className="BtnEmail">
        <button
            type="button"
            className="BtnEmail_Btn"
            onClick={onEmail}
        >
            <span className="BtnEmail_Text">
                Email
            </span>
        </button>
    </div>
);

BtnEmailView.defaultProps = {
    onEmail: PropTypes.func.isRequired
};

BtnEmailView.propTypes = {
    onEmail: PropTypes.func
};

export { BtnEmailView };
