import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ImageGallery from "../../ImageGallery";
import { CLASS_NONE } from "../../../assets/constants";
import "./style.css";

export const FrameChooser = ({ text, index, list, chooserModal, onClick, refVar, hideButtons = false }) => (
    <div className="FrameChooser">
        <div style={{ textAlign: "center" }}>
            {text}
        </div>
        <ImageGallery
            showThumbnails={false}
            showPlayButton={false}
            ref={refVar}
            startIndex={index}
            items={list}
            onClick={hideButtons ? onClick : null}
        />
        <Button
            variant="warning"
            className={`m-2 ${hideButtons ? CLASS_NONE : ""}`}
            onClick={() => chooserModal()}
        >
            Cancel
        </Button>
        <Button
            variant="primary"
            className={`m-2 ${hideButtons ? CLASS_NONE : ""}`}
            onClick={() => onClick()}
        >
            Select
        </Button>
    </div>
);

FrameChooser.defaultProps = {
    text: "",
    index: 0,
    hideButtons: false,
    list: [],
    chooserModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    refVar: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]).isRequired
};

FrameChooser.propTypes = {
    text: PropTypes.string,
    index: PropTypes.number,
    hideButtons: PropTypes.bool,
    list: PropTypes.instanceOf(Array),
    chooserModal: PropTypes.func,
    onClick: PropTypes.func,
    refVar: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
};
