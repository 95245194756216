import { useEffect } from "react";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, getArtwork } from "../../../../db";

export default function GreenScreenBackgroundView({ name, orientation, enableGreenScreen,
    artWorkGreenScreenBackgroundIndex, setArtworkGreenScreenBackground })
{
    useEffect(() =>
    {
        if (enableGreenScreen)
        {
            const artworkName = `${name}greenscreen_background_${orientation}_${artWorkGreenScreenBackgroundIndex}`;

            getArtwork(artworkName).then((result) =>
            {
                if (result?.data != null)
                {
                    const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                    setArtworkGreenScreenBackground(url, artWorkGreenScreenBackgroundIndex);
                }
            });
        }
    }, [setArtworkGreenScreenBackground, orientation, artWorkGreenScreenBackgroundIndex]);

    return null;
}

GreenScreenBackgroundView.defaultProps = {
    enableGreenScreen: PropTypes.bool.isRequired,
    artWorkGreenScreenBackgroundIndex: PropTypes.number.isRequired,
    orientation: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    setArtworkGreenScreenBackground: PropTypes.func.isRequired
};

GreenScreenBackgroundView.propTypes = {
    enableGreenScreen: PropTypes.bool,
    artWorkGreenScreenBackgroundIndex: PropTypes.number,
    orientation: PropTypes.string,
    name: PropTypes.string,
    setArtworkGreenScreenBackground: PropTypes.func
};
