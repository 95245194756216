import { connect } from "react-redux";
import CameraBtnsView from "./CameraBtns";

const mapStateToProps = () => ({
    isCaptureBtnHoldType: false // TODO get it from the server
});

const mapDispatchToProps = () => ({
});

const CameraBtns = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraBtnsView);

export default CameraBtns;
