import { connect } from "react-redux";
import { BtnNotAgreeView } from "./BtnNotAgree";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnNotAgree = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnNotAgreeView);

export { BtnNotAgree };
