import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CONTENT_TYPE_VIDEO } from "../../../../assets/constants";
import "./style.css";

const BTN_START = "Record";
const BTN_STOP = "STOP";

const BtnStartStopView = ({ isVideoStarted, captureHandler, setContentTypeHandler, setShowCountDownOn }) =>
{
    const [btnText, setBtnText] = useState(BTN_START);

    const processVideoStart = () =>
    {
        if (!isVideoStarted)
        {
            setShowCountDownOn(true);
        }
    };

    const processVideoStop = () =>
    {
        if (isVideoStarted)
        {
            captureHandler();
        }
    };

    const btnHandler = () =>
    {
        !isVideoStarted ? processVideoStart() : processVideoStop();
    };

    const setContentVideo = () =>
    {
        setContentTypeHandler(CONTENT_TYPE_VIDEO);
    };

    useEffect(() =>
    {
        if (isVideoStarted && (btnText === BTN_START))
        {
            setBtnText(BTN_STOP);
        }
    }, [isVideoStarted]);

    useEffect(() =>
    {
        setContentVideo();
    }, []);

    const btnClassName = isVideoStarted ? "BtnStartStop_Square" : "BtnStartStop_Circle";

    return (
        <div className="BtnStartStop">
            <button
                type="button"
                className="BtnStartStop_BtnContainer"
                onClick={btnHandler}
            >
                <div className={btnClassName} />
                <span className="BtnStartStop_BtnText">{btnText}</span>
            </button>
        </div>
    );
};

BtnStartStopView.defaultProps = {
    isVideoStarted: PropTypes.bool.isRequired,
    captureHandler: PropTypes.func.isRequired,
    setContentTypeHandler: PropTypes.func.isRequired,
    setShowCountDownOn: PropTypes.func.isRequired
};

BtnStartStopView.propTypes = {
    isVideoStarted: PropTypes.bool,
    captureHandler: PropTypes.func,
    setContentTypeHandler: PropTypes.func,
    setShowCountDownOn: PropTypes.func
};

export { BtnStartStopView };
