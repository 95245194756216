import React from "react";
import ScrollArea from "react-scrollbar";

export const HTMLConditions = () => (
    <ScrollArea
        speed={0.8}
        className="HTMLTerms"
        horizontal={false}
        contentClassName="HTMLTerms_El"
    >
        <div className="HTMLTerms_Header">
            <span>Shane Co. Messaging Terms & Conditions</span>
        </div>

        <span className="HTMLTerms_Lvl1">
            You agree to receive recurring automated promotional and personalized marketing text (e.g., SMS and MMS)
            messages (e.g. cart reminders) from Shane Co., including text messages that may be sent using an automatic
            telephone dialing system, to the mobile telephone number you provided when signing up or any other
            number that you designate. Consent to receive automated marketing text messages is not a condition
            of any purchase. Msg & Data rates may apply.
        </span>
        <span className="HTMLTerms_Lvl1">
            Message frequency will vary. Shane Co. reserves the right to alter the frequency of messages sent at any
            time, so as to increase or decrease the total number of sent messages. Shane Co. also reserves the right
            to change the short code or phone number from which messages are sent and we will notify you when we do so.
        </span>
        <span className="HTMLTerms_Lvl1">
            Not all mobile devices or handsets may be supported and our messages may not be deliverable in all areas.
            Shane Co., its service providers and the mobile carriers supported by the program are not liable for delayed
            or undelivered messages.
        </span>
        <span className="HTMLTerms_Lvl1">
            You also agree to our
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.shaneco.com/about/terms-and-conditions?promo_name=nav&promo_id=footer&promo_creative=termsconditions"> Shane Co. Terms of Use </a>
            and
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.shaneco.com/about/privacy-policy?promo_name=nav&promo_id=footer&promo_creative=privacypolicy"> Shane Co. Privacy Policy.</a>
        </span>
        <span className="HTMLTerms_Lvl1">
            We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon
            Wireless, Sprint, T-Mobile, MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor
            carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One
            of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral
            Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden
            State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless
            (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech
            Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP
            Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5
            Star Wireless).
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Cancellation</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            Text the keyword STOP, END, CANCEL, UNSUBSCRIBE or QUIT to our shortcode to cancel. After texting STOP, END,
            CANCEL, UNSUBSCRIBE or QUIT to our shortcode you will receive one additional message confirming that your
            request has been processed. You acknowledge that our text message platform may not recognize and respond to
            unsubscribe requests that do not include the STOP, END, CANCEL, UNSUBSCRIBE or QUIT keyword commands and
            agree that Shane Co. and its service providers will have no liability for failing to honor such requests.
            If you unsubscribe from one of our text message programs, you may continue to receive text messages from
            Shane Co. through any other programs you have joined until you separately unsubscribe from those programs.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Help</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            Text the keyword HELP to our shortcode to return customer care contact information.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Customer Care</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            If you are experiencing any problems, please visit
            <a href="https://support.attentivemobile.com/help/"> https://support.attentivemobile.com/help/ </a>
            and submit the form with details about your problem or your request for support,
            or email support@attentivemobile.com.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Contact</b>
        </span>
        <span className="HTMLTerms_Lvl1">
            This message program is a service of Shane Co., located at 2203 Uravan Street, Aurora, Colorado 80011.
        </span>
        <span className="HTMLTerms_Lvl1">
            <b>Dispute Resolution</b>
        </span>
        <span className="HTMLTerms_Lvl2">
            a. General. In the interest of resolving disputes between you and Shane Co. in the most expedient and cost
            effective manner, you and Shane Co. agree that any dispute arising out of or in any way related to these
            messaging terms and conditions (&quot;Messaging Terms&quot;) or your receipt of text messages from
            Shane Co. or its service providers will be resolved by binding arbitration. Arbitration is less formal
            than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for
            more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can
            award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all
            claims arising out of or in any way related to these Messaging Terms, or your receipt of text messages from
            Shane Co. or its service providers whether based in contract, tort, statute, fraud, misrepresentation, or
            any other legal theory, and regardless of when a claim arises. YOU UNDERSTAND AND AGREE THAT, BY AGREEING
            TO THESE MESSAGING TERMS, YOU AND Shane Co. ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
            IN A CLASS ACTION AND THAT THESE MESSAGING TERMS SHALL BE SUBJECT TO AND GOVERNED BY THE FEDERAL
            ARBITRATION ACT.
        </span>
        <span className="HTMLTerms_Lvl2">
            b. Exceptions. Notwithstanding subsection (a) above, nothing in these Messaging Terms will be deemed to
            waive, preclude, or otherwise limit the right of you or Shane Co. to: (i) bring an individual action in
            small claims court; (ii) pursue an enforcement action through the applicable federal, state, or local agency
            if that action is available; (iii) seek injunctive relief in aid of arbitration from a court of competent
            jurisdiction; or (iv) file suit in a court of law to address an intellectual property infringement claim.
            Arbitrator.
        </span>
        <span className="HTMLTerms_Lvl2">
            c. Arbitrator. Any arbitration between you and Shane Co. will be governed by the Federal Arbitration Act
            and the Commercial Dispute Resolution Procedures and Supplementary Procedures for Consumer Related Disputes
            (collectively, &quot;AAA Rules&quot;) of the American Arbitration Association (&quot;AAA&quot;), as modified
            by these Messaging Terms, and will be administered by the AAA. The AAA Rules and filing forms are available
            online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting Shane Co.. The arbitrator has
            exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability
            of this binding arbitration agreement.
        </span>
        <span className="HTMLTerms_Lvl2">
            d. Notice; Process. If you or Shane Co. intends to seek arbitration, then the party seeking arbitration must
            first send a written notice of the dispute to the other party by U.S. Mail (&quot;Notice&quot;). Shane Co.
            address for Notice is: 2203 Uravan Street, Aurora, Colorado 80011, Attn: Chief Executive Officer. The Notice
            must: (i) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief
            sought (&quot;Demand&quot;). You and Shane Co. will make good faith efforts to resolve the claim directly,
            but if you and Shane Co. do not reach an agreement to do so within 30 days after the Notice is received,
            you or Shane Co. may commence an arbitration proceeding. During the arbitration, the amount of any
            settlement offer made by you or Shane Co. must not be disclosed to the arbitrator until after the arbitrator
            makes a final decision and award, if any. (e) Fees. If you commence arbitration in accordance with these
            Messaging Terms, Shane Co. will reimburse you for your payment of the filing fee, unless your claim is for
            more than $15,000 or as set forth below, in which case the payment of any fees will be decided by the AAA
            Rules. If the claim is for $15,000 or less, you may choose whether the arbitration will be conducted: (i)
            solely on the basis of documents submitted to the arbitrator; (ii) through a non-appearance based telephone
            hearing; or (iii) by an in-person hearing as established by the AAA Rules. If the arbitrator finds that
            either the substance of your claim or the relief sought in the Demand is frivolous or brought for an
            improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then
            the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse Shane Co.
            for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules.
            Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written
            decision sufficient to explain the essential findings and conclusions on which the decision and award,
            if any, are based. You and Shane Co. agree that such written decision, and information exchanged during
            arbitration, will be kept confidential except to the extent necessary to enforce or permit limited judicial
            review of the award. The arbitrator may make rulings and resolve disputes as to the payment and
            reimbursement of fees or expenses at any time during the proceeding and upon request from you or Shane Co.
            made within 14 days of the arbitrator&apos;s ruling on the merits.
        </span>
        <span className="HTMLTerms_Lvl2">
            e. No Class Actions. YOU AND Shane Co. AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING. Further, unless both you and Shane Co. agree otherwise in a signed writing, the arbitrator may
            not consolidate more than one person&apos;s claims, and may not otherwise preside over any form of a
            representative or class proceeding.
        </span>
        <span className="HTMLTerms_Lvl2">
            f. Modifications to this Arbitration Provision. Notwithstanding anything to the contrary in these Messaging
            Terms, if Shane Co. makes any future change to this arbitration provision, other than a change to Shane Co.
            address for Notice, you may reject the change by sending us written notice within 30 days of the change to
            Shane Co. address for Notice, in which case this arbitration provision, as in effect immediately prior to
            the changes you rejected, will continue to govern any disputes between you and Shane Co..
        </span>
        <span className="HTMLTerms_Lvl2">
            g. Enforceability. If an arbitrator decides that applicable law precludes enforcement of any of the
            limitations of subsection (f) above (addressing class, representative and consolidated proceedings)
            as to a particular claim for relief, then that claim (and only that claim) must be severed from the
            arbitration and brought in court. If any other provision of these Messaging Terms is found to be
            unenforceable, the applicable provision shall be deemed stricken and the remainder of these Messaging
            Terms shall remain in full force and effect.
        </span>
    </ScrollArea>
);
