import { connect } from "react-redux";
import { selectCampaign, selectCampaignID, selectOrientation, selectTestFlag,
    selectArtworkFrame, selectArtworkGreenScreenBackground, selectOuterCSS, selectContentType
} from "../../state/selectors";
import { setOrientation } from "../../state/actions";
import CameraPreviewView from "./CameraPreview";

const mapStateToProps = (state) => ({
    campaignID: selectCampaignID(state),
    videoEndTime: selectCampaign(state).video_settings.max_video_duration,
    appOrientation: selectOrientation(state),
    testFlag: selectTestFlag(state),
    enableGreenScreen: selectCampaign(state).is_green_screen_enabled,
    frontFacingPreviewFlipped: selectCampaign(state).is_frontfacing_preview_flipped,
    frontFacingCameraFlipped: selectCampaign(state).is_frontfacing_camera_flipped,
    enableFaceMesh: selectCampaign(state).is_facetexture_enabled,
    artWorkFrameUrl: selectArtworkFrame(state).url,
    artWorkGreenScreenBackgroundUrl: selectArtworkGreenScreenBackground(state).url,
    isCroppedToSquare: selectCampaign(state).crop_to_square,
    outerCSS: selectOuterCSS(state),
    contentType: selectContentType(state)
});

const mapDispatchToProps = (dispatch) => ({
    setAppOrientation: (appOrientation) => dispatch(setOrientation(appOrientation))
});

const CameraPreview = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraPreviewView);

export default CameraPreview;
