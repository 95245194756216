import { connect } from "react-redux";
import { PhoneView } from "./Phone";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const Phone = connect(
    mapStateToProps,
    mapDispatchToProps
)(PhoneView);

export { Phone };
