import React, { useState } from "react";
import PropTypes from "prop-types";
import { BtnShare } from "../BtnShare";
import { BtnCancel } from "../BtnCancel";
import "./style.css";

const PhoneView = ({ onShare, onCancel }) =>
{
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const [phone, setPhone] = useState("");

    const handlePhoneChange = (event) =>
    {
        const phoneString = event.target.value;
        const phoneNumbers = phoneString.replace(/\D/g, "");
        const minLength = 9;
        const maxLength = 15;
        let phoneValid = false;

        if ((minLength <= phoneNumbers.length) && (phoneNumbers.length <= maxLength))
        {
            phoneValid = true;
        }
        setPhone(`${phoneNumbers}`);
        setPhoneIsValid(phoneValid);
    };

    const classInput = phoneIsValid ? "Phone_Input Phone_Valid" : "Phone_Input Phone_Invalid";

    return (
        <div className="Phone">
            <div className="Phone_Container">
                <div className="Phone_Header">
                    <span className="Phone_Header_Text">i</span>
                </div>
                <div className="Phone_Disclaimer">
                    <span>Enter Your</span>
                    <span>Phone Number</span>
                </div>
                <input
                    className={classInput}
                    name="phone"
                    placeholder="Phone Number"
                    onChange={handlePhoneChange}
                />
                <div className="Phone_Btns">
                    <BtnShare valueIsValid={phoneIsValid} type="Text" onShare={onShare} data={phone} />
                    <BtnCancel onCancel={onCancel} />
                </div>
            </div>
        </div>
    );
};

PhoneView.defaultProps = {
    onShare: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

PhoneView.propTypes = {
    onShare: PropTypes.func,
    onCancel: PropTypes.func
};

export { PhoneView };
