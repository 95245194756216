import { connect } from "react-redux";
import { selectCampaign } from "../../state/selectors";
import CameraErrorView from "./CameraError";

const mapStateToProps = (state) => ({
    campaign: selectCampaign(state)
});

const mapDispatchToProps = () => ({
});

const CameraError = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraErrorView);

export default CameraError;
