/* eslint-disable max-len */

import React from "react";
import { BtnBack } from "../CameraActivity/CameraBtns/BtnBack";
import "./style.css";

const LI1 = "https://shaneco.ar.photo/5620-30.mp3";
const LI2 = "https://shaneco.ar.photo/5623-30.mp3";
const LI3 = "https://shaneco.ar.photo/5625-30.mp3";
export const ExamplesLinks = ({ history }) =>
{
    const clickHandler = () =>
    {
        history.push("/Background");
    };

    return (
        <div className="ExamplesLinks">
            <BtnBack handleDone={clickHandler} />
            <div className="ExamplesLinks_Container">
                <div className="ExamplesLinks_AudioBlock">
                    <span className="ExamplesLinks_Span">Example 1: Graduation Necklace</span>
                    <audio className="ExamplesLinks_Url" controls>
                        <source src={LI1} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
                <div className="ExamplesLinks_AudioBlock">
                    <span className="ExamplesLinks_Span">Example 2: Engagement Ring</span>
                    <audio className="ExamplesLinks_Url" controls>
                        <source src={LI2} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
                <div className="ExamplesLinks_AudioBlock">
                    <span className="ExamplesLinks_Span">Example 3: Three-Stone Ring</span>
                    <audio className="ExamplesLinks_Url" controls>
                        <source src={LI3} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
        </div>
    );
};
