import React, { useEffect, useState } from "react";
import { arrayBufferObjectToBlob, getArtwork } from "../../../db";
import aces from "../../../assets/images/tagkast_header_logo.png";

export const Header = () =>
{
    const [url, setUrl] = useState(aces);

    useEffect(() =>
    {
        getArtwork("header_image").then((result) =>
        {
            if (result?.data != null)
            {
                const newUrl = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                setUrl(newUrl);
            }
        });
    }, []);

    return (
        <div className="ScreenSelect_Header">
            <img className="ScreenSelect_Header_Img" src={url} alt="Logo" />
        </div>
    );
};
