import { connect } from "react-redux";
import {
    setCurrentCampaignID,
    setImages,
    setJpgList,
    hideHeader,
    showHeader,
    setJpgListLength
} from "../../state/actions";
import {
    selectCampaign,
    selectTestFlag
} from "../../state/selectors";
import AppLoaderView from "./AppLoader";

const mapStateToProps = (state) => ({
    ...state,
    campaign: selectCampaign(state),
    testFlag: selectTestFlag(state)
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentCampaignID: (value) => dispatch(setCurrentCampaignID(value)),
    setJpgList: (value) => dispatch(setJpgList(value)),
    setJpgListLength: (value) => dispatch(setJpgListLength(value)),
    setImages: (value) => dispatch(setImages(value)),
    hideHeader: () => dispatch(hideHeader()),
    showHeader: () => dispatch(showHeader())
});

const AppLoader = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppLoaderView);

export default AppLoader;
