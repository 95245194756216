import { connect } from "react-redux";
import { EmailView } from "./Email";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const Email = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailView);

export { Email };
