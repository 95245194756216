import React from "react";
import { bool, func, string } from "prop-types";
import { isImageOnUrl } from "../../assets/utils";

const Item = React.memo(({
    description,
    fullscreen, // fullscreen version of img
    handleImageLoaded,
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    loading
}) => 
{
    const itemSrc = isFullscreen ? (fullscreen || original) : original;
    const isItemImage = isImageOnUrl(itemSrc);
    let item;

    if (isItemImage)
    {
        item = (
            <img
                className="image-gallery-image"
                src={itemSrc}
                alt={originalAlt}
                srcSet={srcSet}
                height={originalHeight}
                width={originalWidth}
                sizes={sizes}
                title={originalTitle}
                onLoad={(event) => handleImageLoaded(event, original)}
                onError={onImageError}
                loading={loading}
            />
        );
    }
    else
    {
        item = (
            <video
                className="image-gallery-image"
                autoPlay
                playsInline
                loop
                muted
                crossOrigin="anonymous"
                preload="auto"
                src={itemSrc}
                height={originalHeight}
                width={originalWidth}
                title={originalTitle}
                onLoadedData={(event) => handleImageLoaded(event, original)}
                onError={onImageError}
            />
        );
    }

    return (
        <>
            {item}
            {
                description && (
                    <span className="image-gallery-description">
                        {description}
                    </span>
                )
            }
        </>
    );
});

Item.displayName = "Item";

Item.propTypes = {
    description: string,
    fullscreen: string, // fullscreen version of img
    handleImageLoaded: func.isRequired,
    isFullscreen: bool,
    onImageError: func.isRequired,
    original: string.isRequired,
    originalAlt: string,
    originalHeight: string,
    originalWidth: string,
    originalTitle: string,
    sizes: string,
    srcSet: string,
    loading: string
};

Item.defaultProps = {
    description: "",
    fullscreen: "",
    isFullscreen: false,
    originalAlt: "",
    originalHeight: "",
    originalWidth: "",
    originalTitle: "",
    sizes: "",
    srcSet: "",
    loading: "eager"
};

export default Item;
