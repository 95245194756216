import { connect } from "react-redux";
import {
    selectCampaign,
    selectCampaignID,
    selectImageWorker,
    selectLastSurveyId
} from "../../state/selectors";
import { addImage } from "../../state/actions";
import TnxPageView from "./TnxPage";

const mapStateToProps = (state) => ({
    campaignID: selectCampaignID(state),
    campaign: selectCampaign(state),
    imageWorker: selectImageWorker(state),
    surveyID: selectLastSurveyId(state)
});

const mapDispatchToProps = (dispatch) => ({
    addImage: (value) => dispatch(addImage(value))
});

const TnxPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(TnxPageView);

export default TnxPage;
