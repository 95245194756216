import React, { useState } from "react";
import PropTypes from "prop-types";
import { BtnShare } from "../BtnShare";
import { BtnCancel } from "../BtnCancel";
import "./style.css";

const EmailView = ({ onShare, onCancel }) =>
{
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [email, setEmail] = useState("");

    const handleEmailChange = (event) =>
    {
        const emailString = event.target.value;
        const regex = /\S+@\S+\.\S+/;
        const emailValid = regex.test(emailString);

        setEmail(emailString);
        setEmailIsValid(emailValid);
    };

    const classInput = emailIsValid ? "Email_Input Email_Valid" : "Email_Input Email_Invalid";

    return (
        <div className="Email">
            <div className="Email_Container">
                <div className="Email_Header">
                    <span className="Email_Header_Text">i</span>
                </div>
                <span className="Email_Disclaimer">Enter Your Email</span>
                <input
                    className={classInput}
                    name="Email"
                    placeholder="Email"
                    onChange={handleEmailChange}
                />
                <div className="Email_Btns">
                    <BtnShare valueIsValid={emailIsValid} type="Email" onShare={onShare} data={email} />
                    <BtnCancel onCancel={onCancel} />
                </div>
            </div>
        </div>
    );
};

EmailView.defaultProps = {
    onShare: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

EmailView.propTypes = {
    onShare: PropTypes.func,
    onCancel: PropTypes.func
};

export { EmailView };
