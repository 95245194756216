import { connect } from "react-redux";
import { BtnEmailView } from "./BtnEmail";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnEmail = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnEmailView);

export { BtnEmail };
