import { connect } from "react-redux";
import { ShaneCirclesView } from "./ShaneCircles";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ShaneCircles = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShaneCirclesView);

export { ShaneCircles };
