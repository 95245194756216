import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnTakeAnotherView = ({ onTakeAnother }) => (
    <div className="BtnTakeAnother">
        <button
            type="button"
            className="BtnTakeAnother_Btn"
            onClick={onTakeAnother}
        >
            <span className="BtnTakeAnother_Text">
                Take Another
            </span>
        </button>
    </div>
);

BtnTakeAnotherView.defaultProps = {
    onTakeAnother: PropTypes.func.isRequired
};

BtnTakeAnotherView.propTypes = {
    onTakeAnother: PropTypes.func
};

export { BtnTakeAnotherView };
