import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnNotAgreeView = ({ onNotAgree }) => (
    <div className="BtnNotAgree">
        <button
            type="button"
            className="BtnNotAgree_Btn"
            onClick={onNotAgree}
        >
            <span className="BtnNotAgree_Text">
                I Don&apos;t Agree
            </span>
        </button>
    </div>
);

BtnNotAgreeView.defaultProps = {
    onNotAgree: PropTypes.func.isRequired
};

BtnNotAgreeView.propTypes = {
    onNotAgree: PropTypes.func
};

export { BtnNotAgreeView };
