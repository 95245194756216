import { connect } from "react-redux";
import { BtnStartStopView } from "./BtnStartStop";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnStartStop = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnStartStopView);

export { BtnStartStop };
