import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnPlayBackView = ({ onClick }) => (
    <div className="BtnPlayBack">
        <button
            type="button"
            className="BtnPlayBack_Btn"
            onClick={onClick}
        >
            <span className="BtnPlayBack_Text">
                PlayBack
            </span>
        </button>
    </div>
);

BtnPlayBackView.defaultProps = {
    onClick: PropTypes.func.isRequired
};

BtnPlayBackView.propTypes = {
    onClick: PropTypes.func
};

export { BtnPlayBackView };
