/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Label } from "../Label";
import { Error, NOERROR } from "../Error";
import "./style.css";

const TextInput = ({ label, showInputLabel, ...props }) =>
{
    const [field, meta] = useField(props);
    const { id, name, placeholder } = props;

    if (placeholder.toLowerCase().includes("story"))
    {
        return null;
    }

    const textInputLabel = label && <Label data={label} id={id || name} />;
    const isError = meta.error || meta.value === "";

    let textInputClass = "TextInput ";
    let inputClass = "TextInput_Input ";

    textInputClass += (label === "EMAIL ADDRESS") ? "TextInput_Email" : " ";
    inputClass += isError ? "LeadGenActivity_Invalid" : "LeadGenActivity_Valid";

    return (
        <div className={textInputClass}>
            <div className="TextInput_Container TextInput_Email">
                { showInputLabel && textInputLabel }
                <input className={inputClass} {...field} {...props} />
            </div>

            <Error error={meta.error || NOERROR} />
        </div>
    );
};

TextInput.defaultProps = {
    label: PropTypes.string.isRequired,
    showInputLabel: PropTypes.bool.isRequired
};

TextInput.propTypes = {
    label: PropTypes.string,
    showInputLabel: PropTypes.bool
};

export { TextInput };
