import { connect } from "react-redux";
import { selectCampaign } from "../../../state/selectors";
import { CounterSimpleView } from "./CounterSimple";

const mapStateToProps = (state) => ({
    videoEndTime: selectCampaign(state).video_settings.max_video_duration
});

const mapDispatchToProps = () => ({
});

const CounterSimple = connect(
    mapStateToProps,
    mapDispatchToProps
)(CounterSimpleView);

export { CounterSimple };
