import { useEffect } from "react";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, getArtwork } from "../../../../db";

export default function FrameView({ orientation, setArtworkFrame, artWorkFrameIndex })
{
    useEffect(() =>
    {
        getArtwork(`frame_${orientation}_${artWorkFrameIndex}`).then((result) =>
        {
            if (result?.data != null)
            {
                const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                setArtworkFrame(url, artWorkFrameIndex);
            }
        });
    }, [orientation, artWorkFrameIndex, setArtworkFrame]);

    return null;
}

FrameView.defaultProps = {
    orientation: PropTypes.string.isRequired,
    setArtworkFrame: PropTypes.func.isRequired,
    artWorkFrameIndex: PropTypes.number.isRequired
};

FrameView.propTypes = {
    orientation: PropTypes.string,
    setArtworkFrame: PropTypes.func,
    artWorkFrameIndex: PropTypes.number
};
