import React, { useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as rax from "retry-axios";
import { serverURL } from "../../webapi/actions";
import { blobToArrayBufferObject, putImage } from "../../db";
import "./style.css";

function generateRandomBlob(size)
{
    const randomBytes = new Uint8Array(size);
    crypto.getRandomValues(randomBytes);

    return new Blob([randomBytes], { type: "image/jpeg" });
}

export default function TnxPageView({ history, campaignID, campaign, surveyID, imageWorker, addImage })
{
    const sendEmail = (key) =>
    {
        const urlType = "v1/photos";
        const url = `${serverURL}/${urlType}/email/`;
        const jsonData = {};
        const email = localStorage.getItem("email");

        jsonData.id = key.toString(); // TODO is it tagkast ID ?
        jsonData.operator_id = "1082";
        jsonData.device_code = campaign.device_code.toString();
        jsonData.email = email.toString();
        jsonData.is_post_event = false;
        jsonData.gallery_id = campaign.gallery_id.toString();
        jsonData.source = "app";
        // jsonData.status = pageUrl;
        jsonData.photos = [key.toString()];
        jsonData.subscribe = false;
        jsonData.is_instagram_send = false;

        axios({
            method: "post",
            url: url,
            data: jsonData,
            headers: { "Content-Type": "application/json" },
            raxConfig: {
                retry: 3,
                retryDelay: 5000,
                backoffType: "static",
                httpMethodsToRetry: ["POST"],
                statusCodesToRetry: [[500, 599]],
                onRetryAttempt: (err) =>
                {
                    const cfg = rax.getConfig(err);

                    console.log(`[Info]: Retry attempt #${cfg.currentRetryAttempt} for ${url}`);
                }
            }
        })
            .then((response) =>
            {
                if (response.data.status.code <= 300)
                {
                    console.info("Success");
                }
                else
                {
                    console.error("Error on sending with:", response.data.status.code);
                }
            })
            .catch((error) =>
            {
                console.info("[Error]:", error);
                history.replace("/CampaignInitialization");
            });
    };

    useEffect(() =>
    {
        const blobSize = 64;
        const blobData = generateRandomBlob(blobSize);

        blobToArrayBufferObject(blobData).then(async (arrayObj) =>
        {
            const newImage = {
                campaignID: campaignID,
                galleryID: campaign.gallery_id,
                created_at: (new Date()).getTime(),
                isUploaded: 0,
                deviceID: campaign.device_code,
                image: arrayObj,
                qrcode: null
            };
            const { survey } = history.location.state;

            campaign.lead_generation.questions.forEach((question) =>
            {
                if (question.category === "qrcode")
                {
                    survey.survey.forEach((answer) =>
                    {
                        if (answer.id === question.id)
                        {
                            newImage.qrcode = answer.answer;
                        }
                    });
                }
            });

            putImage(newImage).then((key) =>
            {
                addImage(newImage);
                imageWorker && imageWorker.postMessage({ messageType: "upload_images" });
                sendEmail(key);
            });
        });
    }, []);

    const doneButton = () =>
    {
        const newHistory = "/KioskStartScreen";

        history.replace(newHistory);
    };

    return (
        <button
            type="button"
            className="TnxPage"
            onClick={doneButton}
        >
            <div className="TnxPage_Header">
                <span className="TnxPage_HeaderSpan">Thank you for sharing</span>
                <span className="TnxPage_HeaderSpan TnxPage_Header_Italic"> your </span>
                <span className="TnxPage_HeaderSpan">story.</span>
            </div>
            <span className="TnxPage_Span" />
            <div
                className="Button_Btn TnxPage_Btn"
            >
                Done
            </div>

        </button>
    );
}

TnxPageView.defaultProps = {
    campaignID: PropTypes.number.isRequired,
    surveyID: PropTypes.number.isRequired,
    campaign: PropTypes.object.isRequired,
    imageWorker: PropTypes.object.isRequired,
    addImage: PropTypes.func.isRequired
};

TnxPageView.propTypes = {
    campaignID: PropTypes.number,
    surveyID: PropTypes.number,
    campaign: PropTypes.shape({ root: PropTypes.string }),
    // history: PropTypes.shape(historyPropTypes),
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    addImage: PropTypes.func
};
