import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnTextView = ({ onText }) => (
    <div className="BtnText">
        <button
            type="button"
            className="BtnText_Btn"
            onClick={onText}
        >
            <span className="BtnText_Text">
                Text
            </span>
        </button>
    </div>
);

BtnTextView.defaultProps = {
    onText: PropTypes.func.isRequired
};

BtnTextView.propTypes = {
    onText: PropTypes.func
};

export { BtnTextView };
