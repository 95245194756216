import React, { Component } from "react";
// import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const defaultText = "We are unable to access your camera. If you are using an Apple device, " +
    "you must open this in Safari. If you are not on iOS, please make sure " +
    "you are using the default browser and your camera is accessible.";

export default class CameraErrorView extends Component
{
    render() 
    {
        const { campaign, history } = this.props;
        let newHistory = "/HomeScreenActivity";

        if (campaign?.activation_mode === "kiosk")
        {
            newHistory = "/KioskStartScreen";
        }

        const data = history.location.state?.data;
        const errorText = data || defaultText;

        return (
            <div className="text-center">
                <div>
                    <h3 className="pb-2 font-weight-normal">
                        { errorText }
                    </h3>
                    <Button
                        variant="primary"
                        className="m-2"
                        onClick={() => history.replace(newHistory)}
                    >
                        Try Again
                    </Button>
                </div>
            </div>
        );
    }
}

CameraErrorView.defaultProps = {
    campaign: PropTypes.object.isRequired
    // history: PropTypes.shape(historyPropTypes).isRequired
};

CameraErrorView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string })
    // history: PropTypes.shape(historyPropTypes)
};
