import { useEffect } from "react";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, getArtwork } from "../../../../db";

export default function FaceView({ enableFaceMesh, artWorkFaceIndex, setArtworkFace })
{
    useEffect(() =>
    {
        if (enableFaceMesh)
        {
            getArtwork(`face_${artWorkFaceIndex}`).then((result) =>
            {
                if (result?.data != null)
                {
                    const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
                    setArtworkFace(url, artWorkFaceIndex);
                }
            });
        }
    }, [enableFaceMesh, artWorkFaceIndex, setArtworkFace]);

    return null;
}

FaceView.defaultProps = {
    enableFaceMesh: PropTypes.bool.isRequired,
    artWorkFaceIndex: PropTypes.number.isRequired,
    setArtworkFace: PropTypes.func.isRequired
};

FaceView.propTypes = {
    enableFaceMesh: PropTypes.bool,
    artWorkFaceIndex: PropTypes.number,
    setArtworkFace: PropTypes.func
};
