import { connect } from "react-redux";
import { selectCampaign, selectContentType, selectOrientation } from "../../../state/selectors";
import { setArtworkGreenScreenBackground } from "../../../state/actions";
import BackgroundView from "./Background";
import { CONTENT_TYPE_STILL } from "../../../assets/constants";

const mapStateToProps = (state) =>
{
    const campaign = selectCampaign(state);
    const contentType = selectContentType(state);
    const isContentStill = contentType === CONTENT_TYPE_STILL;

    const listLengthBackgroundImg = campaign.green_screen_settings?.backgrounds.length;
    const listLengthBackgroundVideo = campaign.video_green_screen_settings?.backgrounds.length;
    const length = isContentStill ? listLengthBackgroundImg : listLengthBackgroundVideo;
    const name = isContentStill ? "" : "video_";
    const keyName = isContentStill ? "greenscreen" : "videobackgrounds";

    return ({
        name: name,
        keyName: keyName,
        orientation: selectOrientation(state),
        listLengthBackground: length,
        enableGreenScreen: selectCampaign(state).is_green_screen_enabled
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkGreenScreenBackground: (url, index) => dispatch(setArtworkGreenScreenBackground(url, index))
});
const Background = connect(
    mapStateToProps,
    mapDispatchToProps
)(BackgroundView);

export default Background;
