import { connect } from "react-redux";
import { BtnCancelView } from "./BtnCancel";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnCancel = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnCancelView);

export { BtnCancel };
