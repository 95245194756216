import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CLASS_BLOCK } from "../../../assets/constants";
import "./style.css";

const INTERVAL_IN_MILLISECONDS = 100;

export const CounterSimpleView = ({ videoEndTime }) =>
{
    const [containerClass] = useState(`CounterSimple ${CLASS_BLOCK}`);
    const [flashClasses] = useState("flashScreen");
    const [count, setCount] = useState(0);
    const [elementToAnimate, setElementToAnimate] = useState(null);
    const [countList, setCountList] = useState([]);

    const [time, setTime] = useState(videoEndTime * 1000);
    const [referenceTime, setReferenceTime] = useState(Date.now());

    useEffect(() =>
    {
        const countDownUntilZero = () =>
        {
            setTime((prevTime) =>
            {
                if (prevTime <= 0)
                {
                    return 0;
                }

                const now = Date.now();
                const interval = now - referenceTime;

                setReferenceTime(now);

                return (prevTime - interval);
            });
        };

        const timer = setTimeout(countDownUntilZero, INTERVAL_IN_MILLISECONDS);

        const newCount = videoEndTime - Number((time / 1000).toFixed(0));

        if (count !== newCount)
        {
            setCount(newCount);
        }

        return () =>
        {
            clearTimeout(timer);
        };
    }, [time]);

    useEffect(() =>
    {
        const newId = `element${count}`;
        const el = countList[count];

        if (el)
        {
            const flashLongClass = el.length > 1 ? "CounterSimple_TextLong" : "";
            const flashClass = `CounterSimple_Flash ${flashClasses} ${flashLongClass}`;
            const classCountdownTextSize = el.length > 1 ? "CounterSimple_FlashLongSize" : "";
            // eslint-disable-next-line max-len
            const classCountdownText = `CounterSimple_Countdown ${classCountdownTextSize} animate__animated animate__zoomIn`;

            const elToAnimate = (
                <div className={flashClass}>
                    <div className={classCountdownText} key={countList[count]} id={newId}>
                        {countList[count]}
                    </div>
                </div>
            );

            setElementToAnimate(elToAnimate);
        }
    }, [count]);

    useEffect(() =>
    {
        const newCountList = [" "];

        for (let i = videoEndTime - 1; i > 0; i--)
        {
            newCountList.push(i.toString());
        }
        newCountList.push(" ");

        setCountList(newCountList);
    }, []);

    return (
        <div className={containerClass}>
            {elementToAnimate}
        </div>
    );
};

CounterSimpleView.defaultProps = {
    videoEndTime: PropTypes.number.isRequired
};

CounterSimpleView.propTypes = {
    videoEndTime: PropTypes.number
};
