import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CONTENT_TYPE_VIDEO } from "../../../assets/constants";
import rendering from "../../../assets/images/rendering.jpg";
import "./style.css";

const UploadingView = ({ ID, uploads, type, goToPhotoShare }) =>
{
    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadingText = ID ? "Rendering audio..." : "Registering audio...";

    useEffect(() =>
    {
        const upload = uploads?.[ID];
        const newUploadProgress = upload?.uploadProgress;
        const roundedUploadProgress = Math.round(Number(newUploadProgress));
        const uploadError = upload?.uploadError;

        if (uploadError)
        {
            console.log("uploadError", uploadError); // TODO uploadError
        }

        if (!isNaN(roundedUploadProgress))
        {
            newUploadProgress && setUploadProgress(roundedUploadProgress);
        }

        if (type === CONTENT_TYPE_VIDEO)
        {
            setUploadProgress(100);
        }
    }, [ID, uploads, type]);

    useEffect(() =>
    {
        const el = document.getElementsByClassName("Uploading_CirclePercentage")[0];

        el.style.setProperty("--percentage", uploadProgress);

        if (uploadProgress === 100)
        {
            setTimeout(() => goToPhotoShare(ID), 3000);
        }
    }, [ID, uploadProgress, goToPhotoShare]);

    return (
        <div className="Uploading">
            <span className="Uploading_Span">{uploadingText}</span>
            <img className="Uploading_Img" src={rendering} alt="rendering" />
            <div className="Uploading_CircleShadow">
                <span className="Uploading_Text">
                    {`Uploading ${uploadProgress}%`}
                </span>
            </div>
            <svg className="Uploading_CircleBorder" viewBox="0 0 36 36">
                <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeDasharray="var(--percentageBorder), 100"
                />
            </svg>
            <svg className="Uploading_CirclePercentage" viewBox="0 0 36 36">
                <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#1E90FF"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeDasharray="var(--percentage), 100"
                />
            </svg>
        </div>
    );
};

UploadingView.defaultProps = {
    type: PropTypes.string.isRequired,
    uploads: [],
    goToPhotoShare: PropTypes.func.isRequired,
    ID: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([null]).isRequired
    ]).isRequired
};

UploadingView.propTypes = {
    type: PropTypes.string,
    uploads: PropTypes.instanceOf(Array),
    goToPhotoShare: PropTypes.func,
    ID: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null])
    ])
};

export { UploadingView };
