import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const BtnBackView = ({ handleDone }) => (
    <div className="BtnBack">
        <button
            type="button"
            className="BtnBack_Btn"
            onClick={handleDone}
        >
            <span className="BtnBack_Text">
                &lt; Back
            </span>
        </button>
    </div>
);

BtnBackView.defaultProps = {
    handleDone: PropTypes.func.isRequired
};

BtnBackView.propTypes = {
    handleDone: PropTypes.func
};

export { BtnBackView };
