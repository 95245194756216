import { connect } from "react-redux";
import TermsPolicyView from "./TermsPolicy";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const TermsPolicy = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsPolicyView);

export default TermsPolicy;
