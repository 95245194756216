import { connect } from "react-redux";
import { BtnShareView } from "./BtnShare";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const BtnShare = connect(
    mapStateToProps,
    mapDispatchToProps
)(BtnShareView);

export { BtnShare };
