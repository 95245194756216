import React, { Component } from "react";
import PropTypes from "prop-types";
// import { history as historyPropTypes } from "history-prop-types";
// import { useHistory } from "react-router-dom";
import gifshot from "gifshot";
import { putImage, blobToBase64 } from "../../db";
import CameraPreview from "../CameraPreview";
import { DebugThresholds } from "./DebugThresholds/DebugThresholds";
import { isBrowserSupported } from "../../assets/utils";
import CameraBtns from "./CameraBtns";
import { SpinnerMsg } from "../SpinnerMsg";
import { Counter } from "./Counter";
import { AssertPopup } from "../AssertPopup/AssertPopup";
import AppBackground from "./SetArtWorks/AppBackground";
import Face from "./SetArtWorks/Face";
import Frame from "./SetArtWorks/Frame";
import GreenScreenBackground from "./SetArtWorks/GreenScreenBackground";
import { CounterSimple } from "./CounterSimple";
import { ShaneText } from "./ShaneText";
import { ShaneCircles } from "./ShaneCircles";
import { CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO, CONTENT_TYPE_GIF } from "../../assets/constants";
import "animate.css";
import "./style.css";

const OPTION_SPINNER = true;

export default class CameraActivityView extends Component
{
    timerHandle = null;

    images = [];

    glContext = null;

    resetTimeout = null;

    offScreenCanvases = {};

    CANVAS_NAMES = {
        blurred: "blurred",
        blurredMask: "blurred-mask",
        mask: "mask",
        cameraImage: "cameraImage",
        lowresPartMask: "lowres-part-mask"
    };

    constructor(props)
    {
        super(props);
        this.previewRef = React.createRef();
        this.counterCallback = null;
        this.reinitializeCamera = true;

        this.state = {
            showCountDown: false,
            showCountDownMaxDuration: false,
            facingMode: "user",
            // facingModeFoundUser: 1,
            // facingModeFoundEnv: 1,
            showDebug: false,
            isFacingModeSwapping: false,
            isVideoCaptureStarted: false,
            threshold: 0.85,
            blurArea: 512,
            showSpinner: false,
            isCanvasLoaded: false,
            spinnerTimeNotOver: true,
            showAssert: false
        };
    }

    async componentDidMount()
    {
        const { campaign, history } = this.props;
        const { current: previewRef } = this.previewRef;
        const { replace } = history;
        let initializeCamera = null;

        if (previewRef)
        {
            initializeCamera = previewRef.initializeCamera;
        }

        this.reinitializeCamera = false;
        initializeCamera && initializeCamera().then(() =>
        {
            const { mediaStream } = this.previewRef.current;

            if (mediaStream === null)
            {
                console.log("CAMERA NOT INITIALIZED, POPPING BACK");
                replace("/CameraError");
            }
        });

        if (campaign === null)
        {
            replace("/CampaignInitialization");

            return;
        }
        this.images = [];

        await this.checkFacingModes();

        setTimeout(() =>
        {
            this.setState({ spinnerTimeNotOver: false });
        }, 2000);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { isCanvasLoaded } = this.state;
        const { current: previewRef } = this.previewRef;
        const { jpgList, isProcessPhotosStarted, isProcessPhotosReady,
            setIsProcessPhotosStarted, testFlag, history
        } = this.props;
        const { replace } = history;
        let initializeCamera = null;

        if (previewRef)
        {
            initializeCamera = previewRef.initializeCamera;
        }

        if (this.reinitializeCamera && initializeCamera)
        {
            initializeCamera().then(() =>
            {
                const { mediaStream } = this.previewRef.current;

                if (mediaStream == null)
                {
                    console.log("CAMERA NOT REINITIALIZED, POPPING BACK");
                    replace("/CameraError");
                }
            });
            this.reinitializeCamera = false;
        }

        const newIsCanvasLoaded = this.previewRef?.current?.canvasRef?.current?.width > 300;

        if (isCanvasLoaded !== newIsCanvasLoaded)
        {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isCanvasLoaded: newIsCanvasLoaded
            });
        }

        if (!testFlag)
        {
            return;
        }

        if (jpgList?.length < 1)
        {
            isProcessPhotosStarted && setIsProcessPhotosStarted(false);

            return;
        }

        if ((isProcessPhotosStarted && isProcessPhotosReady) &&
            (jpgList && jpgList.length > 0))
        {
            this.processListPhotos();
        }
    }

    checkFacingModes = async () =>
    {
        /*
        const constraintsUser = { video: { facingMode: { exact: "user" } } };
        const constraintsEnv = { video: { facingMode: { exact: "environment" } } };

        navigator.mediaDevices.getUserMedia(constraintsUser).then(() => this.setState({ facingModeFoundUser: 1 }))
            .catch((err) => console.log("There is no facingMode \"User\":", err));

        navigator.mediaDevices.getUserMedia(constraintsEnv).then(() => this.setState({ facingModeFoundEnv: 1 }))
            .catch((err) => console.log("There is no facingMode \"Environment\":", err));
         */
    }

    // TODO refactor move somewhere
    getJpgName = () =>
    {
        const { jpgList, jpgListLength } = this.props;
        let jpgName = null;

        if (jpgList?.length > 0)
        {
            jpgName = jpgList.shift();
            const position = jpgListLength - jpgList.length;
            const percentage = ((position * 100) / jpgListLength).toFixed(0);
            console.log("Processing", jpgName, `[${position}/${jpgListLength}][${percentage}%]`);
        }

        return jpgName;
    }

    onBlobGifPicture = (blobData) =>
    {
        const { campaignID, addImage, testFlag, campaign, campaign: { gif_settings: gifSettings } } = this.props;
        const { images } = this;
        const { canvasRef } = this.previewRef.current;
        const jpgName = this.getJpgName();

        blobToBase64(blobData).then((blobObj) =>
        {
            images.push(blobObj.array);
            if (images.length >= gifSettings.max_gif_frames)
            {
                const newImage = {
                    campaignID,
                    galleryID: campaign.gallery_id,
                    created_at: (new Date()).getTime(),
                    isUploaded: testFlag || 0,
                    deviceID: campaign.device_code
                };
                const _this = this;
                try
                {
                    gifshot.createGIF({
                        // "images": ["http://i.imgur.com/2OO33vX.jpg", "http://i.imgur.com/qOwVaSN.png", "http://i.imgur.com/Vo5mFZJ.gif"]
                        images: images,
                        // The frame duration is in 10 scale.
                        frameDuration: (gifSettings.gif_frame_delay / 100),
                        gifWidth: canvasRef.current.width,
                        gifHeight: canvasRef.current.height
                    }, (obj) =>
                    {
                        if (!obj.error)
                        {
                            fetch(obj.image).then((res) =>
                            {
                                res.arrayBuffer().then((buffer) =>
                                {
                                    newImage.image = {
                                        array: buffer,
                                        mimeType: "image/gif"
                                    };

                                    putImage(newImage).then((photoKey) =>
                                    {
                                        addImage(newImage);
                                        if (_this.props.imageWorker)
                                        {
                                            _this.props.imageWorker.postMessage({
                                                messageType: "upload_images"
                                            });
                                        }
                                        const nextHistory = testFlag ?
                                            `/PhotoShare/image/${photoKey}/${jpgName}` :
                                            `/PhotoShare/image/${photoKey}`;

                                        _this.props.history.replace(nextHistory);
                                    });
                                });
                            });
                        }
                    });
                }
                catch (e)
                {
                    console.log(JSON.stringify(e));
                }
            }
            else
            {
                this.handleGifCapture();
            }
        });
    }

    saveFile = (blobData) =>
    {
        const csvURL = window.URL.createObjectURL(blobData);
        const tempLink = document.createElement("a");

        tempLink.href = csvURL;
        tempLink.setAttribute("download", "image_webcam_after.jpeg");
        tempLink.click();
    }

    onBlobPhoto = (blobData) =>
    {
        const { history, testFlag } = this.props;

        testFlag && this.saveFile(blobData);

        const nextHistory = "/PhotoApprove";
        history.replace(
            {
                pathname: nextHistory,
                type: CONTENT_TYPE_STILL,
                blobData: blobData
            }
        );
    }

    onCapture = (canvasRef) =>
    {
        // there should be content Type from store
        // const onBlob = isGifPicture ? this.onBlobGifPicture : this.onBlobPhoto;

        const onBlob = this.onBlobPhoto;
        const blobType = "image/jpeg";
        canvasRef.current.toBlob(onBlob, blobType);
    }

    onCaptureVideo = (blobVideo, thumbnailCanvasSize) =>
    {
        const { history } = this.props;

        const nextHistory = "/PhotoApprove";
        history.replace(
            {
                pathname: nextHistory,
                type: CONTENT_TYPE_VIDEO,
                blobData: blobVideo,
                uploadVideoDirect: this.uploadVideoDirect,
                thumbnailCanvasSize: thumbnailCanvasSize
            }
        );
    };

    tick = (type) =>
    {
        // TODO CLEAN that when video part will be ready
        /*
        const { renderToCanvas, restartVideo, startRecording } = this.previewRef.current;

        if (curTime <= 0)
        {
            (type === TICK_VIDEO) ? startRecording() : renderToCanvas();
        }
        else
        {
            restartVideo();
        }
        */
    }

    handleTimerStart = () =>
    {
        const { campaign: { activation_mode: activationMode } } = this.props;

        // TODO add check type

        if (activationMode === "kiosk")
        {
            // do something here with timer starts
        }
    }

    handlePhotoCapture = () =>
    {
        const { renderToCanvas } = this.previewRef.current;

        renderToCanvas();
    }

    handleVideoStart = () =>
    {
        const { current: previewRef } = this.previewRef;

        if (isBrowserSupported() === false)
        {
            return;
        }

        previewRef.startRecording();

        // this.setState({ showCountUp: true });
    }

    handleVideoStop = () =>
    {
        this.setState({
            showCountDownMaxDuration: false
        });
        this.previewRef.current.stopRecording();
    }

    setVideoCaptureStarted = (value) => this.setState({
        isVideoCaptureStarted: value,
        showCountDownMaxDuration: true

    });

    handleGifCapture = () =>
    {
        // TODO need to be reworked
        /*
        const {
            state: { countdown },
            props: { campaign: { gif_settings: gifSettings, activation_mode: activationMode } },
            images: { length }
        } = this;

        const countdownNew = gifSettings.shot_duration_in_milliseconds / 1000;
        const countdownTextTempStr = ((gifSettings.max_gif_frames - length) === 1) ? "" : "s left";
        let countdownTextNew = `${gifSettings.max_gif_frames - length} picture${countdownTextTempStr}`;

        if (length === 0 && activationMode === "kiosk" && countdown > 0)
        {
            console.log("Gif capture - Kiosk, 0 images, and have countdown greater than 0");
            countdownTextNew = countdownNew.to_s;
        }
        else
        {
            console.log("Gif capture - Not kiosk or we have images or countdown is 0");
        }

        this.setState({
            isGifPicture: true,
            countdown: countdownNew,
            countdownText: countdownTextNew,
        });

        this.timerHandle = setInterval(this.tick, 1000, TICK_GIF);
         */
    }

    handleDone = () =>
    {
        const { history } = this.props;

        history.push("/KioskStartScreen");
    }

    gotoExamples = () =>
    {
        const { history } = this.props;

        history.push("/ExamplesLinks");
    }

    setContentTypeHandler = (contentType) =>
    {
        switch (contentType)
        {
            case CONTENT_TYPE_STILL:
                this.counterCallback = this.handlePhotoCapture;
                break;

            case CONTENT_TYPE_VIDEO:
                this.counterCallback = this.handleVideoStart;
                break;

            case CONTENT_TYPE_GIF:
                this.counterCallback = this.handleGifCapture;
                break;

            default:
                console.error("Invalid contentType: [", contentType, "]");
                break;
        }
    }

    handleSwap = () =>
    {
        const { facingMode } = this.state;

        const facingModeNew = (facingMode === "user") ? "environment" : "user";

        this.reinitializeCamera = true;
        this.setState({
            facingMode: facingModeNew,
            isFacingModeSwapping: true
        });
    }

    finishFacingModeSwapping = () => this.setState({ isFacingModeSwapping: false });

    startProcessListPhotos = () =>
    {
        // TODO check that api

        const {
            jpgList,
            setIsProcessPhotosStarted,
            setIsProcessPhotosReady,
            isProcessPhotosStarted,
            isProcessPhotosReady
        } = this.props;

        if (!jpgList || jpgList.length === 0)
        {
            console.log("Jpg List is empty. Check jpg-list.json or go to AppLoader.");

            return;
        }

        this.handlePhotoCapture();

        isProcessPhotosReady && setIsProcessPhotosReady(false);
        !isProcessPhotosStarted && setIsProcessPhotosStarted(true);
    }

    processListPhotos = () =>
    {
        // TODO check that api

        const { setIsProcessPhotosReady, isProcessPhotosReady } = this.props;

        isProcessPhotosReady && setIsProcessPhotosReady(false);

        this.handlePhotoCapture();
    }

    processPhotoReady = () =>
    {
        const { setIsProcessPhotosReady, testFlag, isProcessPhotosReady } = this.props;

        testFlag && setTimeout(() =>
        {
            !isProcessPhotosReady && setIsProcessPhotosReady(true);
        }, 2000);
    }

    changeThreshold = ({ target }) => this.setState({ threshold: target.value });

    changeBlur = ({ target }) => this.setState({ blurArea: target.value });

    setShowCountDownOn = () => this.setState({ showCountDown: true });

    getSpinnerOrButtons = () =>
    {
        const { isVideoCaptureStarted, isCanvasLoaded, showCountDown, spinnerTimeNotOver } = this.state;

        // const { facingModeFoundUser, facingModeFoundEnv, facingMode } = this.state;
        // const facingModeCount = facingModeFoundUser + facingModeFoundEnv;

        if (OPTION_SPINNER && !isCanvasLoaded && spinnerTimeNotOver)
        {
            return (
                <SpinnerMsg
                    showSpinner={!isCanvasLoaded}
                    text="Loading..."
                    showBackground
                />
            );
        }

        if (showCountDown && !isVideoCaptureStarted)
        {
            const { isCaptureBtnHoldType } = this.props;

            if (!isCaptureBtnHoldType) // TODO check hold type
            {
                return null; // show btn in any case for holding-recording video
            }
        }

        return (
            <CameraBtns
                isVideoCaptureStarted={isVideoCaptureStarted}
                handleSwap={this.handleSwap}
                handleDone={this.handleDone}
                setShowCountDownOn={this.setShowCountDownOn}
                stopRecording={this.handleVideoStop}
                setContentTypeHandler={this.setContentTypeHandler}
            />
        );
    };

    render()
    {
        const {
            facingMode, isVideoCaptureStarted, threshold, blurArea, showDebug,
            isFacingModeSwapping, showSpinner, showCountDown, showCountDownMaxDuration, showAssert
        } = this.state;
        const { jpgList, artWorkAppBackgroundUrl, campaign } = this.props;
        const image1st = jpgList.length >= 1 ? jpgList[0] : null;

        const spinnerOrButtons = this.getSpinnerOrButtons();

        const shaneText = <ShaneText gotoExamples={this.gotoExamples} />;
        const shaneCircles = <ShaneCircles isVideoCaptureStarted={isVideoCaptureStarted} />;
        const shane = showCountDown ? shaneCircles : shaneText;

        return (
            <div>
                {showAssert && (
                    <AssertPopup
                        callback={() => window.location.reload(true)}
                    />
                )}
                <SpinnerMsg
                    id="spinner"
                    showSpinner={showSpinner}
                    text="Processing..."
                    showBackground
                />
                <div className="maxWidthHeight100 widthHeight100 CameraActivity_DivImg">
                    <img
                        className="maxWidthHeight100 widthHeight100 heightFillAvailable CameraActivity_Img"
                        src={artWorkAppBackgroundUrl}
                        alt="App Background"
                    />
                </div>
                <div className="CameraActivity_Row">
                    {/* We need to Set the next artWorks cause after refreshing the page blobs url are invalid */}
                    <AppBackground />
                    <Face />
                    <Frame />
                    <GreenScreenBackground />

                    {shane}

                    <CameraPreview
                        className="cameraPreview"
                        facingMode={facingMode}
                        previewRef={this.previewRef}
                        onCapture={this.onCapture}
                        jpgName={image1st}
                        campaign={campaign}
                        processPhotoReady={this.processPhotoReady}
                        isFacingModeSwapping={isFacingModeSwapping}
                        finishFacingModeSwapping={this.finishFacingModeSwapping}
                        setVideoCaptureStarted={this.setVideoCaptureStarted}
                        isVideoCaptureStarted={isVideoCaptureStarted}
                        onCaptureVideo={this.onCaptureVideo}
                        threshold={threshold}
                        blurArea={blurArea}
                    />
                    {spinnerOrButtons}
                    {showCountDown && (
                        <Counter
                            handleTimerStart={this.handleTimerStart}
                            callback={this.counterCallback}
                        />
                    )}
                    {showCountDownMaxDuration && (
                        <CounterSimple />
                    )}
                    <DebugThresholds
                        showDebug={showDebug}
                        openChooser={false}
                        threshold={threshold}
                        changeThreshold={this.changeThreshold}
                        blurArea={blurArea}
                        changeBlur={this.changeBlur}
                    />
                </div>
            </div>
        );
    }
}

CameraActivityView.defaultProps = {
    // eslint-disable-next-line react/forbid-prop-types
    isCaptureBtnHoldType: PropTypes.bool.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignID: PropTypes.number.isRequired,
    jpgList: [],
    jpgListLength: 0,
    testFlag: false,
    isProcessPhotosStarted: false,
    isProcessPhotosReady: false,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired,
    // history: PropTypes.shape(historyPropTypes).isRequired,
    setIsProcessPhotosStarted: PropTypes.func.isRequired,
    setIsProcessPhotosReady: PropTypes.func.isRequired,
    addImage: PropTypes.func.isRequired,
    artWorkAppBackgroundUrl: PropTypes.string.isRequired
};

CameraActivityView.propTypes = {
    isCaptureBtnHoldType: PropTypes.bool,
    campaign: PropTypes.shape({ root: PropTypes.string }),
    campaignID: PropTypes.number,
    jpgList: PropTypes.instanceOf(Array),
    jpgListLength: PropTypes.number,
    testFlag: PropTypes.bool,
    isProcessPhotosStarted: PropTypes.bool,
    isProcessPhotosReady: PropTypes.bool,
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    // history: PropTypes.shape(historyPropTypes),
    setIsProcessPhotosStarted: PropTypes.func,
    setIsProcessPhotosReady: PropTypes.func,
    addImage: PropTypes.func,
    artWorkAppBackgroundUrl: PropTypes.string
};
