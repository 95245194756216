import React from "react";
import PropTypes from "prop-types";
// import parse from "html-react-parser";
import { Button } from "../LeadGenActivity/Button";
import { HTMLTerms } from "./HTMLTerms/index";
import "./style.css";

export default function TermsAndConditionsView({ setHideTerms, legalNotice })
{
    // const legalNoticeReact = parse(terms);

    return (
        <div className="TermsAndConditions">
            <HTMLTerms />
            <Button text="OK" onClickHandler={setHideTerms} btnClass="HTMLTerms_Btn" />
        </div>
    );
}

TermsAndConditionsView.defaultProps = {
    setHideTerms: PropTypes.func.isRequired
};

TermsAndConditionsView.propTypes = {
    setHideTerms: PropTypes.func
};
